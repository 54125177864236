import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import i18next from "i18next";
import axios from "axios";
import { FaRegFilePdf } from "react-icons/fa";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../../../redux/features/userSlice";
import styles from "./StationCustomerReport.module.scss";

export default function StationCustomersReport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const girdRef = useRef();

  const token = useSelector((state) => state.cpc.user.login.token);
  const areaID = useSelector((state) => state.cpc.user.login.areaID);

  const [bookDate, setBookDate] = useState("");
  const [bookDatee, setBookDatee] = useState("");
  const [stations, setStations] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [message, setMessage] = useState("");
  const [messageAr, setMessageAr] = useState("");

  const { t } = useTranslation();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "الكمية المنصرفة ( لتر )",
      field: "total_liters",
      wrapText: false,
      autoHeight: true,
    },
    {
      headerName: "المبلغ المنصرف ( جنيه )",
      field: "amount",
      wrapText: false,
      autoHeight: true,
    },
    {
      headerName: "رقم هاتف عامل المحطة",
      field: "station.operator.phone_number",
      wrapText: false,
      autoHeight: true,
    },
    {
      headerName: "اسم عامل المحطة",
      field: "station.operator.name",
      wrapText: false,
      autoHeight: true,
    },
    {
      headerName: "حالة العملية",
      field: "",
      wrapText: false,
      autoHeight: true,
    },
    {
      headerName: "رقم السيارة",
      field: "",
      minWidth: 300,
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.car["plate_number_letters"]} - ${result.data.car["plate_number_numbers"]}`}
          </div>
        );
      },
    },
    {
      headerName: "رقم هاتف العميل",
      field: "customer.phone",
      wrapText: false,
      autoHeight: true,
    },
    {
      headerName: "اسم العميل",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.customer["firstName"]} ${result.data.customer["lastName"]}`}
          </div>
        );
      },
    },
    {
      headerName: "رقم طلب الدفع",
      field: "order_id",
      minWidth: 350,
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data["order_id"]}`}
          </div>
        );
      },
    },
    {
      headerName: "التاريخ و الوقت",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {moment(result.data["createdAt"])
              .utc()
              .format("MM-DD-YYYY, h:mm:ss a")}
          </div>
        );
      },
    },
  ]);

  const defaultColDef = {
    flex: 1,
    sortable: false,
    filter: false,
    floatingFilter: false,
    minWidth: 200,
    resizable: true,
  };

  const [rowData, setRowData] = useState([]);
  const [filter, setFilter] = useState({
    status: "",
    customerPhone: "",
    transactionId: "",
    amountFrom: "",
    amountTo: "",
    plateLetters: "",
    plateNumbers: "",
    fromDate: "",
    toDate: "",
    govId: "",
    stationId: "",
    format: "",
  });

  useEffect(() => {
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/orders/getReport",
        {
          responseType: filter.format === "" ? "" : "blob",
          params: {
            sort: "desc",
            sortBy: "createdAt",
            fromDate: filter.fromDate,
            toDate: filter.toDate,
            transactionId: filter.transactionId,
            customerPhone: filter.customerPhone,
            operatorName: "",
            operatorId: "",
            operatorPhone: "",
            stationId: filter.stationId,
            govId: filter.govId,
            cityId: "",
            limit: 1000000,
            page: "",
            offset: "",
            voucher_type: "",
            fuelType: "",
            plateLetters: filter.plateLetters,
            plateNumbers: filter.plateNumbers,
            amountFrom: filter.amountFrom,
            amountTo: filter.amountTo,
            status: filter.status,
            Download: filter.format,
            hourFrom: "",
            hourTo: "",
            AreaID: areaID,
            officeID: "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          if (filter.format == "") {
            setRowData(res.data.orders);
          }
        }
        if (filter.format === "Excel" && res.data.type !== "application/json") {
          const data = window.URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = data;
          link.download = "Station-Customers-Report.xlsx";
          link.click();
        }
        if (filter.format === "PDF" && res.data.type !== "application/json") {
          const data = window.URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = data;
          link.download = "Station-Customers-Report.pdf";
          link.click();
        }
        if (res.data.type === "application/json") {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
        if (res.data.statusCode === 404) {
          if (filter.format == "") {
            setRowData([]);
          }
        }
      });
  }, [filter, areaID]);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/Stations", {
        params: {
          governorateID: "",
          cityID: "",
          AreaID: areaID,
          officeID: "",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setStations(res.data.data);
        }
        if (res.data.statusCode === 404) {
          setStations([]);
          setMessage(res.data.error);
          setMessageAr(res.data.errorAr);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [areaID]);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  return (
    <div className={`${styles.stationCustomersReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{
                transactionStatus: "",
                clientPhoneNumber: "",
                transactionNumber: "",
                paymentAmountFrom: "",
                paymentAmountTo: "",
                plateNumbers: "",
                plateLetters: "",
                clientType: "",
                transactionDateFrom: "",
                transactionDateTo: "",
                governID: "",
                stationID: "",
              }}
              onSubmit={(values) => {
                setFilter({
                  status: values.transactionStatus,
                  customerPhone: values.clientPhoneNumber,
                  transactionId: values.transactionNumber,
                  amountFrom: values.paymentAmountFrom,
                  amountTo: values.paymentAmountTo,
                  plateLetters: values.plateLetters,
                  plateNumbers: values.plateNumbers,
                  fromDate: values.transactionDateFrom,
                  toDate: values.transactionDateTo,
                  govId: values.governID,
                  stationId: values.stationID,
                  format: "",
                });
              }}
              // enableReinitialize
            >
              {({
                values,
                errors,
                handleChange,
                setFieldValue,
                touched,
                handleSubmit,
                handleReset,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          حالة العملية
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="transactionStatus"
                          id="transactionStatus"
                          onChange={(e) => {
                            setFieldValue(
                              "transactionStatus",
                              e.currentTarget.value
                            );
                          }}
                        >
                          <option value="">اختر حالة العملية</option>
                          <option value={1}>مقبولة</option>
                          <option value={0}>مرفوضة</option>
                        </Form.Select>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          رقم هاتف العميل
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          placeholder="أدخل رقم الهاتف"
                          name="clientPhoneNumber"
                          id="clientPhoneNumber"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setFieldValue("clientPhoneNumber", e.target.value);
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          رقم طلب الدفع
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          placeholder="ادخل رقم طلب الدفع"
                          name="transactionNumber"
                          id="transactionNumber"
                          onChange={(e) => {
                            setFieldValue("transactionNumber", e.target.value);
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              المبلغ المدفوع ( من )
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputField}`}
                              name="paymentAmountFrom"
                              id="paymentAmountFrom"
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "paymentAmountFrom",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              المبلغ المدفوع ( إلى )
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputField}`}
                              name="paymentAmountTo"
                              id="paymentAmountTo"
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "paymentAmountTo",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              ارقام لوحة السيارة
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputCarField}`}
                              name="plateNumbers"
                              id="plateNumbers"
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue("plateNumbers", e.target.value);
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              أحرف لوحة السيارة
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputCarField}`}
                              name="plateLetters"
                              id="plateLetters"
                              onChange={(e) => {
                                let string = e.target.value;
                                let array = string.split("");
                                let newStr = array.join(" ");
                                setFieldValue("plateLetters", newStr);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          نوع العميل
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="clientType"
                          id="clientType"
                          onChange={(e) => {
                            setFieldValue("clientType", e.currentTarget.value);
                          }}
                        >
                          <option value="">اختر نوع العميل</option>
                          <option value="Sharee_Coin">عميل شاري كوين</option>
                          <option value="CPC">عميل التعاون</option>
                        </Form.Select>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              تاريخ العملية ( من )
                            </Form.Label>
                            <DatePicker
                              className={`${styles.filterDateField}`}
                              name="transactionDateFrom"
                              id="transactionDateFrom"
                              selected={bookDate}
                              onSelect={(date) => {
                                setFieldValue(
                                  "transactionDateFrom",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDate(date);
                              }}
                              onChange={(date) => {
                                setFieldValue(
                                  "transactionDateFrom",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDate(date);
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              تاريخ العملية ( إلى )
                            </Form.Label>
                            <DatePicker
                              className={`${styles.filterDateField}`}
                              name="transactionDateTo"
                              id="transactionDateTo"
                              selected={bookDatee}
                              onSelect={(date) => {
                                setFieldValue(
                                  "transactionDateTo",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDatee(date);
                              }}
                              onChange={(date) => {
                                setFieldValue(
                                  "transactionDateTo",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDatee(date);
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          المحافظة
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="governID"
                          id="governID"
                          onChange={(e) => {
                            setFieldValue("governID", e.currentTarget.value);
                          }}
                        >
                          <option value="">اختر المحافظة</option>
                          {governs.map((gov) => {
                            return (
                              <option
                                key={gov.GovernateID}
                                value={gov.GovernateID}
                              >
                                {i18next.language == "ar"
                                  ? gov["governorate_name_ar"]
                                  : gov["governorate_name_en"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          اسم المحطة
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="stationID"
                          id="stationID"
                          onChange={(e) => {
                            setFieldValue("stationID", e.currentTarget.value);
                          }}
                        >
                          <option value="">اسم المحطة</option>
                          {stations.map((station, index) => {
                            return (
                              <option key={index} value={station["station_id"]}>
                                {station["station_name"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <div className="d-flex justify-content-start align-items-center mb-3">
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                        className={`${styles.searchBtn} m-1`}
                      >
                        {t("search")}
                      </Button>
                      <Button
                        type="reset"
                        variant="primary"
                        size="sm"
                        className={`${styles.searchBtn} m-1`}
                        onClick={() => {
                          setFilter({
                            status: "",
                            customerPhone: "",
                            transactionId: "",
                            amountFrom: "",
                            amountTo: "",
                            plateLetters: "",
                            plateNumbers: "",
                            fromDate: "",
                            toDate: "",
                            govId: "",
                            stationId: "",
                            format: "",
                          });
                          handleReset();
                          setBookDate("");
                          setBookDatee("");
                        }}
                      >
                        {t("reset")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine ag-theme-quartz"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3">
            <div className="d-flex justify-content-start">
              <Button
                variant="danger"
                size="md"
                className={`${styles.downloadPdfBtn} m-1`}
                onClick={() => {
                  setFilter({ ...filter, format: "PDF" });
                }}
              >
                <FaRegFilePdf className="mx-1" />
                PDF
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
