import React from "react";
import styles from "./OwnerTerms.module.scss"

export default function OwnerTerms() {
  return (
    <div className={`${styles.container}`}>
      <h1>الشروط والأحكام - برنامج وياك</h1>
      <h2>إقرار لجميع مستخدمين برنامج المقدم من شركة التعاون للبترول:</h2>
      <p>
        &nbsp; &nbsp; * برنامج لأصحاب محلات الزيوت و كل من يقوم بشراء منتجات
        زيوت التعاون للبترول.&nbsp; &nbsp; * هذا البرنامج سارى داخل المحلات و
        المنافذ و محطات الوقود التابعة لشركة التعاون للبترول او أى منفذ بيع زيوت
        فى جميع&nbsp; &nbsp; أنحاء جمهورية مصر العربية المشتركة في البرنامج و
        عملاء التعاون للبترول المشتركين فى البرنامج على المنتجات التالية:
      </p>
      <p>
        * يسري البرنامج على منتجات زيوت التعاون للبترول التى تحددها الشركة داخل
        جمهورية مصر العربية بالمحطات التى تحددها&nbsp; &nbsp; الشركة منفردة.
      </p>
      <p>
        * تطبق الشروط والأحكام أثناء استخدامك برنامج و بمجرد البدء فى الإستخدام
        فإنك توافق على قبول جميع الشروط والأحكام&nbsp; &nbsp; المنصوص عليها بهذا
        البرنامج حيث أن ليس من حق الأشخاص دون سن 18 عاما الإشتراك و الإستخدام
        (الحد الادنى للسن القانونية&nbsp; &nbsp; لإستخراج رخصة القيادة) .
      </p>
      <p>
        * يسمح برنامج لأصحاب محلات الزيوت الذين لديهم أكثر من فرع بتسجيل هذه
        الفروع لصاحبها برقم هاتف محمول واحد للمالك و&nbsp; &nbsp; لكل فرع رقم
        هاتف محمول مختلف
      </p>
      <h2>حقوق شركة التعاون للبترول:</h2>
      <p>
        &nbsp; &nbsp; * تحتفظ شركة التعاون للبترول بجميع حقوقها منفرده بحق وقف
        أو إلغاء أو تأجيل أو تعديل و تطوير البرنامج فى أى وقت و&nbsp; &nbsp;
        بدون سابق إنذار و أيضاً حق تحديد عدد النقاط و قيمها المادية لكل عبوة من
        عبوات منتجات الزيوت السالف ذكرها دون الرجوع&nbsp; &nbsp; على شركة
        التعاون للبترول بأى تعويضات مادية أو أدبية قد تنتج عنها خسائر او اضرار
        او مكاسب فائته.
      </p>
      <p>
        &nbsp; &nbsp; * يحق لشركة التعاون للبترول (أو من تفوضه) بجمع والاحتفاظ
        ومعالجة كافة البيانات لأغراض خاصة ببرنامج أو لأغراض خاصة&nbsp; &nbsp;
        بالدراسات التسويقية للشركة كما تستخدم الشركة بعض التقنيات لجمع البيانات
        وقد يشمل ذلك إرسال ملفات تعريف الارتباط إلى&nbsp; &nbsp; الجهاز الخاص بك
        أو إرسال رسائل نصية قصيرة للترويج عن البرامج والعروض والخصومات المقدمة
        من خلال&nbsp; &nbsp; برنامج والبرامج التسويقية الأخرى التى تقدمها الشركة
        وذلك بدون أي مسئولية على شركة التعاون للبترول او الشركات التابعة&nbsp;
        &nbsp; أو الشقيقة لها.
      </p>
      <p>
        &nbsp; &nbsp; * يحق لشركة التعاون للبترول منفرده إلغاء أو تأجيل أو وقف
        أو تغير هذا البرنامج فى أى وقت خلال فترة سريانه وذلك في&nbsp; &nbsp;
        حالة وجود حالة من حالات القوه القاهرة التي تستدعي ذلك ولا يجوز للعميل
        خلالها الرجوع على شركة التعاون للبترول و لا&nbsp; &nbsp; يحق للعميل طلب
        الحصول على هدايا البرنامج أو المقابل المادى للنقاط المستبدلة أو تعويض أو
        أى طلب أخر بخلاف ذلك، كما&nbsp; &nbsp; انه لا يحق للعميل الرجوع على شركة
        التعاون للبترول عن أى خسائر أو أضرار أو أى مكسب فائت، ويقر العميل بعلمه
        وإقراره&nbsp; &nbsp; وقبوله لهذه الفقرة.
      </p>
      <h2>شروط الحصول على النقاط والجوائز:</h2>
      <p>
        &nbsp; &nbsp; * للحصول على النقاط و الجوائز الممنوحة بالبرنامج لابد من
        شراء أحد عبوات منتجات زيوت شركة التعاون للبترول المحدده&nbsp; &nbsp;
        سلفاً و نزع الملصق الجانبى المخصص لأصحاب المتاجر لعمل مسح&nbsp; &nbsp;
        (QR-Code) المطبوع عليه و يبدأ فى الحصول على نقاط البرنامج.
      </p>
      <p>
        &nbsp; &nbsp; * غير متاح تبادل النقاط او قسائم الشراء التى يتم الحصول
        عليها من البرنامج مع أى مشترك آخر و لكن يسمح بصرفها نقدى او&nbsp; &nbsp;
        تحويلها لحساب بنكى أو محفظى إلكترونية.&nbsp; &nbsp; * لا تتحمل التعاون
        للبترول اي مسئولية في حالة امتناع مقدم الخدمة عن استبدال النقاط او قبول
        القسائم الشرائية المقدمة&nbsp; &nbsp; من العملاء.&nbsp; &nbsp; * لا يحق
        للعميل نقل النقاط المكتسبة الخاصة به الى عميل أخر.
      </p>
      <p>
        &nbsp; &nbsp; * يحق لشركة التعاون للبترول اللجوء للقضاء أو استخدام كافة
        الأدوات القانونية المتاحة ضدالعميل&nbsp; &nbsp; أو من يمثله في حالة
        إساءة إستخدام (مباشرة أو غير مباشرة) أو التزوير للإستفادة من البرنامج.
        {"{"}" "{"}"}
      </p>
      <p>
        &nbsp; &nbsp; * تحتفظ شركة التعاون للبترول منفرده بحق الرد او اللجوء
        القانونى و استخدام كافة الأساليب القانونية المتاحة للحفاظ على&nbsp;
        &nbsp; حقوق الملكية الفكرية و العلامة التجارية فى حالة تعرض الشركة لأى
        غش تجارى أو إستغلال الاسم و العلامة التجارية او أى&nbsp; &nbsp; ضرر أياً
        ما كان و بأى صورة سواء بشكل مباشر أو غير مباشر.
      </p>
      <p>
        &nbsp; &nbsp; * يحق لشركة التعاون للبترول غلق الحساب فورا فى حالة مخالفة
        شروط و قواعد استخدام التطبيق و البرنامج دون ان يكون من&nbsp; &nbsp; حقى
        الرجوع علي الشركة بأى شكل من أشكال المسائلة القانونية.{"{"}" "{"}"}
      </p>
      <h2>آلية تجميع النقاط: </h2>
      <p>
        &nbsp; &nbsp; * تتم عملية تجميع النقاط بدون تحديد شرائح للصرف او إصدار
        قسائم شراء،حيث يتم تحديد مبلغ قسيمة الشراء من خلال المحلات&nbsp; &nbsp;
        التى سيتم صرف قسائم الشراء بها و يكون لكل محل قيمه كحد ادنى لإصدار هذه
        القسيمة و تكون مدتها عام من تاريخ إصدارها و&nbsp; &nbsp; لا يمكن
        إستخدامها مره آخرى.
      </p>
      <h2>الموافقة على الشروط:</h2>
      <p>
        &nbsp; &nbsp; * اقر و أوافق على الاشتراك في برنامج . كما اقر اننى قرأت
        وفهمت و وافقت على كافة الشروط والاحكام الخاصة بالبرنامج .&nbsp; &nbsp;
        كما اقر انى أوافق على قيام شركة التعاون للبترول&nbsp; &nbsp; (او من
        تفوضه) بجمع والاحتفاظ ومعالجة كافة البيانات الخاصة بي لأغراض خاصة
        ببرنامج&nbsp; &nbsp;
        <br /> و/او لأغراض خاصة بالدراسات التسويقية للشركة كما تستخدم الشركة بعض
        التقنيات لجمع البيانات , وقد يشمل ذلك ارسال&nbsp; &nbsp; ملفات تعريف
        الارتباط الى الجهاز الخاص بك و/او ارسال رسائل نصية قصيرة للترويج عن
        البرامج والعروض والخصومات المقدمة من&nbsp; &nbsp; خلال برنامج&nbsp;
        &nbsp;
        <br /> والبرامج التسويقية الأخرى التي تقدمها الشركة وذلك بدون أي مسئولية
        على شركة التعاون للبترول او الشركات التابعة&nbsp; &nbsp; او الشقيقة لها
        . كما يقر ويقبل العميل ان من حق الشركة غلق الحساب فورا في حالة مخالفة
        العميل لشروط وقواعد استخدام&nbsp; &nbsp; التطبيق والبرنامج دون ان يكون
        من حقه الرجوع على الشركة .
      </p>
      <p>
        &nbsp; &nbsp; * أقر أنني قرأت وفهمت و وافقت على كافة الشروط والأحكام
        الخاصة بالبرنامج.
      </p>
      <hr />
      <h2>كيفية عمل البرنامج و شروط الإستخدام</h2>
      <h3>1. كيفية التسجيل و الحصول على النقاط:</h3>
      <p>
        &nbsp; &nbsp; * لكى يتمكن المستخدم من الاشتراك فى البرنامج و الحصول على
        النقاط و الجوائز الممنوحه بالبرنامج لابد من شراء عبوة منتج&nbsp; &nbsp;
        من زيوت التعاون للبترول المحدده سلفاً من أحد وكلائنا باى حجم من الاحجام
        المحددة بمعرفة الشركة ثم يقوم بالإجراءات&nbsp; &nbsp; التالية:-
      </p>
      <p>
        &nbsp; &nbsp; * الدخول على رابط البرنامج التالى :{"{"}" "{"}"}
        <a href="https://www.cpc.com.eg/cpc-wayak.php">
          https://www.cpc.com.eg/cpc-wayak.php
        </a>
        &nbsp; &nbsp; يتم إتباع الخطوات التالية :-
      </p>
      <ol>
        &nbsp; &nbsp;
        <li>يقوم صاحب المحل بالضغط على إنشاء حساب جديد لتسجيل بياناته.</li>
        &nbsp; &nbsp;
        <li>
          يقوم صاحب المتجر بملئ نموذج بيانات على البرنامج يتضمن( الاسم / رقم
          الهاتف / البريد الالكترونى / اسم المحل /&nbsp; &nbsp; &nbsp; &nbsp;
          المحافظة / المنطقة)
        </li>
        &nbsp; &nbsp;
        <li>
          يقوم صاحب المحل بالضغط على تسجيل محل لإرسال بياناته لتفعيلها على
          البرنامج.{"{"}" "{"}"}
        </li>
        &nbsp; &nbsp;
        <li>سيصلك رسالة تأكيد بعملية التسجيل و الانتظار لعملية التفعيل.</li>
      </ol>
      <p>
        &nbsp; &nbsp; * عند وصول طلب التسجيل للشركة يتم تفعيل المحلات و ذلك من
        خلال عمل زيارات ميدانية لمقرات المحلات التى أرسلت طلبات&nbsp; &nbsp;
        التفعيل من خلال فريق عمل خاص (Activation Team) لتفعيلها حيث تم توجيه أحد
        أفراد الفريق لإستكمال البيانات مع صاحب&nbsp; &nbsp; المحل و فى النهاية
        سيتم إرسال رسالة ترحيبية فيها كلمة المرور السرية لحساب صاحب المحل
        بالبرنامج.
      </p>
      <p>
        &nbsp; &nbsp; * يقوم صاحب المحل بالدخول للبرنامج من خلال تسجيل رقم هاتفه
        المحمول و كلمة المرور السرية المرسله له سابقاً عند تفعيل&nbsp; &nbsp;
        حسابة ليتمكن من بداية عمليات مسح الاكواد للحصول على النقاط و إدارة حسابة
        و معرفة عدد نقاطه و اماكن صرف النقاط فى&nbsp; &nbsp; المتاجر المختلفة .
      </p>
      <p>
        &nbsp; &nbsp; * يستطيع العميل استخدام نقاطه المجمعة كوسيلة سداد بديلة
        سواء كلية أو جزئية عند شرائه لاي من المنتجات أو الخدمات&nbsp; &nbsp;
        المتوفره داخل أى فرع من فروع مقدمى الخدمات المشتركين فى البرنامج داخل
        جمهورية مصر العربية.
      </p>
      <h3>2. كيفية استبدال قسيمة الشراء:</h3>
      <p>
        &nbsp; &nbsp; * تعتبر قسيمة الشراء التى يتم إصدارها من البرنامج بمثاية
        وسيلة دفع لدى المتاجر المشتركة بالبرنامج بحيث يمكن دفع جزء&nbsp; &nbsp;
        او كل المبالغ منها داخل الأفرع الخاصة بالمحلات المشتركة بالبرنامج و لا
        يجوز ان يتم استخدام جزء من قسيمة الشراء و&nbsp; &nbsp; ترحيل المبلغ
        المتبقى او صرفة كاش.
      </p>
      <p>
        &nbsp; &nbsp; * صلاحية سريان نقاط المشتركين بالبرنامج المجمعة لمدة سنة
        ميلادية تبدأ من تاريخ الحصول على النقاط فقط.&nbsp; &nbsp; * تسرى وتسود
        قواعد شركة التعاون للبترول على هذا البرنامج.{"{"}" "{"}"}
      </p>
      <h2>الدعم والاستفسارات:</h2>
      <p>
        &nbsp; &nbsp; * تقدم شركة التعاون للبترول الدعم والاستفسارات للعملاء و
        اصحاب المحلات من خلال:
      </p>
      <ul>
        &nbsp; &nbsp;<li>خدمة الواتساب رقم (01090011397)</li>&nbsp; &nbsp;
        <li>
          رسائل الصفحة (
          <a href="https://facebook.com/CPCWayak.eg">
            https://facebook.com/CPCWayak.eg
          </a>
          )
        </li>
        &nbsp; &nbsp;<li>البريد الإلكترونى : wayak@cpc.com.eg</li>&nbsp; &nbsp;
        <li>الخط الساخن : 17034</li>
      </ul>
    </div>
  );
}
