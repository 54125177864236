import React, { useState, useEffect } from "react";
import OwnerCashTerms from "./terms/OwnerCashTerms";
import { Formik } from "formik";
import * as yup from "yup";
import { Container, Row, Col, Button, Form, NavLink } from "react-bootstrap";
import { Fragment } from "react";
import failedMessage from "../../../../../messages/Failed";
import { getPointsValue } from "../../../../../redux/features/customerSlice";
import { walletBankCashout } from "../../../../../redux/features/customerSlice";
import { walletMobileCashout } from "../../../../../redux/features/customerSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import styles from "./OwnerWallet.module.scss";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import axios from "axios";
import { handleLogout } from "../../../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";
import Message from "../../../message/Message";

export default function OwnerWallet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [banks, setBanks] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const { t, i18n } = useTranslation();

  const [showMsg, setShowMsg] = useState(false);
  const [finalMessage, setFinalMessage] = useState("");

  const cashoutMobileWalletValidation = yup.object().shape({
    cashAmount: yup.number().required("المبلغ المراد مطلوب"),
    fullName: yup.string().required("الاسم بالكامل مطلوب"),
    password: yup.string().required("تاكيد كلمة السر مطلوب"),
    walletName: yup.string().required("اسم المحفظة مطلوب"),
  });
  const cashoutBankWalletValidation = yup.object().shape({
    cashAmount: yup.number().required("المبلغ المراد مطلوب"),
    fullName: yup.string().required("الاسم بالكامل مطلوب"),
    password: yup.string().required("تاكيد كلمة السر مطلوب"),
    bankCode: yup.string().required("اسم البنك مطلوب"),
  });

  const [finalValue, setFinalValue] = useState("00");
  const [activeBtn, setActiveBtn] = useState(1);
  const [termsMessageLine, setTermsMessageLine] = useState(false);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/banks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setBanks(res.data.data.banks);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  const [alertMessage, setAlertMessage] = useState();

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <div className={`${styles.ewalletContent} py-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{
                walletName: "",
                cashAmount: "",
                bankCode: "",
                password: "",
                fullName: "",
                points: "",
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 3000);
                if (activeBtn === 1) {
                  dispatch(
                    walletMobileCashout({
                      walletName: values.walletName,
                      cashAmount: values.cashAmount,
                      points: values.points,
                      fullName: values.fullName,
                      password: values.password,
                      token,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      setShowMsg(true);
                      if (i18next.language == "ar") {
                        setFinalMessage(res.payload.data.messageAr);
                      } else {
                        setFinalMessage(res.payload.data.message);
                      }
                    } else if (res.payload.data.statusCode === 401) {
                      dispatch(handleLogout());
                      navigate("/cpc/ownerTrader/login");
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    } else {
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                } else {
                  dispatch(
                    walletBankCashout({
                      bankCode: values.bankCode,
                      cashAmount: values.cashAmount,
                      points: values.points,
                      fullName: values.fullName,
                      password: values.password,
                      token,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      setShowMsg(true);
                      if (i18next.language == "ar") {
                        setFinalMessage(res.payload.data.messageAr);
                      } else {
                        setFinalMessage(res.payload.data.message);
                      }
                    } else if (res.payload.data.statusCode === 401) {
                      dispatch(handleLogout());
                      navigate("/cpc/ownerTrader/login");
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    } else {
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }
              }}
              validationSchema={
                activeBtn === 1
                  ? cashoutMobileWalletValidation
                  : activeBtn === 2 && cashoutBankWalletValidation
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => checkKeyDown(e)}
                  >
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} lg={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("enterAmount")}
                        </Form.Label>
                        <NavLink
                          onClick={() => {
                            setTermsMessageLine(true);
                          }}
                          className={`${styles.termsBtn}`}
                        >
                          <Form.Text className="d-block">
                            *{t("sendGiftNote")}
                          </Form.Text>
                        </NavLink>
                      </Col>
                      <Col xs={12} lg={2} className="mb-3">
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="cashAmount"
                          name="cashAmount"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setFieldValue("cashAmount", e.currentTarget.value);
                            dispatch(
                              getPointsValue({
                                token,
                                amount: Number(e.currentTarget.value),
                                cashType: "wallet",
                              })
                            ).then((res) => {
                              setAlertMessage(null);
                              if (res.payload.data.statusCode === 200) {
                                setFieldValue(
                                  "points",
                                  res.payload.data.pointsNeeded
                                );
                                setFinalValue(res.payload.data.pointsNeeded);
                              } else {
                                setAlertMessage(
                                  <span
                                    className={`${styles.alertMessageContent} px-3 py-2`}
                                  >
                                    {i18n.language === "ar"
                                      ? res.payload.data.errorAr
                                      : res.payload.data.error}
                                  </span>
                                );
                                setFinalValue("00");
                              }
                            });
                          }}
                          value={values.cashAmount}
                        />
                        {errors.cashAmount && touched.cashAmount && (
                          <Form.Text className="text-error">
                            {errors.cashAmount}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} lg={4}>
                        <Form.Text>
                          <p className={`${styles.totalValuepara}`}>
                            {t("totalPoints")}
                            <span
                              className={`${styles.finalValueStyle} m-3 px-3 py-2`}
                            >
                              {finalValue}
                            </span>
                            {t("point")}
                          </p>
                        </Form.Text>
                      </Col>
                      <Col xs={12} className="mt-2">
                        {alertMessage !== null && alertMessage}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} className="mb-4">
                        <Form.Label className="d-block">
                          {t("ewalletType")}
                        </Form.Label>
                        <div className="d-flex justify-content-center align-items-center">
                          <Button
                            variant="primary"
                            size="sm"
                            className={`${
                              activeBtn === 1
                                ? styles.walletChoiceBtn
                                : styles.walletnotActiveBtn
                            } mx-2`}
                            onClick={() => {
                              setActiveBtn(1);
                            }}
                          >
                            {t("mobileWallet")}
                          </Button>
                          <Button
                            variant="primary"
                            size="sm"
                            className={`${
                              activeBtn === 2
                                ? styles.walletChoiceBtn
                                : styles.walletnotActiveBtn
                            } mx-2`}
                            onClick={() => {
                              setActiveBtn(2);
                            }}
                          >
                            {t("bankWallet")}
                          </Button>
                        </div>
                      </Col>
                      {activeBtn === 1 ? (
                        <Fragment>
                          <Col xs={12} md={6} className="mb-2">
                            <Form.Label className="d-block">
                              {t("chooseEwallet")}
                            </Form.Label>
                            <Form.Text className="d-block">
                              *{t("cashformNote")}
                            </Form.Text>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Select
                              className={`${styles.cashoutSelect}`}
                              name="walletName"
                              id="walletName"
                              onChange={(e) => {
                                setFieldValue(
                                  "walletName",
                                  e.currentTarget.value
                                );
                              }}
                              value={values.walletName}
                            >
                              <option value="">{t("selectWallet")}</option>
                              <option value="vodafone">Vodafone Cash</option>
                              <option value="etisalat">Etisalat Cash</option>
                              <option value="Orange">Orange Cash</option>
                            </Form.Select>
                            {errors.walletName && touched.walletName && (
                              <Form.Text className="text-error">
                                {errors.walletName}
                              </Form.Text>
                            )}
                          </Col>
                        </Fragment>
                      ) : (
                        activeBtn === 2 && (
                          <Fragment>
                            <Col xs={12} md={6} className="mb-2">
                              <Form.Label className="d-block">
                                {t("chooseEwallet")}
                              </Form.Label>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Select
                                className={`${styles.cashoutSelect}`}
                                name="bankCode"
                                id="bankCode"
                                onChange={(e) => {
                                  setFieldValue(
                                    "bankCode",
                                    e.currentTarget.value
                                  );
                                }}
                              >
                                <option value="">{t("chooseBank")}</option>
                                {banks.map((bank, index) => {
                                  return (
                                    <option
                                      value={bank["bank_code"]}
                                      key={index}
                                    >
                                      {bank["bank_name"]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              {errors.bankCode && touched.bankCode && (
                                <Form.Text className="text-error">
                                  {errors.bankCode}
                                </Form.Text>
                              )}
                            </Col>
                          </Fragment>
                        )
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("walletOwnerName")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="fullName"
                          name="fullName"
                          onChange={handleChange}
                          value={values.fullName}
                        />
                      </Col>
                      {errors.fullName && touched.fullName && (
                        <Form.Text className="text-error">
                          {errors.fullName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("confirmPass")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="password"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </Col>
                      {errors.password && touched.password && (
                        <Form.Text className="text-error">
                          {errors.password}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <div className="d-grid">
                      <Button
                        type="submit"
                        variant="primary"
                        size="md"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("sendCash")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Message
        show={showMsg}
        onHide={() => {
          setShowMsg(false);
        }}
        message={finalMessage}
      />
      <OwnerCashTerms
        show={termsMessageLine}
        onHide={() => {
          setTermsMessageLine(false);
        }}
      />
    </div>
  );
}
