import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./OwnerDetails.module.scss";
import axios from "axios";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { handleLogout } from "../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";

export default function OwnerDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantID } = useParams();
  const { activatorID } = useParams();

  const [owner, setOwner] = useState({
    ownerName: "",
    businessName: "",
    phone: "",
    branches: [],
  });

  const [merchant, setMerchant] = useState([]);
  const [shops, setShops] = useState([]);
  const [shopId, setShopId] = useState("");
  const [shopDetails, setShopDetails] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://evouchers.shareecoin.com/CPC/list-Owner-Traders/${merchantID}`
  //     )
  //     .then((res) => {
  //       setMerchant(res.data.ownersAndTraders);
  //       res.data.ownersAndTraders.map((trader) => {
  //         setShops(trader["traders_data"]);
  //       });

  //       const shopTarget = res.data.ownersAndTraders["traders_data"].filter(
  //         (trade) => {
  //           return trade["trader_id"] == shopId;
  //         }
  //       );
  //       setShopDetails(shopTarget);
  //     });
  // }, [shopId]);

  useEffect(() => {
    axios
      .get(
        `https://evouchers.shareecoin.com/CPC/list-Owner-Traders/${merchantID}`
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          res.data.ownersAndTraders.map((owner) => {
            setOwner({
              ownerName: `${owner["owner_firstName"]} ${owner["owner_lastName"]}`,
              businessName: `${owner["owner_businessName"]}`,
              phone: `${owner["owner_phone"]}`,
              branches: owner["traders_data"],
            });
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  useEffect(() => {}, []);

  const { t, i18n } = useTranslation();

  return (
    <div className={`${styles.OwnerDetails} p-3`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.DashboardTitle} d-inline`}>
              {t("contactDetails")}
            </h3>
          </Col>
          <Col xs={12} className="mt-3" key={Math.random() * 10000}>
            <div className={`${styles.contactInfo} py-4 px-5`}>
              <h4 className={`${styles.contactTitle} mb-3`}>
                {t("contactDetails")}
              </h4>
              <div className={`${styles.element}`}>
                <h5>{t("ownerName")}:</h5>
                <h5>{`${owner.ownerName}`}</h5>
              </div>
              <div className={`${styles.element}`}>
                <h5>{t("email")}:</h5>
                <h5></h5>
              </div>
              <div className={`${styles.element}`}>
                <h5>{t("phoneNumber")}:</h5>
                <h5>{owner.phone}</h5>
              </div>
              <div className={`${styles.element}`}>
                <h5>{t("nationalId")}:</h5>
                <h5></h5>
              </div>
              <div className={`${styles.element}`}>
                <h5></h5>
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            <div className={`${styles.locationInfo} py-4 px-5`}>
              <h4 className={`${styles.location} mb-3`}>
                {t("branchDetials")}
              </h4>
              <Formik
                initialValues={{ traderId: "" }}
                onSubmit={(values) => {
                  setShopId(values.traderId);
                }}
              >
                {({
                  values,
                  errros,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} />
                      <Col xs={12} sm={6}>
                        <Form.Select
                          name="traderId"
                          id="traderId"
                          onChange={(e) => {
                            setFieldValue("traderId", e.target.value);
                            setShopId(e.target.value);
                          }}
                        >
                          <option value="">{t("selectBranch")}</option>
                          {owner.branches.map((shop, i) => {
                            return (
                              <option
                                value={shop["trader_id"]}
                                key={i}
                              >{`${shop["trader_branchName"]}`}</option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Form>
                  );
                }}
              </Formik>
              {owner.branches
                .filter((trader) => {
                  return trader["trader_id"] == shopId;
                })
                .map((shop, index) => {
                  return (
                    <div
                      className={`${styles.ownerBranchesContent} mt-3`}
                      key={index}
                    >
                      <h5 className="mb-3">{`${shop["trader_branchName"]}`}</h5>
                      <div className={`${styles.element} mb-3`}>
                        <h5 className="d-inline me-4">{t("branchManager")}:</h5>
                        <span>{`${shop["trader_firstName"]} ${shop["trader_lastName"]}`}</span>
                      </div>
                      <div className={`${styles.element} mb-3`}>
                        <h5 className="d-inline me-4">{t("branchPhone")}</h5>
                        <span>{`${shop["trader_phone"]}`}</span>
                      </div>
                      <div className={`${styles.element} mb-3`}>
                        <h5 className="d-inline me-4">{t("address")}:</h5>
                        <span>{`${shop["trader_address"]}`}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
          <Col xs={12}>
            <div className="text-center">
              <Button
                variant="primary"
                className={`${styles.proceedBtn} mt-3`}
                onClick={() => {
                  navigate(
                    `/activator/${activatorID}/merchant/${merchantID}/activate/upload`
                  );
                }}
              >
                {t("proceed")}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
