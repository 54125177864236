import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { handleLogout } from "../../../../../redux/features/userSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./OwnerBankAccount.module.scss";
import i18next from "i18next";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { bankCashout } from "../../../../../redux/features/customerSlice";
import { getPointsValue } from "../../../../../redux/features/customerSlice";
import failedMessage from "../../../../../messages/Failed";
import OwnerCashTerms from "../wallet/terms/OwnerCashTerms";
import { useDispatch, useSelector } from "react-redux";

export default function OwnerBankAccount() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [banks, setBanks] = useState([]);
  const [finalValue, setFinalValue] = useState("00");
  const [showMsg, setShowMsg] = useState(false);
  const [finalMessage, setFinalMessage] = useState("");

  const cashoutValidation = yup.object().shape({
    cashAmount: yup.number().required("المبلغ المراد مطلوب"),
    bankCode: yup.string().required("اسم البنك مطلوب"),
    accountNumber: yup.string().required("رقم الحساب مطلوب"),
    fullName: yup.string().required("الاسم بالكامل مطلوب"),
    password: yup.string().required("تاكيد كلمة السر مطلوب"),
  });

  const token = useSelector((state) => state.cpc.user.login.token);

  const [termsMessageLine, setTermsMessageLine] = useState(false);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/banks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          let result = res.data.data.banks.sort((a, b) =>
            a["bank_name"].localeCompare(b["bank_name"])
          );
          setBanks(result);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = useState();

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };
  return (
    <div className={`${styles.customerBankAccountContent} py-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{
                cashAmount: "",
                points: "",
                bankCode: "",
                fullName: "",
                accountNumber: "",
                password: "",
              }}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 3000);
                dispatch(
                  bankCashout({
                    amount: values.cashAmount,
                    points: values.points,
                    fullName: values.fullName,
                    cardNumber: values.accountNumber,
                    bankCode: values.bankCode,
                    password: values.password,
                    token,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    setShowMsg(true);
                    if (i18next.language == "ar") {
                      setFinalMessage(res.payload.data.messageAr);
                    } else {
                      setFinalMessage(res.payload.data.message);
                    }
                  } else if (res.payload.data.statusCode === 401) {
                    dispatch(handleLogout());
                    navigate("/cpc/ownerTrader/login");
                    const loadReq = toast.loading(t("loading"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  } else {
                    const loadReq = toast.loading(t("loading"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  }
                });
              }}
              validationSchema={cashoutValidation}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => checkKeyDown(e)}
                  >
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("enterAmount")}
                        </Form.Label>
                        <NavLink
                          onClick={() => {
                            setTermsMessageLine(true);
                          }}
                          className={`${styles.termsBtn}`}
                        >
                          <Form.Text className="d-block">
                            *{t("sendGiftNote")}
                          </Form.Text>
                        </NavLink>
                      </Col>
                      <Col xs={12} lg={2}>
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput} mb-3`}
                          size="sm"
                          id="cashAmount"
                          name="cashAmount"
                          onChange={(e) => {
                            setFieldValue("cashAmount", e.currentTarget.value);
                            dispatch(
                              getPointsValue({
                                token,
                                amount: Number(e.currentTarget.value),
                                cashType: "bank",
                              })
                            ).then((res) => {
                              setAlertMessage(null);

                              if (res.payload.data.statusCode === 200) {
                                setFieldValue(
                                  "points",
                                  res.payload.data.pointsNeeded
                                );
                                setFinalValue(res.payload.data.pointsNeeded);
                              } else {
                                setAlertMessage(
                                  <span
                                    className={`${styles.alertMessageContent} px-3 py-2`}
                                  >
                                    {i18n.language === "ar"
                                      ? res.payload.data.errorAr
                                      : res.payload.data.error}
                                  </span>
                                );
                                setFinalValue("00");
                              }
                            });
                          }}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          value={values.cashAmount}
                        />
                        {errors.cashAmount && touched.cashAmount && (
                          <Form.Text className="text-error">
                            {errors.cashAmount}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} lg={4}>
                        <Form.Text>
                          <p className={`${styles.totalValuepara}`}>
                            {t("totalPoints")}
                            <span
                              className={`${styles.finalValueStyle} m-3 px-3 py-2`}
                            >
                              {finalValue}
                            </span>
                            {t("point")}
                          </p>
                        </Form.Text>
                      </Col>
                      <Col xs={12} className="mt-2">
                        {alertMessage !== null && alertMessage}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("bankName")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Select
                          className={`${styles.cashoutSelect}`}
                          name="bankCode"
                          id="bankCode"
                          onChange={(e) => {
                            setFieldValue("bankCode", e.currentTarget.value);
                          }}
                        >
                          <option value="">{t("chooseBank")}</option>
                          {banks.map((bank, index) => {
                            return (
                              <option value={bank["bank_code"]} key={index}>
                                {bank["bank_name"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                      {errors.bankCode && touched.bankCode && (
                        <Form.Text className="text-error">
                          {errors.bankCode}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("BankAccountNumber")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="accountNumber"
                          name="accountNumber"
                          onChange={handleChange}
                          value={values.accountNumber}
                        />
                      </Col>
                      {errors.accountNumber && touched.accountNumber && (
                        <Form.Text className="text-error">
                          {errors.accountNumber}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("receiverName")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="text"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="fullName"
                          name="fullName"
                          onChange={handleChange}
                          value={values.fullName}
                        />
                      </Col>
                      {errors.fullName && touched.fullName && (
                        <Form.Text className="text-error">
                          {errors.fullName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className={`${styles.cashoutInputContent} mb-3 p-3`}
                    >
                      <Col xs={12} md={6} className="mb-2">
                        <Form.Label className="d-block">
                          {t("confirmPass")}
                        </Form.Label>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Control
                          type="password"
                          className={`${styles.cashoutInput}`}
                          size="sm"
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                      </Col>
                      {errors.password && touched.password && (
                        <Form.Text className="text-error">
                          {errors.password}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <div className="d-grid">
                      <Button
                        type="submit"
                        variant="primary"
                        size="md"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("sendCash")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
      <OwnerCashTerms
        show={termsMessageLine}
        onHide={() => {
          setTermsMessageLine(false);
        }}
      />
    </div>
  );
}
