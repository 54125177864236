import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Table,
  Accordion,
  Badge,
} from "react-bootstrap";
import { Formik } from "formik";
import { FaBookmark } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import styles from "./EarnScheme.module.scss";
import { createEarningScheme } from "../../../../redux/features/adminSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../../redux/features/userSlice";

export default function EarnScheme() {
  const [items, setItems] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [newStatus, setNewSatus] = useState("new");

  const token = useSelector((state) => state.cpc.user.login.token);

  const initialValues = {
    earnedPoints: [
      {
        productGroupId: "",
        merchantPoints: "",
        customerPoints: "",
      },
    ],
  };

  const loadValues = {
    earnedPoints: formValues,
  };

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/points/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (searchValue !== "") {
            const filterItems = res.data.data.filter((itm) => {
              return itm.name.toLowerCase().includes(searchValue.toLowerCase());
            });
            setItems(filterItems);
          } else {
            setItems(res.data.data);
          }
          const array = [];
          res.data.data.map((item) => {
            const newObject = {
              productGroupId: item["productGroupId"],
              merchantPoints: item["merchantPoints"],
              customerPoints: item["customerPoints"],
            };
            array.push(newObject);
          });
          setFormValues(array);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [searchValue]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={`${styles.earnSchemeContent} py-3`}>
      <Container fluid>
        <Row>
          <Col xs={12} className="mb-3">
            <div className={`${styles.earnSchemePar} p-3`}>
              {t("definePointsValue")}
            </div>
          </Col>
          <Col xs={12}>
            <div className={`${styles.searchForm}`}>
              <Formik
                initialValues={{ searchValue: "" }}
                onSubmit={(values) => {}}
              >
                {({
                  values,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <InputGroup className={`${styles.searchInput}`}>
                        <InputGroup.Text className={`${styles.searchLabel}`}>
                          <BiSearch />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder={t("search")}
                          className={`${styles.searchField}`}
                          id="searchValue"
                          name="searchValue"
                          onChange={(e) => {
                            setFieldValue("searchValue", e.currentTarget.value);
                            setSearchValue(e.currentTarget.value);
                          }}
                          value={values.searchValue}
                        />
                      </InputGroup>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            <Formik
              initialValues={loadValues || initialValues}
              onSubmit={(values) => {
                dispatch(
                  createEarningScheme({
                    earnPoints: values,
                    token,
                  })
                ).then((res) => {
                  if (res.payload.data.success === true) {
                    window.location.reload();
                  }
                });
              }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form
                    className={`${styles.earnedSchemeForm}`}
                    onSubmit={handleSubmit}
                  >
                    <Accordion
                      defaultActiveKey={0}
                      className={`${styles.accordContent}`}
                    >
                      {items.map((item, i) => {
                        return (
                          <Accordion.Item
                            key={i}
                            eventKey={i}
                            className={`${styles.accordParent} mb-3`}
                          >
                            <Accordion.Header
                              className={`${styles.accordTitle}`}
                            >
                              {values.earnedPoints
                                .filter((el) => {
                                  return (
                                    el.productGroupId === item["productGroupId"]
                                  );
                                })
                                .map((earn) => {
                                  if (
                                    earn.customerPoints == 0 &&
                                    earn.merchantPoints == 0
                                  ) {
                                    return (
                                      // <FaBookmark
                                      //   className={`${styles.newIcon} me-2`}
                                      // />
                                      <h6 className="me-1">
                                        <div
                                          className={`${styles.newIcon}`}
                                        ></div>
                                      </h6>
                                    );
                                  }
                                })}
                              {/* {values.earnedPoints[i]["customerPoints"] == 0 ? (
                                <FaBookmark
                                  className={`${styles.newIcon} me-2`}
                                />
                              ) : null} */}
                              {/* <GiJerrycan className="me-1" /> */}
                              {item.name}
                            </Accordion.Header>
                            <Accordion.Body className={`${styles.accordBody}`}>
                              <Row className="mb-3 d-flex align-items-center">
                                <Col xs={12} md={4}>
                                  <Form.Label
                                    className={`${styles.earnedSchemeLabel}`}
                                  >
                                    {t("earnedPoints")}
                                  </Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Group as={Row}>
                                    <Col xs={12} md={6}>
                                      <Form.Label
                                        className={`${styles.earnedSchemeSmallLabel}`}
                                      >
                                        {t("merchant")}
                                      </Form.Label>

                                      <InputGroup
                                        className={`${styles.earnedSchemeInputGroupContent}`}
                                      >
                                        <Form.Control
                                          size="sm"
                                          type="number"
                                          className={`${styles.earnedSchemeInput}`}
                                          name={`earnedPoints[${i}].merchantPoints`}
                                          id={`earnedPoints[${i}].merchantPoints`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `earnedPoints[${i}].productGroupId`,
                                              item["productGroupId"]
                                            );
                                            setFieldValue(
                                              `earnedPoints[${i}].merchantPoints`,
                                              parseInt(e.currentTarget.value)
                                            );
                                          }}
                                          defaultValue={item["merchantPoints"]}
                                        />
                                        <InputGroup.Text
                                          className={`${styles.earnedSchemeInputTextt}`}
                                        >
                                          {t("points")}
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <Form.Label
                                        className={`${styles.earnedSchemeSmallLabel}`}
                                      >
                                        {t("customer")}
                                      </Form.Label>

                                      <InputGroup
                                        className={`${styles.earnedSchemeInputGroupContent}`}
                                      >
                                        <Form.Control
                                          size="sm"
                                          type="number"
                                          name={`earnedPoints[${i}].customerPoints`}
                                          id={`earnedPoints[${i}].customerPoints`}
                                          className={`${styles.earnedSchemeInput}`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `earnedPoints[${i}].customerPoints`,
                                              parseInt(e.currentTarget.value)
                                            );
                                          }}
                                          defaultValue={item["customerPoints"]}
                                        />
                                        <InputGroup.Text
                                          className={`${styles.earnedSchemeInputTextt}`}
                                        >
                                          {t("points")}
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Col>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mb-3 d-flex align-items-center">
                                <Col xs={12} md={4}>
                                  <Form.Label
                                    className={`${styles.earnedSchemeLabel}`}
                                  >
                                    {t("rewardValue")}
                                  </Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Group as={Row}>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className="mb-2 mb-md-0"
                                    >
                                      <InputGroup
                                        className={`${styles.earnedSchemeInputGroupContent}`}
                                      >
                                        <Form.Control
                                          size="sm"
                                          type="text"
                                          className={`${styles.earnedSchemeInputGroup}`}
                                          defaultValue={
                                            item["merchantRewardValue"]
                                          }
                                          disabled={true}
                                        />
                                        <InputGroup.Text
                                          className={`${styles.earnedSchemeInputText}`}
                                        >
                                          {t("egp")}
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <InputGroup
                                        className={`${styles.earnedSchemeInputGroupContent}`}
                                      >
                                        <Form.Control
                                          size="sm"
                                          type="text"
                                          className={`${styles.earnedSchemeInputGroup}`}
                                          defaultValue={
                                            item["customerRewardValue"]
                                          }
                                          disabled={true}
                                        />
                                        <InputGroup.Text
                                          className={`${styles.earnedSchemeInputText}`}
                                        >
                                          {t("egp")}
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </Col>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                    <div className="text-center">
                      <Button
                        type="submit"
                        variant="primary"
                        className={`${styles.saveBtn} mt-3`}
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
