import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { resetPassword } from "../../../redux/features/userSlice";
import styles from "./CustomerForgetPassword.module.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CustomerForgetPassword(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.phone === "") {
      navigate("/cpc/customer/forget/password");
    }
  }, []);

  const { t, i18n } = useTranslation();

  const customerForgetPasswordValidation = yup.object().shape({
    password: yup.string().required("كلمة السر مطلوبة"),
    otp: yup.string().required("الكود مطلوب"),
  });

  return (
    <div className={`${styles.ResetPassContent} py-5 px-2 p-md-5`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <h4 className={`${styles.verifyTitle}`}>{t("resetPass")}</h4>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`${styles.verifyFormContent} p-4 p-md-5`}>
              <Formik
                initialValues={{
                  phoneNumber: props.phone,
                  otp: "",
                  password: "",
                }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading("Loading...", {
                    transition: Zoom,
                    position: "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    resetPassword({
                      phoneNumber: values.phoneNumber,
                      otp: values.otp,
                      password: values.password,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      navigate(`/cpc/customer/login`);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={customerForgetPasswordValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.verifyLabel}`}>
                          {t("phoneNumber")}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone Number"
                          className={`${styles.verifyInput}`}
                          name="phoneNumber"
                          id="phoneNumber"
                          onChange={handleChange}
                          value={values.phoneNumber}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.verifyLabel}`}>
                          {t("newPass")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("enterNewPass")}
                          className={`${styles.verifyInput}`}
                          name="password"
                          id="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                        {errors.password && touched.password && (
                          <Form.Text className="text-error">
                            {errors.password}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.verifyLabel}`}>
                          {t("verifyOtp")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={6}
                          placeholder={t("enterOtp")}
                          className={`${styles.verifyOTP}`}
                          name="otp"
                          id="otp"
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          value={values.otp}
                        />
                        {errors.otp && touched.otp && (
                          <Form.Text className="text-error d-block">
                            {errors.otp}
                          </Form.Text>
                        )}
                        <Form.Text className={`${styles.emailNote}`}>
                          Check your Email, you'll get OTP code 6 digits write
                          it to verify Email.
                        </Form.Text>
                      </Form.Group>
                      <div className="d-grid mt-4">
                        <Button
                          type="submit"
                          variant="danger"
                          size="lg"
                          className={`${styles.verifyBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("confirmOtp")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
