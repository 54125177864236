import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Pagination from "../../../pagination/Pagination";
import styles from "./BrandVoucher.module.scss";

export default function BrandVoucher(props) {
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [showOption, setShowOption] = useState(1);
  const [vouchers, setVouchers] = useState([]);
  const [pendingVouchers, setPendingVouchers] = useState([]);
  const [successfulVouchers, setSuccessfulVouchers] = useState([]);

  let brandVouchers = props.brandvouchers;

  // const postLastIndex = page * postsPerPage;
  // const postFirstIndex = postLastIndex - postsPerPage;
  // const currentPosts = vouchers.slice(postFirstIndex, postLastIndex);

  useEffect(() => {
    if (showOption === 1) {
      const pendingVouchs = brandVouchers.filter(
        (vouch) => vouch["vendorTrxStatus"] === "pending"
      );
      setPendingVouchers(pendingVouchs);
    } else {
      const successVouchs = brandVouchers.filter(
        (vouch) => vouch["vendorTrxStatus"] === "successful"
      );
      setSuccessfulVouchers(successVouchs);
    }
  }, [brandVouchers]);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  // const currentPosts = brandVouchers.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * postsPerPage) % brandVouchers.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };


  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % brandVouchers.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });

  return (
    <div className={`${styles.giftCardsParent} p-4`}>
      <h3 className={`${styles.teamTitle} mb-3`}>كروت الهدايا</h3>
      <div>
        <div
          className={`${styles.fuelTypeContent} mb-4 d-flex justify-content-around`}
        >
          <Button
            variant="primary"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 1 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(1);
            }}
          >
            صالح
          </Button>
          <Button
            variant="primary"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 2 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(2);
            }}
          >
            مستخدم
          </Button>
        </div>
      </div>
      <div className="mb-4 d-flex flex-wrap justify-content-center align-items-center">
        {showOption === 1
          ? pendingVouchers
              .slice(itemOffset, endOffset)
              .map((reward, index) => {
                return (
                  <div className={`${styles.giftCard} m-2 p-3`} key={index}>
                    <div className={`${styles.giftCardInfo} w-100`}>
                      <div className={`${styles.giftCardImg} me-2`}>
                        <img src={reward["ImageUrl"]} />
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>كروت الهدايا</p>
                        <p>يصرف لدى</p>
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>{reward["voucher_value"]} جنيه</p>
                        <p>{reward["voucher_name"]}</p>
                      </div>
                    </div>
                    <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                      <div className={`${styles.notSettledStatus} px-4 py-1`}>
                        سيتم ارسال كود الهدية الشهر القادم
                      </div>
                    </div>
                  </div>
                );
              })
          : successfulVouchers
              .slice(itemOffset, endOffset)
              .map((reward, index) => {
                return (
                  <div className={`${styles.giftCard} m-2 p-3`} key={index}>
                    <div className={`${styles.giftCardInfo} w-100`}>
                      <div className={`${styles.giftCardImg} me-2`}>
                        <img src={reward["ImageUrl"]} />
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>كروت الهدايا</p>
                        <p>يصرف لدى</p>
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>{reward["voucher_value"]} جنيه</p>
                        <p>{reward["voucher_name"]}</p>
                      </div>
                    </div>
                    <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                      <Button
                        variant="primary"
                        size="sm"
                        className={`${styles.getCodeBtn} mx-1`}
                        disabled={reward["settled"] === "false" ? true : false}
                      >
                        ابعت كود الهدية
                      </Button>
                    </div>
                  </div>
                );
              })}
      </div>
      {showOption === 1 ? (
        <Pagination
          totalPosts={pendingVouchers.length}
          postsPerPage={postsPerPage}
          onChangePage={handlePageClick}
          currentPage={itemOffset}
        />
      ) : (
        <Pagination
          totalPosts={successfulVouchers.length}
          postsPerPage={postsPerPage}
          onChangePage={handlePageClick}
          currentPage={itemOffset}
        />
      )}
    </div>
  );
}
