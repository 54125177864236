import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Accordion,
  Button,
} from "react-bootstrap";
import { Formik } from "formik";
import styles from "./Freeunit.module.scss";
import "./style.css";

export default function Freeunit(props) {
  const [value, setValue] = useState("");

  useEffect(() => {
    props.unitValue.map((val) => {
      setValue(val["reward_value"]);
    });
  }, []);

  return (
    <div className={`${styles.freeUnit} py-4`}>
      <Formik
        initialValues={{ freeUnitValue: "", sDate: "", eDate: "" }}
        onSubmit={(values) => {
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group xs={3} as={Col} className="mb-3">
                  <Form.Label className={`${styles.inputLabel}`}>
                    Quantity
                  </Form.Label>
                  <InputGroup size="sm" className={`${styles.inputGroupForm}`}>
                    <Form.Control
                      type="text"
                      className={`${styles.unitInput}`}
                      name="freeUnitValue"
                      id="freeUnitValue"
                      onChange={handleChange}
                      disabled={true}
                      value={value}
                    />
                    <InputGroup.Text>Unit</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Col xs={12}>
                  <Accordion defaultActiveKey="2">
                    <Accordion.Item eventKey="0" className="mb-3">
                      <Accordion.Header
                        className={`${styles.unitOptionsHeader}`}
                      >
                        Select from listed units
                      </Accordion.Header>
                      <Accordion.Body className={`${styles.unitOptionsBody}`}>
                        <Form.Group as={Row} className="mb-3">
                          <Col sm={10}>
                            <Form.Check
                              type="radio"
                              label={
                                <div className={`${styles.unitOptions}`}>
                                  <p className="d-inline">Oil XPL 50w-4</p>
                                  <span className="ms-5">400 EGP</span>
                                </div>
                              }
                              name="formHorizontalRadios"
                              id="formHorizontalRadios1"
                            />
                            <Form.Check
                              type="radio"
                              label={
                                <div className={`${styles.unitOptions}`}>
                                  <p className="d-inline">Oil XPL 50w-4</p>
                                  <span className="ms-5">500 EGP</span>
                                </div>
                              }
                              name="formHorizontalRadios"
                              id="formHorizontalRadios1"
                            />
                            <Form.Check
                              type="radio"
                              label={
                                <div className={`${styles.unitOptions}`}>
                                  <p className="d-inline">Oil XPL 50w-4</p>
                                  <span className="ms-5">600 EGP</span>
                                </div>
                              }
                              name="formHorizontalRadios"
                              id="formHorizontalRadios1"
                            />
                          </Col>
                        </Form.Group>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-3">
                      <Accordion.Header
                        className={`${styles.unitOptionsHeader}`}
                      >
                        Add new units to rewards list
                      </Accordion.Header>
                      <Accordion.Body className={`${styles.unitOptionsBody}`}>
                        <Row className="d-flex justify-content-evenly align-items-center">
                          <Form.Group xs={4} as={Col} className="mb-3">
                            <Form.Label className={`${styles.inputLabelName}`}>
                              Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={`${styles.inputName}`}
                            />
                          </Form.Group>
                          <Form.Group xs={3} as={Col} className="mb-3">
                            <Form.Label className={`${styles.inputLabelPrice}`}>
                              Price
                            </Form.Label>
                            <InputGroup
                              size="sm"
                              className={`${styles.inputGroupFormPrice}`}
                            >
                              <Form.Control type="number" />
                              <InputGroup.Text>EGP</InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        className={`${styles.unitOptionsHeader}`}
                      >
                        Campaign Date
                      </Accordion.Header>
                      <Accordion.Body className={`${styles.unitOptionsBody}`}>
                        <Row className="d-flex justify-content-evenly align-items-center">
                          <Form.Group xs={4} as={Col} className="mb-3">
                            <Form.Label className={`${styles.inputLabelName}`}>
                              From
                            </Form.Label>
                            <Form.Control
                              type="date"
                              className={`${styles.inputName}`}
                            />
                          </Form.Group>
                          <Form.Group xs={4} as={Col} className="mb-3">
                            <Form.Label className={`${styles.inputLabelName}`}>
                              To
                            </Form.Label>
                            <Form.Control
                              type="date"
                              className={`${styles.inputName}`}
                            />
                          </Form.Group>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col xs={{ span: 4, offset: 4 }} className="mt-3">
                  <div className="d-gird">
                    <Button
                      variant="primary"
                      className={`${styles.saveBtn} w-100`}
                      size="md"
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
