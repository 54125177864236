import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FiCamera } from "react-icons/fi";
import { Formik } from "formik";
import Camera from "react-html5-camera-photo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  activateMerchant,
  scanNationalID,
} from "../../../redux/features/ownerSlice";
import CameraModal from "./Camera/CameraModal";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import styles from "./NationalID.module.scss";
import { useTranslation } from "react-i18next";

export default function NationalID() {
  const [confirmActive, setConfirmActive] = useState(false);
  const [showIDField, setShowIDField] = useState(false);
  const [nationalId, setNationalID] = useState("");
  const [lastFile, setLastFile] = useState("");

  const [imageFile, setImageFile] = useState(null);
  const [progressBar, setProgressBar] = useState(0);

  const [showCamera, setShowCamera] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { merchantID } = useParams();
  const { activatorID } = useParams();

  const token = useSelector((state) => state.cpc.user.login.token);

  const handlenational = (nationalID, showconfirm, showidfield) => {
    setNationalID(nationalID);
    setConfirmActive(showconfirm);
    setShowIDField(showidfield);
  };

  const handleimagefile = (file) => {
    setImageFile(file);
  };

  const handleprogress = (result) => {
    setProgressBar(result);
  };



  const { t } = useTranslation();

  return (
    <div className={`${styles.NationalIDContent}`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.DashboardTitle} d-inline`}>
              {t("confirmDetails")}
            </h3>
          </Col>
          <Col xs={12}>
            <div className={`${styles.NationalIDContent}`}>
              <h6 className="mb-3">{t("makesureClearImage")}</h6>
              {imageFile == null ? (
                <img
                  src={require("../../../imgs/Image 1.png")}
                  alt="idnational-photo"
                  className={`${styles.NationalIDPic}`}
                />
              ) : (
                <img
                  src={imageFile}
                  alt="idnational-photo"
                  className={`${styles.NationalIDPic}`}
                />
              )}
              {/* <div className={`${styles.progress} mt-2`}>
                <div
                  className={`${styles.progressBar}`}
                  style={{ width: `${progressBar}%` }}
                ></div>
              </div> */}
            </div>
            <Formik
              initialValues={{
                nationalIDFile: "",
                nationalID: "",
                nationalResult: "",
              }}
              onSubmit={(values, actions) => {
                const loadReq = toast.loading("Loading...", {
                  transition: Zoom,
                  position: "bottom-left",
                });
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 3000);
                dispatch(
                  activateMerchant({
                    merchantID,
                    activatorID,
                    nationalID: nationalId,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    navigate(
                      `/activator/${activatorID}/merchant/${merchantID}/activate`
                    );
                    successMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  }
                });
              }}
            >
              {({
                values,
                errors,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                      <Form.Label
                        // htmlFor="nationalIDFile"
                        className={`${styles.uploadBtn} w-100 btn btn-primary mx-1 mb-0`}
                        onClick={() => {
                          setShowCamera(true);
                        }}
                      >
                        <FiCamera className="mx-2" />
                        {t("idfrontSide")}
                      </Form.Label>
                      <Form.Control
                        hidden
                        type="file"
                        name="nationalIDFile"
                        id="nationalIDFile"
                        className={`${styles.NationalInput}`}
                        // onChange={(e) => {
                        // }}
                      />
                      <Form.Control
                        type="tel"
                        maxLength={14}
                        name="nationalResult"
                        id="nationalResult"
                        placeholder={t("nationalId")}
                        className={`${styles.nationalResult} mx-1`}
                        onChange={(e) => {
                          setNationalID(e.currentTarget.value);
                        }}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={nationalId}
                        disabled={showIDField === true ? false : true}
                      />
                    </Form.Group>
                    <div className="text-center">
                      <Button
                        type="submit"
                        variant="primary"
                        className={`${styles.confirmBtn} mt-3`}
                        disabled={confirmActive === true ? isSubmitting : true}
                      >
                        {t("confirm")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>

      <CameraModal
        show={showCamera}
        onHide={() => {
          setShowCamera(false);
        }}
        handlenational={handlenational}
        handleimagefile={handleimagefile}
        handleprogress={handleprogress}
      />
    </div>
  );
}
