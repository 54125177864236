import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import i18next from "i18next";
import Cookies from "js-cookie";
import { Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./StationOwnerForgetpass.module.scss";
import { stationOwnerForgetPassword } from "../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";

export default function StationOwnerForgetpass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lang, setLang] = useState(Cookies.get("i18next"));

  const { t } = useTranslation();

  useEffect(() => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  }, []);

  window.onload = () => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  };

  const stationOwnerForgetPassValidation = yup.object().shape({
    email: yup.string().email("الايميل غير صحيح").required("الايميل مطلوب"),
    phoneNumber: yup.string().required("رقم الموبايل مطلوب"),
  });

  return (
    <div className={`${styles.forgetPasswordContent} py-5 px-2 p-md-5`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <h4 className={`${styles.forgetTitle}`}>{t("forgetPass")}</h4>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`${styles.forgetFormContent} p-4 p-md-5`}>
              <Formik
                initialValues={{ email: "", phoneNumber: "" }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading("Loading...", {
                    transition: Zoom,
                    position: "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    stationOwnerForgetPassword({
                      phoneNumber: values.phoneNumber,
                      email: values.email,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      navigate("/cpc/stationOwner/login");
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={stationOwnerForgetPassValidation}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  touched,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.forgetLabel}`}>
                          {t("phoneNumber")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={11}
                          placeholder="******01"
                          className={`${styles.forgetInput} ${styles.phoneStyle}`}
                          name="phoneNumber"
                          id="phoneNumber"
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          value={values.phoneNumber}
                        />
                        {errors.phoneNumber && touched.phoneNumber && (
                          <Form.Text className="text-error">
                            {errors.phoneNumber}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.forgetLabel}`}>
                          {t("email")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          className={`${styles.forgetInput}`}
                          placeholder={t("enterEmail")}
                          name="email"
                          id="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <Form.Text className="text-error">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-grid mt-4">
                        <Button
                          type="submit"
                          variant="primary"
                          size="lg"
                          className={`${styles.forgetBtn} mb-3`}
                          disabled={isSubmitting}
                        >
                          {t("forgetPass")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
