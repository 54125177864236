import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import { handleLogout } from "../../../redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./OwnerDetail.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function OwnerDetail() {
  const [owner, setOwner] = useState({
    ownerName: "",
    businessName: "",
    phone: "",
    branches: [],
  });
  const [traderID, setTraderID] = useState("");
  const navigate = useNavigate();
  const merchantID = useSelector((state) => state.cpc.user.login.merchantID);
  const goverNameAr = useSelector((state) => state.cpc.user.login.goverNameAr);
  const goverNameEn = useSelector((state) => state.cpc.user.login.goverNameEn);
  const cityNameAr = useSelector((state) => state.cpc.user.login.cityNameAr);
  const cityNameEn = useSelector((state) => state.cpc.user.login.cityNameEn);

  useEffect(() => {
    axios
      .get(
        `https://evouchers.shareecoin.com/CPC/list-Owner-Traders/${merchantID}`
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          res.data.ownersAndTraders.map((owner) => {
            setOwner({
              ownerName: `${owner["owner_firstName"]} ${owner["owner_lastName"]}`,
              businessName: `${owner["owner_businessName"]}`,
              phone: `${owner["owner_phone"]}`,
              branches: owner["traders_data"],
            });
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div className={`${styles.OwnerDetailContent}`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.DashboardTitle} d-inline`}>
              {t("contactDetails")}
            </h3>
          </Col>
          <Col xs={12} className="mt-3" key={Math.random() * 10000}>
            <div className={`${styles.contactInfo} py-4 px-5`}>
              <h4 className={`${styles.contactTitle} mb-3`}>
                {t("contactDetails")}
              </h4>
              <div className={`${styles.element}`}>
                <h5>{t("ownerName")}:</h5>
                <h5>{owner.ownerName}</h5>
              </div>
              <div className={`${styles.element}`}>
                <h5>{t("email")}:</h5>
                <h5></h5>
              </div>
              <div className={`${styles.element}`}>
                <h5>{t("phoneNumber")}:</h5>
                <h5>{owner.phone}</h5>
              </div>
              <div className={`${styles.element}`}>
                <h5>{t("nationalId")}:</h5>
                <h5></h5>
              </div>
              <div className={`${styles.element}`}>
                {i18next.language == "ar" ? (
                  <h5>{`${goverNameAr}, ${cityNameAr}`}</h5>
                ) : (
                  <h5>{`${goverNameEn}, ${cityNameEn}`}</h5>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            <div className={`${styles.locationInfo} py-4 px-5`}>
              <h4 className={`${styles.location} mb-3`}>
                {t("branchDetials")}
              </h4>
              <Formik
                initialValues={{ traderId: "" }}
                onSubmit={(values) => {}}
              >
                {({
                  values,
                  errros,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} />
                      <Col xs={6}>
                        <Form.Select
                          className={`${styles.selectBranchList}`}
                          name="traderId"
                          id="traderId"
                          onChange={(e) => {
                            setFieldValue("traderId", e.target.value);
                            setTraderID(e.target.value);
                          }}
                        >
                          <option value="">{t("selectBranch")}</option>
                          {owner.branches.map((branch, i) => {
                            return (
                              <option value={branch["trader_id"]} key={i}>
                                {branch["trader_branchName"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Form>
                  );
                }}
              </Formik>
              {owner.branches
                .filter((trader) => {
                  return trader["trader_id"] == traderID;
                })
                .map((shop, index) => {
                  return (
                    <div
                      className={`${styles.ownerBranchesContent} mt-3`}
                      key={index}
                    >
                      <h4 className="mb-3">{shop["trader_branchName"]}</h4>
                      <div className={`${styles.element} mb-2`}>
                        <h5 className="d-inline me-4">Branch Manager:</h5>
                        <span>{`${shop["trader_firstName"]} ${shop["trader_lastName"]}`}</span>
                      </div>
                      <div className={`${styles.element} mb-2`}>
                        <h5 className="d-inline me-4">Branch Phone Number:</h5>
                        <span>{`${shop["trader_phone"]}`}</span>
                      </div>
                      <div className={`${styles.element} mb-2`}>
                        <h5 className="d-inline me-4">Address:</h5>
                        <span>{`${shop["trader_governorate_name_en"]}, ${shop["trader_city_name_en"]}`}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
