import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { handleLogout } from "../../../redux/features/userSlice";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { userForgetpass } from "../../../redux/features/userSlice";
import styles from "./SendCode.module.scss";
import { useTranslation } from "react-i18next";

export default function SendCode(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendOtpValdation = yup.object().shape({
    email: yup.string().required("Email is required"),
  });

  const { t } = useTranslation();

  return (
    <div className={`${styles.sendCodeContent} py-5 px-2 p-md-5`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <h4 className={`${styles.forgetTitle}`}>{t("forgetPass")}</h4>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`${styles.forgetFormContent} p-4 p-md-5`}>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading("Loading...", {
                    transition: Zoom,
                    position: "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    userForgetpass({
                      email: values.email,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      props.handlesendcode(res.meta.arg.email);
                      navigate(`/cpc/reset/password`);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else if (res.payload.data.statusCode === 401) {
                      dispatch(handleLogout());
                      navigate("/cpc/login");
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={sendOtpValdation}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  touched,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.forgetLabel}`}>
                          {t("email")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t("enterEmail")}
                          className={`${styles.forgetInput}`}
                          name="email"
                          id="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <Form.Text className="text-error">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-grid mt-4">
                        <Button
                          type="submit"
                          variant="primary"
                          size="lg"
                          className={`${styles.forgetBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("sendOtp")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
