import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./ListingShops.module.scss";
import { useTranslation } from "react-i18next";

export default function ListingShops({ owners }) {
  const [shops, setShops] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={`${styles.listingShopsContent}`}>
      {owners.map((owner) => {
        return (
          <div
            className={`${styles.bgGrey} w-100 mb-3`}
            key={Math.random() * 1000}
          >
            <Container>
              <Row>
                <Col xs={12} lg={10}>
                  <div className={`${styles.infoContent} p-3`}>
                    <div className="w-100">
                      <h5 className="d-inline">{owner["businessName"]}</h5>
                    </div>
                    <div className="w-100 d-flex justify-content-left align-items-center mt-2">
                      <div className={`${styles.activatorTitle}`}>
                        <h4>{`${owner["email"]}`}</h4>
                        <h6>{`${owner["phone"]}`}</h6>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg={2} className="px-0">
                  <Button
                    variant="primary"
                    className={`${styles.editBtn}`}
                    onClick={() => {
                      navigate(
                        `/activator/dashboard/${owner["OwnerID"]}/addshop`
                      );
                    }}
                  >
                    {t("createBranche")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        );
      })}
    </div>
  );
}
