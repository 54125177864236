import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { handleLogout } from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./Dashboard.module.scss";
import i18next from "i18next";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shopID = useParams().shopID;
  const [shop, setShop] = useState([]);
  const [shopId, setShopId] = useState("");

  const [owner, setOwner] = useState({
    ownerName: "",
    businessName: "",
    phone: "",
    branches: [],
  });

  const { t } = useTranslation();

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/reports/listOwners", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const filteredShop = res.data.owners.filter((own) => {
            return own.userID == shopID;
          });
          setShop(filteredShop);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://evouchers.shareecoin.com/CPC/list-Owner-Traders/${shopID}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          res.data.ownersAndTraders.map((owner) => {
            setOwner({
              ownerName: `${owner["owner_firstName"]} ${owner["owner_lastName"]}`,
              businessName: `${owner["owner_businessName"]}`,
              phone: `${owner["owner_phone"]}`,
              branches: owner["traders_data"],
            });
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  return (
    <div className={`${styles.adminDashboard} p-3`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <IoMdArrowRoundBack
              className={`${styles.backBtn} fs-4 me-2`}
              onClick={() => {
                navigate("/admin/dashboard/merchants/all");
              }}
            />
            <h3 className={`${styles.DashboardTitle} d-inline`}>
              {t("details")}
            </h3>
          </Col>
          {shop.map((sh) => {
            return (
              <Col xs={12} md={6} key={Math.random() * 10000}>
                <div
                  className={`${styles.infoContent} w-100 d-flex align-items-center p-3`}
                >
                  <img
                    src={require("../../../imgs/shop2.jpg")}
                    className={`${styles.ownerChar}`}
                    alt="oilshop-logo"
                  />
                  <div className={`${styles.ownerTitle} ms-3`}>
                    <h4>
                      {t("regDate")}: {sh["creationDate"]}
                    </h4>
                    <h5>{`${sh.businessName}`}</h5>
                    <h6></h6>
                  </div>
                </div>
              </Col>
            );
          })}
          {shop.map((sh) => {
            return (
              <Col xs={12} className="mt-3" key={Math.random() * 10000}>
                <div className={`${styles.contactInfo} py-4 px-5`}>
                  <h4 className={`${styles.contactTitle} mb-3`}>
                    {t("contactDetails")}
                  </h4>
                  <div className={`${styles.element}`}>
                    <h5>{t("ownerName")}:</h5>
                    <h5>{`${sh.firstName} ${sh.lastName}`}</h5>
                  </div>
                  <div className={`${styles.element}`}>
                    <h5>{t("phoneNumber")}:</h5>
                    <h5>{`${sh.phone}`}</h5>
                  </div>
                  <div className={`${styles.element}`}>
                    <h5>{t("nationalId")}:</h5>
                    <h5>{`${sh.NID}`}</h5>
                  </div>
                  <div className={`${styles.element}`}>
                    {i18next.language === "en" ? (
                      <h5>{`${sh["districts.governorate.governorate_name_en"]}, ${sh["districts.city.city_name_en"]}`}</h5>
                    ) : (
                      <h5>{`${sh["districts.governorate.governorate_name_ar"]}, ${sh["districts.city.city_name_ar"]}`}</h5>
                    )}
                  </div>
                </div>
              </Col>
            );
          })}

          <Col xs={12} className="mt-3">
            <div className={`${styles.locationInfo} py-4 px-5`}>
              <h4 className={`${styles.location} mb-3`}>
                {t("branchDetials")}
              </h4>
              <Formik
                initialValues={{ traderId: "" }}
                onSubmit={(values) => {
                  setShopId(values.traderId);
                }}
              >
                {({
                  values,
                  errros,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} />
                      <Col xs={12} sm={6}>
                        <Form.Select
                          name="traderId"
                          id="traderId"
                          onChange={(e) => {
                            setFieldValue("traderId", e.target.value);
                            setShopId(e.target.value);
                          }}
                        >
                          <option value="">{t("selectBranch")}</option>
                          {owner.branches.map((shop, i) => {
                            return (
                              <option
                                value={shop["trader_id"]}
                                key={i}
                              >{`${shop["trader_branchName"]}`}</option>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </Form>
                  );
                }}
              </Formik>
              {owner.branches
                .filter((trader) => {
                  return trader["trader_id"] == shopId;
                })
                .map((shop, index) => {
                  return (
                    <div
                      className={`${styles.ownerBranchesContent} mt-3`}
                      key={index}
                    >
                      <h5 className="mb-3">{`${shop["trader_branchName"]}`}</h5>
                      <div className={`${styles.element} mb-3`}>
                        <h5 className="d-inline me-4">{t("branchManager")}:</h5>
                        <span>{`${shop["trader_firstName"]} ${shop["trader_lastName"]}`}</span>
                      </div>
                      <div className={`${styles.element} mb-3`}>
                        <h5 className="d-inline me-4">{t("branchPhone")}</h5>
                        <span>{`${shop["trader_phone"]}`}</span>
                      </div>
                      <div className={`${styles.element} mb-3`}>
                        <h5 className="d-inline me-4">{t("address")}:</h5>
                        <span>{`${shop["trader_address"]}`}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
