import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import styles from "./Shops.module.scss";
import ListingShops from "./listing/ListingShops";
import Pagination from "../../pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";

export default function Shops() {
  const [owners, setOwners] = useState([]);
  const [allowners, setAllOwners] = useState([]);
  const [ownerID, setOwnerID] = useState("");

  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);

  const token = useSelector((state) => state.cpc.user.login.token);

  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listBusinesses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setOwners(res.data.businesses);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const postLastIndex = page * postsPerPage;
  const postFirstIndex = postLastIndex - postsPerPage;
  const currentPosts = owners.slice(postFirstIndex, postLastIndex);

  const { t } = useTranslation();

  return (
    <div className={`${styles.activatorShop} p-1`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.shopTitle}`}>{t("Merchants")}</h3>
          </Col>
          <Col xs={12} className="mt-1">
            <div className={`${styles.shopContent}`}>
              <ListingShops owners={owners} />
            </div>
          </Col>
          <Col xs={12}>
            <Pagination
              totalPosts={total}
              postsPerPage={postsPerPage}
              onChangePage={(value) => {
                setPage(value);
              }}
              currentPage={page}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
