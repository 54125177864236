import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const adminAddActivator = createAsyncThunk(
  "admin/add/activator",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/create/user",
        {
          firstName: info.firstName,
          lastName: info.lastName,
          phone: info.phone,
          email: info.email,
          NID: info.nationalID,
          districts: info.districts,
          role_id: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${info.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const adminUpdateActivator = createAsyncThunk(
  "admin/edit/activator",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        "https://evouchers.shareecoin.com/CPC/update/user",
        {
          userID: info.activatorID,
          firstName: info.firstName,
          lastName: info.lastName,
          phone: info.phoneNumber,
          email: info.email,
          NID: info.nationalID,
          districts: info.districts,
        },
        {
          headers: {
            Authorization: `Bearer ${info.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const assignActivator = createAsyncThunk(
  "assign/activator",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/assignMerchants",
        {
          merchantID: data.shopID,
          activatorID: data.activatorID,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const addOwner = createAsyncThunk(
  "add/owner",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/create/user",
        {
          firstName: data.ownerName,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          NID: data.nationalID,
          districts: [
            {
              govID: data.govID,
              cityID: data.cityID,
            },
          ],
          role_id: 3,
          businessName: data.businessName,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const adminUploadProducts = createAsyncThunk(
  "upload/products",
  async (data, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", data.productFile);
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/CPC/uploadProducts",
        formData,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const adminCreateCampaign = createAsyncThunk(
  "create/campaign",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.post(
        "https://evouchers.shareecoin.com/CPC/campaigns",
        {
          campaign_name: data.campaignName,
          start_date: data.startDate,
          end_date: data.endDate,
          product_id: data.productIDs,
          earning_type_id: data.earningID,
          reward_type_id: data.rewardID,
          min_purchase_qty: 1,
          max_purchase_qty: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createEarningScheme = createAsyncThunk(
  "create/earn",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/points/update",
        {
          earningScheme: data.earnPoints.earnedPoints,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createStation = createAsyncThunk(
  "create/station",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/fuelStation/createStation",
        {
          station_name: data.stationName,
          office_id: data.officeID,
          cityID: data.cityID,
          governorateID: data.governID,
          address_line: data.stationAddress,
          phone: data.ownerPhoneNumber,
          CR: data.stationCode,
          station_type: data.stationType,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const updateStationDetails = createAsyncThunk(
  "update/station/details",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `https://evouchers.shareecoin.com/CPC/fuelStation/station?id=${data.stationID}`,
        {
          address_line: data.stationAddress,
          station_name: data.stationName,
          station_code: data.stationCode,
          phone_number: data.phone,
          email: data.email,
          office_id: data.officeID,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const activateDeactivateStation = createAsyncThunk(
  "station/activate",
  async (data, rejectWithValue) => {
    try {
      const result = await axios.put(
        "https://evouchers.shareecoin.com/CPC/fuelStation/updateStation",
        {
          stationId: data.stationID,
          status: data.status,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deactivateMerchant = createAsyncThunk(
  "merchant/deactivate",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        "https://evouchers.shareecoin.com/CPC/deactivateUser",
        {
          phone: data.merchantPhone,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const activatorTeamReportFilter = createAsyncThunk(
  "filter/activation/report",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        "https://evouchers.shareecoin.com/CPC/listActivators-filtered",
        {
          params: data.filterParams,
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const downloadActivatorTeamReportFilter = createAsyncThunk(
  "download/activation/report",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        "https://evouchers.shareecoin.com/CPC/downloadActivatorsReport",
        {
          responseType: "blob",
          params: data.downloadParams,
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  progressBar: 0,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [adminAddActivator.fulfilled]: (state, action) => {},
    [adminUploadProducts.pending]: (state, action) => {},
    [adminUploadProducts.fulfilled]: (state, action) => {},
    [createStation.fulfilled]: (state, action) => {},
  },
});

export const {} = adminSlice.actions;

export default adminSlice.reducer;
