import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import styles from "./Activated.module.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../../../../redux/features/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Activated() {
  const [activatedShops, setActivatedShops] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const { activatorID } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listActivators", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const targetActivator = res.data.activators.filter((activ) => {
            return activ.activatorId == activatorID;
          });
          targetActivator.map((activator) => {
            const activatedShops = activator.assignedMerchants.filter(
              (assign) => {
                return assign.activationStatus === 1;
              }
            );
            setActivatedShops(activatedShops);
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  return (
    <div className={`${styles.activatedContent} p-2`}>
      {activatedShops.length !== 0 ? (
        activatedShops.map((shop) => {
          return (
            <div
              className={`${styles.bgBlue} p-4 w-100 mb-3`}
              key={Math.random() * 10000}
            >
              <Container>
                <Row>
                  <Col xs={12} md={8}>
                    <div className={`${styles.infoContent}`}>
                      <h5>{shop.businessName}</h5>
                      <p className="d-inline">{`${t("ownerName")}: ${
                        shop.ownerFirstName
                      } ${shop.ownerLastName}`}</p>
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div
                      className={`${styles.successIconContent} mt-2 mt-md-0 h-100 d-flex justify-content-center justify-content-md-end align-items-center`}
                    >
                      <FaRegCheckCircle className={`${styles.verifyIcon}`} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        })
      ) : (
        <div className={`${styles.bgBlue} text-center p-4 w-100 mb-3`}>
          <h4>{t("noActivatedMerchants")}</h4>
        </div>
      )}
    </div>
  );
}
