import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./MessageBox.module.scss";

export default function MessageBox(props) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.messageBoxContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.giftPointBody}`}>
          <h4>ستصلك رسالة بها رابط تسجيل الدخول</h4>
          <div className="d-grid">
            <Button
              size="sm"
              variant="danger"
              className={`${styles.okBtn} mt-2`}
              onClick={() => {
                props.onHide();
              }}
            >
              تمام
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
