import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { handleLogout } from "../../../../redux/features/userSlice";
import styles from "./AddNewStation.module.scss";
import { createStation } from "../../../../redux/features/adminSlice";

export default function AddNewStation() {
  const [areas, setAreas] = useState([]);
  const [offices, setOffices] = useState([]);
  const [cities, setCities] = useState([]);
  const [officeGovern, setOfficeGovern] = useState("");
  const [officeID, setOfficeID] = useState(null);
  const [governID, setGovernID] = useState(null);
  const [areaID, setAreaID] = useState(null);

  const [officeDisable, setOfficeDisable] = useState(true);
  const [cityDisable, setCityDisable] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/getAreas", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setAreas(res.data.data);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/getOffices", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const filterOffices = res.data.data.filter(
            (off) => off["area_id"] == areaID
          );
          setOffices(filterOffices);
          if (officeID !== null) {
            const officeGovernn = filterOffices.filter(
              (offi) => offi["id"] == officeID
            );
            setOfficeGovern(officeGovernn[0]["officeGovernorate"]);
            setGovernID(officeGovernn[0]["officeGovernorate"]["id"].toString());
          }
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [areaID, officeID]);

  console.log(officeGovern);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: officeGovern.id,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [officeGovern]);

  const token = useSelector((state) => state.cpc.user.login.token);

  return (
    <div className={`${styles.addNewStationStyle} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {t("addStation")}
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              stationName: "",
              areaID: "",
              ownerPhoneNumber: "",
              officeID: "",
              stationCode: "",
              cityID: "",
              stationType: "",
              stationAddress: "",
            }}
            onSubmit={(values, actions) => {
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18next.language == "ar" ? "bottom-right" : "bottom-left",
              });
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 3000);
              dispatch(
                createStation({
                  token,
                  stationName: values.stationName,
                  officeID: values.officeID,
                  cityID: values.cityID,
                  governID,
                  stationAddress: values.stationAddress,
                  ownerPhoneNumber: values.ownerPhoneNumber,
                  stationCode: values.stationCode,
                  stationType: values.stationType,
                })
              ).then((res) => {
                if (res.payload.data.statusCode === 200) {
                  navigate("/admin/dashboard/stations");
                  successMessage(
                    res.payload.data.message,
                    res.payload.data.messageAr,
                    loadReq
                  );
                } else if (res.payload.data.statusCode === 401) {
                  dispatch(handleLogout());
                  navigate("/cpc/login");
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("stationName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("stationName")}
                        className={`${styles.addInput}`}
                        name="stationName"
                        id="stationName"
                        onChange={handleChange}
                        value={values.stationName}
                      />
                      {errors.stationName && touched.stationName && (
                        <Form.Text className="text-error">
                          {errors.stationName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        المناطق
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="areaID"
                        id="areaID"
                        onChange={(e) => {
                          if (e.currentTarget.value == "") {
                            setFieldValue("officeID", "");
                            setFieldValue("cityID", "");
                            setOfficeDisable(true);
                            setCityDisable(true);
                          } else {
                            setAreaID(e.currentTarget.value);
                            setFieldValue("areaID", e.currentTarget.value);
                            setOfficeDisable(false);
                          }
                        }}
                        value={values.areaID}
                      >
                        <option value="">اختر المنطقة</option>
                        {areas.map((area) => {
                          return (
                            <option key={area.id} value={area.id}>
                              {i18next.language == "ar"
                                ? area["area_name_ar"]
                                : area["area_name_en"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("stationOwnerPhoneNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={11}
                        placeholder={t("stationOwnerPhoneNumber")}
                        className={`${styles.addInput}`}
                        name="ownerPhoneNumber"
                        id="ownerPhoneNumber"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.ownerPhoneNumber}
                      />
                      {errors.ownerPhoneNumber && touched.ownerPhoneNumber && (
                        <Form.Text className="text-error">
                          {errors.ownerPhoneNumber}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Col xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        مكاتب البيع
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="officeID"
                        id="officeID"
                        onChange={(e) => {
                          if (e.currentTarget.value == "") {
                            setFieldValue("cityID", "");
                            setCityDisable(true);
                          } else {
                            setOfficeID(e.currentTarget.value);
                            setFieldValue("officeID", e.currentTarget.value);
                            setCityDisable(false);
                          }
                        }}
                        value={values.officeID}
                        disabled={officeDisable}
                      >
                        <option value="">اختر مكتب البيع التابع</option>
                        {offices.map((office) => {
                          return (
                            <option key={office.id} value={office.id}>
                              {i18next.language == "ar"
                                ? office["office_name_ar"]
                                : office["office_name_en"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        رقم كود المحطة
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="اكتب رقم كود المحطة"
                        className={`${styles.addInput}`}
                        name="stationCode"
                        id="stationCode"
                        onChange={handleChange}
                        value={values.stationCode}
                      />
                      {errors.stationCode && touched.stationCode && (
                        <Form.Text className="text-error">
                          {errors.stationCode}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        نوع المحطة
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="stationType"
                        id="stationType"
                        onChange={(e) => {
                          setFieldValue("stationType", e.currentTarget.value);
                        }}
                        value={values.stationType}
                      >
                        <option value="">اختر نوع المحطة</option>
                        <option value="owner">ملك</option>
                        <option value="franchise">متعهد</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        المدينة
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="cityID"
                        id="cityID"
                        onChange={(e) => {
                          setFieldValue("cityID", e.currentTarget.value);
                        }}
                        value={values.cityID}
                        disabled={cityDisable}
                      >
                        <option value="">اختر المدينة</option>
                        {cities.map((city) => {
                          return (
                            <option key={city.cityID} value={city.cityID}>
                              {i18next.language == "ar"
                                ? city["city_name_Ar"]
                                : city["city_name_en"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("stationAddress")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("stationAddress")}
                        className={`${styles.addInput}`}
                        name="stationAddress"
                        id="stationAddress"
                        onChange={handleChange}
                        value={values.stationAddress}
                      />
                      {errors.stationAddress && touched.stationAddress && (
                        <Form.Text className="text-error">
                          {errors.stationAddress}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} className="mt-3 text-center">
                      <Button
                        type="submit"
                        size="md"
                        variant="primary"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}
