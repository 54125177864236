import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import styles from "./ReportActivator.module.scss";
import { useTranslation } from "react-i18next";

export default function ReportActivator() {
  const navigate = useNavigate();

  const userID = useParams().activatorID;

  const { t } = useTranslation();

  return (
    <div className={`${styles.reportActivator} p-2 p-md-3`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/team");
                }}
              />
              {t("reportActivator")}
            </h3>
          </Col>
          <Col xs={12}>
            <NavLink
              to={`/admin/dashboard/team/report/${userID}/assigned`}
              className={`${styles.assignBtn} btn btn-primary me-3 mt-2 mb-sm-0`}
            >
              {t("assignedMerchants")}
            </NavLink>
            <NavLink
              to={`/admin/dashboard/team/report/${userID}/activated`}
              className={`${styles.activateBtn} btn btn-primary mt-2`}
            >
              {t("activatedMerchants")}
            </NavLink>
          </Col>
          <Col xs={12} className="mt-2">
            <div className={`${styles.reportContent}`}>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
