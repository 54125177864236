import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import * as yup from "yup";
import { Formik } from "formik";
import styles from "./AddActivator.module.scss";
import axios from "axios";
import { handleLogout } from "../../../../redux/features/userSlice";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { IoMdArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { adminAddActivator } from "../../../../redux/features/adminSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function AddActivator() {
  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);

  const token = useSelector((state) => state.cpc.user.login.token);

  const [area, setArea] = useState([]);
  const [selectList, setSelectList] = useState([]);

  const multiSelectRef = useRef();

  const handleResetMultiSelect = () => {
    multiSelectRef.current.resetSelectedValues();
  };

  const handleRemoveSelectedItem = (value) => {
    multiSelectRef.current.onRemoveSelectedItem(value);
  };

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          handleResetMultiSelect();
          setCities(res.data["List of Cities"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [governID]);

  const addActivatorValidation = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup.number().required("Phone number is required"),
    nationalID: yup.number().required("National ID is required"),
    email: yup.string().email().required("Email is required"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  return (
    <div className={`${styles.addActivator} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/team");
                }}
              />
              {t("addActivator")}
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              phoneNumber: "",
              nationalID: "",
              email: "",
              districts: [],
            }}
            onSubmit={(values, actions) => {
              const loadReq = toast.loading(t("loading"), {
                transition: Zoom,
                position:
                  i18next.language == "ar" ? "bottom-right" : "bottom-left",
              });
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 3000);
              dispatch(
                adminAddActivator({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phone: values.phoneNumber,
                  nationalID: values.nationalID,
                  email: values.email,
                  districts: values.districts,
                  token,
                })
              ).then((res) => {
                if (res.payload.data.statusCode === 200) {
                  navigate("/admin/dashboard/team");
                  successMessage(
                    res.payload.data.message,
                    res.payload.data.messageAr,
                    loadReq
                  );
                } else if (res.payload.data.statusCode === 401) {
                  dispatch(handleLogout());
                  navigate("/cpc/login");
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                } else {
                  failedMessage(
                    res.payload.data.error,
                    res.payload.data.errorAr,
                    loadReq
                  );
                }
              });
            }}
            validationSchema={addActivatorValidation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form id="addActivatorForm" onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("firstName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("firstName")}
                        className={`${styles.addInput}`}
                        name="firstName"
                        id="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                      />
                      {errors.firstName && touched.firstName && (
                        <Form.Text className="text-error">
                          {errors.firstName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("lastName")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("lastName")}
                        className={`${styles.addInput}`}
                        name="lastName"
                        id="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                      />
                      {errors.lastName && touched.lastName && (
                        <Form.Text className="text-error">
                          {errors.lastName}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("phoneNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={11}
                        placeholder={t("phoneNumber")}
                        className={`${styles.addInput}`}
                        name="phoneNumber"
                        id="phoneNumber"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.phoneNumber}
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <Form.Text className="text-error">
                          {errors.phoneNumber}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("regNumber")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={6}
                        placeholder={t("regNumber")}
                        className={`${styles.addInput}`}
                        name="nationalID"
                        id="nationalID"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.nationalID}
                      />
                      {errors.nationalID && touched.nationalID && (
                        <Form.Text className="text-error">
                          {errors.nationalID}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={12} sm={6} className="mb-2">
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("email")}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className={`${styles.addInput}`}
                        name="email"
                        id="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <Form.Text className="text-error">
                          {errors.email}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6}>
                      <Form.Label className={`${styles.addInputLabel}`}>
                        {t("gover")}
                      </Form.Label>
                      <Form.Select
                        className={`${styles.addSelect}`}
                        name="govID"
                        id="govID"
                        onChange={(e) => {
                          setGovernID(e.currentTarget.value);
                          setFieldValue("govID", e.currentTarget.value);
                          setAcitveCity(true);
                        }}
                        value={values.govID}
                      >
                        <option value="">{t("selectGover")}</option>
                        {governs.map((gov) => {
                          return (
                            <option
                              key={gov.GovernateID}
                              value={gov.GovernateID}
                            >
                              {i18next.language == "ar"
                                ? gov["governorate_name_ar"]
                                : gov["governorate_name_en"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} sm={6}>
                      <Form.Label
                        className={`${styles.addInputLabel}`}
                        htmlFor="cityIDs_input"
                      >
                        {t("districts")}
                      </Form.Label>
                      <Multiselect
                        className={`${styles.addMultiSelect}`}
                        ref={multiSelectRef}
                        customCloseIcon={<AiFillCloseCircle className="ms-1" />}
                        options={cities}
                        displayValue={
                          i18next.language == "ar"
                            ? "city_name_Ar"
                            : "city_name_en"
                        }
                        placeholder={t("selectDistricts")}
                        name="cityIDs"
                        id="cityIDs"
                        disable={!activeCity}
                        onSelect={(selectedList, selectedItem) => {
                          setSelectList(selectedList);
                          let selArray = [];
                          selectedList.map((sel) => {
                            selArray.push({
                              govID: parseInt(governID),
                              cityID: sel.cityID,
                            });
                          });
                          setFieldValue("districts", selArray);
                        }}
                        onRemove={(selectedList, selectedItem) => {
                          setSelectList(selectedList);
                          let selArray = [];
                          selectedList.map((sel) => {
                            selArray.push({
                              govID: parseInt(governID),
                              cityID: sel.cityID,
                            });
                          });
                          setFieldValue("districts", selArray);
                        }}
                      />
                      <div className="mt-3">
                        {selectList.map((sel, index) => {
                          return (
                            <span
                              className={`${styles.chipCustom}`}
                              key={index}
                            >
                              {i18next.language == "ar"
                                ? sel["city_name_Ar"]
                                : sel["city_name_en"]}
                              <i
                                className={`${styles.closeIcon} ms-1`}
                                onClick={() => {
                                  handleRemoveSelectedItem(sel);
                                }}
                              >
                                <IoMdClose />
                              </i>
                            </span>
                          );
                        })}
                      </div>
                    </Col>
                    <Col xs={12} className="mt-3 text-center">
                      <Button
                        type="submit"
                        size="md"
                        variant="primary"
                        form="addActivatorForm"
                        className={`${styles.saveBtn}`}
                        disabled={isSubmitting}
                      >
                        {t("save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}
