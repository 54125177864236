import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const scanNationalID = createAsyncThunk(
  "owner/scan/nationalID",
  async (data, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("image", data.scanNationalIDFile);
    formData.append("OwnerId", data.ownerID);

    try {
      const result = axios.post(
        "https://hook.eu1.make.com/teb9je5cyg1jvspz0p977grk48znlr7d",
        formData
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const activateMerchant = createAsyncThunk(
  "activate/merchant",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.put(
        "https://evouchers.shareecoin.com/CPC/merchant/activate",
        {
          merchantId: data.merchantID,
          activatorId: data.activatorID,
          NID: data.nationalID,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const earnPointsAfterScanQr = createAsyncThunk(
  "owner/earnPoints/qr",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios({
        method: "post",
        url: "https://evouchers.shareecoin.com/CPC/points/earn",
        params: {
          QRCode: data.qrCode,
        },
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "change/password",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.post(
        "https://evouchers.shareecoin.com/CPC/customer/changePassword",
        {
          mobileNumber: data.mobileNumber,
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {};

export const ownerSlice = createSlice({
  name: "owner",
  initialState,
  reducers: {},
  extraReducers: {
    [scanNationalID.pending]: (state, action) => {},
  },
});

export const {} = ownerSlice.actions;

export default ownerSlice.reducer;
