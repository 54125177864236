import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styles from "./Product.module.scss";
import UploadBatch from "./uploadBatch/UploadBatch";
import Campaigns from "./campaign/Campaigns";
import "./style.css";
import EarnScheme from "./earnScheme/EarnScheme";
import { useTranslation } from "react-i18next";

export default function Product() {
  const [showOption, setShowOption] = useState(1);

  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={`${styles.productsContent} p-2`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.productTitle}`}>{t("products")}</h3>
          </Col>
          <Col xs={12} md={6} className="mb-3">
            <div className={`${styles.btnSaveGroup}`}>
              {showOption === 2 ? (
                <Button
                  variant="light"
                  className={`${styles.reportBtn}`}
                  onClick={() => {
                    navigate("/admin/dashboard/products/redeem/report");
                  }}
                >
                  {t("redeemReport")}
                </Button>
              ) : (
                showOption === 1 && (
                  <Button
                    variant="light"
                    className={`${styles.reportBtn}`}
                    onClick={() => {
                      navigate("/admin/dashboard/products/report");
                    }}
                  >
                    {t("batchReport")}
                  </Button>
                )
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex flex-wrap justify-content-center align-items-center mt-4">
              <Button
                variant="primary"
                size="sm"
                className={`${styles.productBtn} me-2 ${
                  showOption === 1 && styles.productBtnActive
                }`}
                onClick={() => {
                  setShowOption(1);
                }}
              >
                {t("uploadBatch")}
              </Button>
              <Button
                variant="primary"
                size="sm"
                className={`${styles.productBtn} me-2 ${
                  showOption === 2 && styles.productBtnActive
                }`}
                onClick={() => {
                  setShowOption(2);
                }}
              >
                {t("earnScheme")}
              </Button>
              <Button
                variant="primary"
                size="sm"
                className={`${styles.productBtn} me-2 ${
                  showOption === 1 && "btn-product"
                } d-none`}
              >
                Reports
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className={`${styles.productSectionContent} p-2`}>
              {showOption === 1 ? (
                <UploadBatch />
              ) : (
                showOption === 2 && <EarnScheme />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
