import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styles from "./ForgetPass.module.scss";
import { useTranslation } from "react-i18next";

export default function ForgetPass() {
  const { t } = useTranslation();

  return (
    <div className={`${styles.forgetLogin} py-5 px-2 p-md-5`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <h4 className={`${styles.forgetTitle}`}>Forget Password</h4>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`${styles.forgetFormContent} p-4 p-md-5`}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className={`${styles.forgetLabel}`}>
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    className={`${styles.forgetInput}`}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className={`${styles.passLabel}`}>
                    Old Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Old Password"
                    className={`${styles.passInput}`}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className={`${styles.passLabel}`}>
                    New Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    className={`${styles.passInput}`}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className={`${styles.passLabel}`}>
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    className={`${styles.passInput}`}
                  />
                </Form.Group>
                <div className="d-grid mt-4">
                  <Button
                    variant="primary"
                    size="lg"
                    className={`${styles.forgetBtn}`}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
