import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styles from "./CustomerRefcode.module.scss";

export default function CustomerRefcode() {
  return (
    <div className={`${styles.refCodeContent} py-5`}>
      <div className={`${styles.comingSoon}`}>
        <h5>قريباً...</h5>
      </div>
      <Container>
        <Row>
          <Col xs={12}>
            <Form>
              <Form.Group
                as={Row}
                className={`${styles.cashoutInputContent} mb-3 p-3`}
              >
                <Col xs={12} md={6}>
                  <Form.Label className="d-block">Exchange</Form.Label>
                  <Form.Text className="d-block">
                    * The minimum withdrawal is 100 EGP.
                  </Form.Text>
                  <Form.Text className="d-block">
                    * Terms and conditions are applied.
                  </Form.Text>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    type="number"
                    className={`${styles.cashoutInput}`}
                    size="sm"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className={`${styles.cashoutInputContent} mb-3 p-3`}
              >
                <Col xs={12}>
                  <Form.Text className="d-block">
                    *This service is provided by OPay POS Machiens.
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className={`${styles.cashoutInputContent} mb-3 p-3`}
              >
                <Col xs={12} md={6}>
                  <Form.Label className="d-block">Confirm Password</Form.Label>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    type="password"
                    className={`${styles.cashoutInput}`}
                    size="sm"
                  />
                </Col>
              </Form.Group>
              <div className="d-grid">
                <Button
                  variant="primary"
                  size="md"
                  className={`${styles.saveBtn}`}
                >
                  Get Cash-out
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
