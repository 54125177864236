import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CustomerPagination from "../../../pagination/customerPagination/CustomerPagination";
import styles from "./BrandVoucher.module.scss";

export default function BrandVoucher(props) {
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [showOption, setShowOption] = useState(1);
  const [vouchers, setVouchers] = useState([]);

  let brandVouchers = props.brandvouchers;

  const postLastIndex = page * postsPerPage;
  const postFirstIndex = postLastIndex - postsPerPage;
  // const currentPosts = vouchers.slice(postFirstIndex, postLastIndex);

  return (
    <div className={`${styles.giftCardsParent} p-4`}>
      <h3 className={`${styles.teamTitle} mb-3`}>كروت الهدايا</h3>
      <div>
        <div
          className={`${styles.fuelTypeContent} mb-4 d-flex justify-content-around`}
        >
          <Button
            variant="danger"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 1 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(1);
            }}
          >
            صالح
          </Button>
          <Button
            variant="danger"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 2 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(2);
            }}
          >
            مستخدم
          </Button>
        </div>
      </div>
      <div className="mb-4 d-flex flex-wrap justify-content-center align-items-center">
        {showOption === 1
          ? brandVouchers
              .filter((vouch) => vouch["vendorTrxStatus"] === "pending")
              .slice(postFirstIndex, postLastIndex)
              .map((reward, index) => {
                return (
                  <div className={`${styles.giftCard} m-2 p-3`} key={index}>
                    <div className={`${styles.giftCardInfo} w-100`}>
                      <div className={`${styles.giftCardImg} me-2`}>
                        <img src={reward["ImageUrl"]} />
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>كروت الهدايا</p>
                        <p>يصرف لدى</p>
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>{reward["voucher_value"]} جنيه</p>
                        <p>{reward["voucher_name"]}</p>
                      </div>
                    </div>
                    <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                      <div className={`${styles.notSettledStatus} px-4 py-1`}>
                        سيتم ارسال كود الهدية الشهر القادم
                      </div>
                    </div>
                  </div>
                );
              })
          : brandVouchers
              .filter((vouch) => vouch["vendorTrxStatus"] === "successful")
              .slice(postFirstIndex, postLastIndex)
              .map((reward, index) => {
                return (
                  <div className={`${styles.giftCard} m-2 p-3`} key={index}>
                    <div className={`${styles.giftCardInfo} w-100`}>
                      <div className={`${styles.giftCardImg} me-2`}>
                        <img src={reward["ImageUrl"]} />
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>كروت الهدايا</p>
                        <p>يصرف لدى</p>
                      </div>
                      <div className={`${styles.giftCardDetails}`}>
                        <p>{reward["voucher_value"]} جنيه</p>
                        <p>{reward["voucher_name"]}</p>
                      </div>
                    </div>
                    <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                      <Button
                        variant="danger"
                        size="sm"
                        className={`${styles.getCodeBtn} mx-1`}
                        disabled={reward["settled"] === "false" ? true : false}
                      >
                        ابعت كود الهدية
                      </Button>
                    </div>
                  </div>
                );
              })}
      </div>
      {showOption === 1 ? (
        <CustomerPagination
          totalPosts={
            brandVouchers.filter(
              (vouch) => vouch["vendorTrxStatus"] === "pending"
            ).length
          }
          postsPerPage={postsPerPage}
          onChangePage={(value) => {
            setPage(value);
          }}
          currentPage={page}
        />
      ) : (
        <CustomerPagination
          totalPosts={
            brandVouchers.filter(
              (vouch) => vouch["vendorTrxStatus"] === "successful"
            ).length
          }
          postsPerPage={postsPerPage}
          onChangePage={(value) => {
            setPage(value);
          }}
          currentPage={page}
        />
      )}
    </div>
  );
}
