import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Formik } from "formik";
import i18next from "i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Zoom } from "react-toastify";
import axios from "axios";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import Pagination from "../../../pagination/Pagination";
import { handleLogout } from "../../../../redux/features/userSlice";
import styles from "./EditStation.module.scss";
import { updateStationDetails } from "../../../../redux/features/adminSlice";

export default function EditStation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = useSelector((state) => state.cpc.user.login.token);
  const { stationID } = useParams();

  const [savedValues, setSavedValues] = useState({});
  const [AreaId, setAreaId] = useState("");
  const [GovId, setGovId] = useState("");
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/stations", {
        params: {
          governorateID: "",
          cityID: "",
          AreaID: null,
          officeID: null,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const targetStation = res.data.data.filter(
            (sat) => sat["station_id"] == stationID
          );
          // setDetails(targetStation[0]);
          setSavedValues({
            stationName: targetStation[0]["station_name"],
            areaNameAr:
              targetStation[0]["stationOffice"]["officeArea"]["area_name_ar"],
            areaNameEn:
              targetStation[0]["stationOffice"]["officeArea"]["area_name_en"],
            areaID: targetStation[0]["stationOffice"]["officeArea"]["id"],
            officeID: targetStation[0]["stationOffice"]["id"],
            cityNameAr: targetStation[0]["city"]["city_name_ar"],
            cityNameEn: targetStation[0]["city"]["city_name_en"],
            govNameAr: targetStation[0]["governorate"]["governorate_name_ar"],
            govNameEn: targetStation[0]["governorate"]["governorate_name_en"],
            email:
              targetStation[0]["stationOffice"]["reportUser"] !== null
                ? targetStation[0]["stationOffice"]["reportUser"]["email"]
                : "",
            phone:
              targetStation[0]["stationOffice"]["reportUser"] !== null
                ? targetStation[0]["stationOffice"]["reportUser"]["phone"]
                : "",
            stationCode:
              targetStation[0]["stationOffice"]["reportUser"] !== null
                ? targetStation[0]["stationOffice"]["reportUser"]["NID"]
                : "",
            stationAddress: targetStation[0]["address_line"],
          });
          setAreaId(targetStation[0]["stationOffice"]["officeArea"]["id"]);
          setGovId(targetStation[0]["governorate"]["id"]);
        }
        if (res.data.statusCode === 404) {
          navigate("/admin/dashboard/stations");
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const initialValues = {
    stationName: "",
    areaNameAr: savedValues.areaNameAr,
    areaNameEn: savedValues.areaNameEn,
    areaID: "",
    officeID: "",
    cityNameAr: savedValues.cityNameAr,
    cityNameEn: savedValues.cityNameEn,
    govNameAr: savedValues.govNameAr,
    govNameEn: savedValues.govNameEn,
    email: "",
    phone: "",
    stationCode: "",
    stationAddress: "",
  };

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/getOffices", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const filterOffices = res.data.data.filter(
            (off) => off["area_id"] == AreaId && off["gov_id"] == GovId
          );
          setOffices(filterOffices);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [AreaId, GovId]);

  return (
    <div className={`${styles.editStationContent} p-1`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {t("editStationDetails")}
            </h3>
          </Col>
        </Row>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            const loadReq = toast.loading(t("loading"), {
              transition: Zoom,
              position:
                i18next.language == "ar" ? "bottom-right" : "bottom-left",
            });
            setTimeout(() => {
              actions.setSubmitting(false);
            }, 3000);
            dispatch(
              updateStationDetails({
                token,
                stationID,
                stationName: values.stationName,
                stationAddress: values.stationAddress,
                stationCode: values.stationCode,
                phone: values.phone,
                email: values.email,
                officeID: values.officeID,
              })
            ).then((res) => {
              if (res.payload.data.statusCode === 200) {
                navigate("/admin/dashboard/stations");
                successMessage(
                  res.payload.data.message,
                  res.payload.data.messageAr,
                  loadReq
                );
              } else if (res.payload.data.statusCode === 401) {
                dispatch(handleLogout());
                navigate("/cpc/login");
                failedMessage(
                  res.payload.data.error,
                  res.payload.data.errorAr,
                  loadReq
                );
              } else {
                failedMessage(
                  res.payload.data.error,
                  res.payload.data.errorAr,
                  loadReq
                );
              }
            });
          }}
          enableReinitialize
        >
          {({
            values,
            erros,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mt-4">
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("areaName")}
                      </h6>
                      <Form.Control
                        type="text"
                        className={`${styles.editInput}`}
                        name={
                          i18next.language == "ar" ? "areaNameAr" : "areaNameEn"
                        }
                        id={
                          i18next.language == "ar" ? "areaNameAr" : "areaNameEn"
                        }
                        defaultValue={
                          i18next.language == "ar"
                            ? values.areaNameAr
                            : values.areaNameEn
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("officeName")}
                      </h6>
                      <Form.Select
                        className={`${styles.editInput}`}
                        id="officeID"
                        name="officeID"
                        onChange={(e) => {
                          setFieldValue("officeID", e.currentTarget.value);
                        }}
                        value={values.officeID}
                      >
                        <option value="">{t("ChooseOffice")}</option>
                        {offices.map((office, index) => {
                          return (
                            <option value={office["id"]} key={index}>
                              {office["office_name_ar"]}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("goverName")}
                      </h6>
                      <Form.Control
                        type="text"
                        className={`${styles.editInput}`}
                        name={
                          i18next.language == "ar" ? "govNameAr" : "govNameEn"
                        }
                        id={
                          i18next.language == "ar" ? "govNameAr" : "govNameEn"
                        }
                        defaultValue={
                          i18next.language == "ar"
                            ? values.govNameAr
                            : values.govNameEn
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("cityName")}
                      </h6>
                      <Form.Control
                        type="text"
                        className={`${styles.editInput}`}
                        name={
                          i18next.language == "ar" ? "cityNameAr" : "cityNameEn"
                        }
                        id={
                          i18next.language == "ar" ? "cityNameAr" : "cityNameEn"
                        }
                        defaultValue={
                          i18next.language == "ar"
                            ? values.cityNameAr
                            : values.cityNameEn
                        }
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("stationEmail")}
                      </h6>
                      <Form.Control
                        type="email"
                        className={`${styles.editInput}`}
                        name="email"
                        id="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("stationPhoneNumber")}
                      </h6>
                      <Form.Control
                        type="text"
                        maxLength={11}
                        className={`${styles.editInput}`}
                        name="phone"
                        id="phone"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.phone}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("stationCode")}
                      </h6>
                      <Form.Control
                        type="text"
                        className={`${styles.editInput} px-0`}
                        name="stationCode"
                        id="stationCode"
                        onChange={handleChange}
                        value={values.stationCode}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} className="mb-3 p-2">
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <h6 className={`${styles.detailsTitle}`}>
                        {t("stationAddress")}
                      </h6>
                      <Form.Control
                        type="text"
                        className={`${styles.editInput}`}
                        name="stationAddress"
                        id="stationAddress"
                        onChange={handleChange}
                        value={values.stationAddress}
                      />
                    </div>
                  </Col>
                </Form.Group>
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="success"
                    size="md"
                    className={`${styles.saveBtn} mx-2`}
                    disabled={isSubmitting}
                  >
                    {t("saveChanges")}
                  </Button>
                  <Button
                    type="reset"
                    variant="danger"
                    size="md"
                    className={`${styles.cancelBtn} mx-2`}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
}
