import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import failedMessage from "../../../messages/Failed";
import successMessage from "../../../messages/Successful";
import { Fragment } from "react";
import { RiCoupon2Fill } from "react-icons/ri";
import Qrscan from "../../trader/home/qrscan/Qrscan";
import { BsQrCodeScan } from "react-icons/bs";
import { handleLogout } from "../../../redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import { BsGift } from "react-icons/bs";
import Cookies from "js-cookie";
import styles from "./CustomerHome.module.scss";
import axios from "axios";
import { earnPointsAfterScanQr } from "../../../redux/features/ownerSlice";
import CustomerQrscan from "./qrscan/CustomerQrscan";
import CustomerPoint from "./point/CustomerPoint";
import CouponModal from "./couponModal/CouponModal";

export default function CustomerHome() {
  const [showQrModal, setShowQrModal] = useState(false);
  const [customerBalance, setCustomerBalance] = useState({
    points: "",
    egp: "",
  });

  const token = useSelector((state) => state.cpc.user.login.token);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lang, setLang] = useState("en");
  const [showGift, setShowGift] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [points, setPoints] = useState("");

  window.onbeforeunload = (event) => {
    Cookies.remove("type");
  };

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/customer/pointsBalance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCustomerBalance({
            points: res.data.pointsBalance,
            egp: res.data.pointsBalanceEGP,
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  }, []);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const voucherCodeValidaiton = yup.object().shape({
    voucherCode: yup.number().required("voucherCodeValidation"),
  });

  return (
    <div className={`${styles.customerHomeContent}`}>
      <Container>
        <Row>
          <Col xs={12} md={5}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              {t("userDash")}
            </h3>
          </Col>
          <Col xs={12} md={7}>
            <div className={`${styles.btnRight}`}>
              <Container>
                <Row>
                  <Col xs={12} sm={6}>
                    <div className="d-grid">
                      <Button
                        variant="danger"
                        onClick={() => {
                          setShowCouponModal(true);
                        }}
                        className="m-1"
                      >
                        <RiCoupon2Fill className="me-2" />
                        صرف قسائم الشراء
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className="d-grid">
                      <Button
                        variant="danger"
                        onClick={() => {
                          navigate("/customer/dashboard/home/redeem");
                        }}
                        className="m-1"
                      >
                        <BsGift className="me-2" />
                        {t("redeemPoints")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>

          <Col xs={12} md={{ span: 8, offset: 2 }} className="mt-4">
            <div className={`${styles.balanceTableContent}`}>
              <Table responsive borderless className="mb-0">
                <thead>
                  <tr>
                    <th className="text-center">{t("points")}</th>
                    <th>{customerBalance.points}</th>
                  </tr>
                </thead>
              </Table>
            </div>
          </Col>
          <Col xs={12}>
            <h5 className="mt-5 mb-3">{t("earnPointsTitle")}</h5>
          </Col>
          <Col xs={12} md={{ span: 8, offset: 2 }} className="mb-4">
            <div
              className={`${styles.scanQrContent} p-3 p-md-5 w-100 d-flex flex-column justify-content-center align-items-center`}
            >
              <h6 className={`${styles.scanTitle} mb-4`}>{t("scanQrCode")}</h6>
              <Button
                variant="light"
                className={`${styles.scanBtn}`}
                onClick={() => {
                  setShowQrModal(true);
                }}
              >
                <BsQrCodeScan />
              </Button>
            </div>
          </Col>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <div
              className={`${styles.scanQrContent} p-3 p-md-5 w-100 d-flex flex-column justify-content-center align-items-center`}
            >
              <h6 className={`${styles.scanTitle} mb-4`}>
                {t("codeManually")}
              </h6>
              <Formik
                initialValues={{
                  voucherCode: "",
                }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    earnPointsAfterScanQr({
                      qrCode: values.voucherCode,
                      token,
                    })
                  ).then((res) => {
                    const loadReq = toast.loading(t("loading"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    if (res.payload.data.statusCode === 200) {
                      setPoints(res.payload.data.addedPoints);
                      setShowGift(true);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else if (res.payload.data.statusCode === 401) {
                      dispatch(handleLogout());
                      navigate("/cpc/customer/login");
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={voucherCodeValidaiton}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => checkKeyDown(e)}
                    >
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          className={`${styles.verifyInput}`}
                          onChange={handleChange}
                          name="voucherCode"
                          id="voucherCode"
                          value={values.voucherCode}
                        />
                        {errors.voucherCode && touched.voucherCode && (
                          <Form.Text className="text-error">
                            {t(errors.voucherCode)}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="danger"
                          type="submit"
                          className={`${styles.verifyBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("checkCodeBtn")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
      <CustomerQrscan
        show={showQrModal}
        onHide={() => {
          setShowQrModal(false);
        }}
      />
      <CustomerPoint
        show={showGift}
        onHide={() => {
          setShowGift(false);
        }}
        points={points}
      />
      <CouponModal
        show={showCouponModal}
        onHide={() => {
          setShowCouponModal(false);
        }}
      />
    </div>
  );
}
