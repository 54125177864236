import React from "react";
import styles from "./CustomerSoon.module.scss";

export default function CustomerSoon() {
  return (
    <div className={`${styles.CustomerSoonContent}`}>
      <img
        src={require("../../../../imgs/customer img.jpeg")}
        className={`${styles.ownerImg}`}
      />
      <img
        src={require("../../../../imgs/soon logo.jpeg")}
        className={`${styles.soonLogo}`}
      />
    </div>
  );
}
