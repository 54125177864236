import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Formik } from "formik";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleLogout } from "../../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./StationsDetails.module.scss";

export default function StationsDetails() {
  const [governs, setGoverns] = useState([]);
  const [cities, setCities] = useState([]);
  const [stations, setStations] = useState([]);
  const [stationGov, setStationGov] = useState("");
  const [activeCity, setActiveCity] = useState(false);
  const [activeCityField, setAcitveCityField] = useState(false);
  const [activeStationInput, setActiveStationInput] = useState(false);

  const [govvID, setGovvID] = useState("");

  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  }, []);

  const handleCities = (govID) => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govID,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  };


  // const handleStations = (govID, cityID, token) => {
  //   axios
  //     .get("https://evouchers.shareecoin.com/CPC/fuelStation/stations", {
  //       params: {
  //         governorateID: govID,
  //         cityID: cityID,
  //         sort: "asc",
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.statusCode === 200) {
  //         setStations(res.data.data);
  //       } else {
  //         setStations([]);
  //         setMessage(`لا توجد محطات تابعة للبرنامج بهذا الموقع`);
  //       }
  //     });
  // };
  const { t } = useTranslation();

  const handleListStationsPerGovern = (governID) => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/operators", {
        params: {
          sortBy: "date",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          let stationsOfGovers = res.data.data.filter(
            (go) => go["governorateID"] == governID
          );
          setStations(stationsOfGovers);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  };

  useEffect(() => {
    handleListStationsPerGovern(govvID);
  }, [govvID]);

  return (
    <div className={`${styles.StationsDetailsContent}`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/customer/dashboard/home");
                }}
              />
              المحطات التابعة للبرنامج
            </h3>
          </Col>
          <Col xs={12} className="mb-5">
            <Form>
              <Row>
                <Col xs={12} sm={6} className="mb-2">
                  <Form.Select
                    className={`${styles.cashoutSelect}`}
                    name="stationGov"
                    id="stationGov"
                    onChange={(e) => {
                      // setFinalResult("");
                      // setFieldValue("stationGov", e.currentTarget.value);
                      setMessage("");
                      setStationGov(e.currentTarget.value);
                      handleCities(e.currentTarget.value);
                      setGovvID(e.currentTarget.value);
                      setActiveCity(true);
                    }}
                  >
                    <option value="">{t("selectGover")}</option>
                    {governs.map((gov, index) => {
                      return (
                        <option key={gov.GovernateID} value={gov.GovernateID}>
                          {i18next.language == "ar"
                            ? gov["governorate_name_ar"]
                            : gov["governorate_name_en"]}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3`}>المحطات</h3>
            <div className={`${styles.stationsDetailsContent}`}>
              <ul>
                {stations.length > 0 ? (
                  stations.map((station, index) => {
                    return (
                      <li className="mb-2">{`${
                        station["station_name"]
                      } - - ${cities
                        .filter((ci) => ci["cityID"] == station["cityID"])
                        .map((cit) => {
                          return cit["city_name_Ar"];
                        })} - ${station["address_line"]} `}</li>
                    );
                  })
                ) : (
                  <p className="text-center">{message}</p>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
