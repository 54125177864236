import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { FaQrcode } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  checkMerchantBranches,
  generateQR,
} from "../../../redux/features/activatorSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../redux/features/userSlice";
import styles from "./Qrcode.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function Qrcodes() {
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);

  const postLastIndex = page * postsPerPage;
  const postFirstIndex = postLastIndex - postsPerPage;
  //   const currentPosts = shops.slice(postFirstIndex, postLastIndex);

  const token = useSelector((state) => state.cpc.user.login.token);

  const [traders, setTraders] = useState([]);
  const navigate = useNavigate();

  const [qrCode, setQrCode] = useState("");
  const [showQr, setShowQr] = useState(false);
  const [ownerID, setOwnerID] = useState("");

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listBusinesses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setTraders(res.data.businesses);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className={`${styles.qrcodesContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.shopTitle}`}>{t("activatedMerchants")}</h3>
          </Col>
          {traders.map((trader) => {
            return (
              <Col xs={12} md={6} key={trader["OwnerID"]}>
                <div className={`${styles.bgGrey} w-100 mb-3`}>
                  <Container>
                    <Row>
                      <Col xs={12} lg={9}>
                        <div className={`${styles.infoContent} p-3`}>
                          <div className="w-100">
                            <h5 className="d-inline me-3">{`${trader["businessName"]}`}</h5>
                          </div>
                          <div className="w-100 d-flex justify-content-left align-items-center mt-2">
                            <div className={`${styles.activatorTitle}`}>
                              <h4>{`${trader["email"]}`}</h4>
                              <h6>{`${trader["phone"]}`}</h6>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} lg={3} className="px-0">
                        <Button
                          variant="primary"
                          className={`${styles.editBtn}`}
                          // onClick={() => {
                          //   dispatch(
                          //     checkMerchantBranches({
                          //       merchantID: trader["OwnerID"],
                          //     })
                          //   ).then((res) => {
                          //     const loadReq = toast.loading(t("loading"), {
                          //       transition: Zoom,
                          //       position:
                          //         i18next.language == "ar"
                          //           ? "bottom-right"
                          //           : "bottom-left",
                          //     });
                          //     if (
                          //       res.payload.data.ownersAndTraders.length > 0
                          //     ) {
                          //       dispatch(
                          //         generateQR({
                          //           merchantID: trader["OwnerID"],
                          //           token,
                          //         })
                          //       ).then((res) => {
                          //         if (res.payload.data.statusCode === 200) {
                          //           successMessage(
                          //             res.payload.data.message,
                          //             res.payload.data.messageAr,
                          //             loadReq
                          //           );
                          //           setShowQr(true);
                          //           setQrCode(res.payload.data.qrCodeBase64);
                          //         }
                          //       });
                          //     } else {
                          //       failedMessage(
                          //         "Firstly, Create branch for this merchant",
                          //         "اولاً, برجاء انشاء فرع لهذا المتجر",
                          //         loadReq
                          //       );
                          //       navigate(
                          //         `/activator/dashboard/${trader["OwnerID"]}/addshop`
                          //       );
                          //     }
                          //   });
                          // }}
                          onClick={() => {
                            const loadReq = toast.loading(t("loading"), {
                              transition: Zoom,
                              position:
                                i18next.language == "ar"
                                  ? "bottom-right"
                                  : "bottom-left",
                            });
                            dispatch(
                              generateQR({
                                merchantID: trader["OwnerID"],
                                token,
                              })
                            ).then((res) => {
                              if (res.payload.data.statusCode === 200) {
                                successMessage(
                                  res.payload.data.message,
                                  res.payload.data.messageAr,
                                  loadReq
                                );
                                setShowQr(true);
                                setQrCode(res.payload.data.qrCodeBase64);
                              } else if (res.payload.data.statusCode === 401) {
                                dispatch(handleLogout());
                                navigate("/cpc/login");
                                failedMessage(
                                  res.payload.data.error,
                                  res.payload.data.errorAr,
                                  loadReq
                                );
                              } else {
                                failedMessage(
                                  res.payload.data.error,
                                  res.payload.data.errorAr,
                                  loadReq
                                );
                              }
                            });
                          }}
                        >
                          <FaQrcode />
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            );
          })}
        </Row>
        <Modal size="md" show={showQr} onHide={() => setShowQr(false)} centered>
          <Modal.Body className={`${styles.qrModal} text-center p-4`}>
            <h5 className="text-center">{t("activationQrcode")}</h5>
            <img src={`data:image/png;base64,${qrCode}`} />
            <Button
              variant="secondary"
              size="sm"
              className={`${styles.closeBtn} mt-3`}
              onClick={() => {
                setShowQr(false);
              }}
            >
              {t("close")}
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}
