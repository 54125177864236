import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../redux/features/userSlice";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BrandVoucher from "./brandVouchers/BrandVoucher";
import FuelVouchers from "./fuelVouchers/FuelVouchers";
import CashRewards from "./cashRewards/CashRewards";
import styles from "./OwnerGifts.module.scss";

export default function OwnerGifts() {
  const [count, setCount] = useState(20);

  const [vouchers, setVouchers] = useState([]);
  const [cashRewards, setCashRewards] = useState([]);
  const [totalCashRewards, setTotalCashRewards] = useState("");
  const [brandVouchers, setBrandVouchers] = useState([]);
  const [totalBrandVouchers, setTotalBrandVouchers] = useState("");
  const [fuelVouchers, setFuelVouchers] = useState([]);
  const [totalFuelVouchers, setTotalFuelVouhcers] = useState("");
  const [message, setMessage] = useState({
    error: "",
    errorAr: "",
  });

  const token = useSelector((state) => state.cpc.user.login.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(
        "https://evouchers.shareecoin.com/CPC/redeem/listOrderedVouchers",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          let finalCashRewards = res.data.cashRewards.sort((a, b) =>
            b["updatedAt"].localeCompare(a["updatedAt"])
          );
          setCashRewards(finalCashRewards);
          setTotalCashRewards(res.data.cashRewards.length);

          let finalBrandVouchers = res.data.voucherRewards[
            "brandsVouchers"
          ].sort((a, b) => b["createdAt"].localeCompare(a["createdAt"]));
          setBrandVouchers(finalBrandVouchers);
          setTotalBrandVouchers(
            res.data.voucherRewards["brandsVouchers"].length
          );

          let finalFuelVouchers = res.data.voucherRewards["fuelVouchers"].sort(
            (a, b) => b["createdAt"].localeCompare(a["createdAt"])
          );
          setFuelVouchers(finalFuelVouchers);
          setTotalFuelVouhcers(res.data.voucherRewards["fuelVouchers"].length);
        } else if (res.data.statusCode === 200 && res.data.message != "") {
          setMessage({
            error: res.data.message,
            errorAr: res.data.messageAr,
          });
        } else if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  }, []);

  const { t } = useTranslation();


  const [showOption, setShowOption] = useState(1);

  return (
    <div className={`${styles.OwnerGiftsContent} p-2`}>
      <Container fluid>
        <Row className="mb-3">
          <Col xs={12}>
            <Container>
              <Row>
                <Col xs={12} sm={4}>
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.redeemPointsBtn} mb-2 me-2 ${
                      showOption === 1 && styles.redeemPointActiveBtn
                    }`}
                    onClick={() => {
                      setShowOption(1);
                    }}
                  >
                    كروت هدايا
                  </Button>
                </Col>

                <Col xs={12} sm={4}>
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.redeemPointsBtn} mb-2 me-2 ${
                      showOption === 3 && styles.redeemPointActiveBtn
                    }`}
                    onClick={() => {
                      setShowOption(3);
                    }}
                  >
                    سحب نقدي
                  </Button>
                </Col>
                <Col xs={12} sm={4}>
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.redeemPointsBtn} mb-2 me-2 ${
                      showOption === 2 && styles.redeemPointActiveBtn
                    }`}
                    onClick={() => {
                      setShowOption(2);
                    }}
                  >
                    كروت وقود
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
          {showOption === 3 ? (
            <Col xs={12} className="mt-4">
              <CashRewards cashrewards={cashRewards} total={totalCashRewards} />
            </Col>
          ) : showOption === 1 ? (
            <Col xs={12} className="mt-4">
              <BrandVoucher
                brandvouchers={brandVouchers}
                total={totalBrandVouchers}
              />
            </Col>
          ) : (
            showOption === 2 && (
              <Col xs={12} className="mt-4">
                <FuelVouchers
                  fuelvouchers={fuelVouchers}
                  total={totalFuelVouchers}
                />
              </Col>
            )
          )}
        </Row>
      </Container>
    </div>
  );
}
