import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import styles from "./Cashout.module.scss";

export default function Cashout(props) {
  const [value, setValue] = useState("");

  useEffect(() => {
    props.cashValue.map((val) => {
      setValue(val["reward_value"]);
    });
  }, []);

  return (
    <div className={`${styles.createCashout} py-4`}>
      <Container>
        <Row>
          <Col xs={12}>
            <Formik
              initialValues={{ cashoutValue: "", sDate: "", eDate: "" }}
              onSubmit={(values) => {
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      as={Row}
                      className={`${styles.formGroupCashout} mb-3 px-2 py-4`}
                    >
                      <Col xs={1}>
                        <Form.Label className={`${styles.formLabel}`}>
                          Value
                        </Form.Label>
                      </Col>
                      <Col xs={4}>
                        <InputGroup
                          size="sm"
                          className={`${styles.inputGroupForm}`}
                        >
                          <Form.Control
                            type="text"
                            className={`${styles.cashInput}`}
                            name="cashoutValue"
                            id="cashoutValue"
                            onChange={handleChange}
                            disabled={true}
                            value={value}
                          />
                          <InputGroup.Text>EGP</InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <Form.Text className={`${styles.formGroupCashoutNote}`}>
                          This amount will be transferred to the merchant's
                          e-wallet after deduction of required fees.
                        </Form.Text>
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col xs={12} className="text-center">
                        <Button
                          variant="primary"
                          className={`${styles.saveBtn}`}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
