import React from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { scanNationalID } from "../../../../redux/features/ownerSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import failedMessage from "../../../../messages/Failed";
import styles from "./CameraModal.module.scss";
import successMessage from "../../../../messages/Successful";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function CameraModal(props) {
  const { merchantID } = useParams();

  const { t } = useTranslation();

  function dataURLtoBlob(dataUri) {
    var arr = dataUri.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function handleTakePhoto(dataUri) {
    props.handleimagefile(dataUri);
    const newBlob = dataURLtoBlob(dataUri);
    const myFile = new File([newBlob], "MyID.jpeg", {
      type: newBlob.type,
    });
   
    const loadReq = toast.loading(t("uploadingNationalIdImage"), {
      transition: Zoom,
      position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
    });
    dispatch(
      scanNationalID({
        scanNationalIDFile: myFile,
        ownerID: merchantID,
      })
    ).then((res) => {
      if (res.payload.data.statusCode === 200) {
        successMessage(
          res.payload.data.message,
          res.payload.data.messageAr,
          loadReq
        );
        props.handlenational(res.payload.data.OwnerNID, true, true);
      } else {
        failedMessage(
          res.payload.data.error,
          res.payload.data.errorAr,
          loadReq
        );
      }
    });
    props.onHide();
  }

  const dispatch = useDispatch();

  return (
    <div className={`${styles.cameraContent}`}>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
        fullscreen={true}
      >
        <Modal.Body className={`${styles.cameraContentModal} p-0 m-0`}>
          <Container>
            <Row>
              <Col xs={12}>
                <Camera
                  onTakePhoto={(dataUri) => {
                    handleTakePhoto(dataUri);
                  }}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  imageType={IMAGE_TYPES.JPEG}
                  idealResolution={{ width: 720, height: 1280 }}
                  imageCompression={0.97}
                  isImageMirror={false}
                  isSilentMode={false}
                  isDisplayStartCameraError={true}
                  isFullscreen={false}
                  sizeFactor={1}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
