import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Badge,
  Alert,
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { handleLogout } from "../../../../../redux/features/userSlice";
import moment from "moment/moment";
import { IoMdArrowRoundBack } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./ProductReport.module.scss";
import { useTranslation } from "react-i18next";

export default function ProductReport() {
  const girdRef = useRef();
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState("");
  const [customerGoverns, setCustomerGoverns] = useState([]);
  const [ownerGoverns, setOwnerGoverns] = useState([]);

  const token = useSelector((state) => state.cpc.user.login.token);

  const [showGovern, setShowGovern] = useState(false);

  const handleClose = () => {
    setShowGovern(false);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Product Name",
      field: "productName",
    },
    {
      headerName: "Total Scanned Owner Codes",
      field: "scannedOwnerCodes.totalCodes",
    },
    {
      headerName: "Total Owner Rewards Value EGP",
      field: "totalOwnerRewardsValue",
      cellRenderer: (result) => {
        return <div>{`${result.data.totalOwnerRewardsValue} EGP`}</div>;
      },
    },
    {
      headerName: "Total Scanned Customer Codes",
      field: "scannedCustomerCodes.totalCodes",
    },
    {
      headerName: "Total Customer Rewards Value EGP",
      field: "totalCustomerRewardsValue",
      cellRenderer: (result) => {
        return <div>{`${result.data.totalCustomerRewardsValue} EGP`}</div>;
      },
    },
    {
      headerName: "Governorates",
      field: "governorates",
      cellRenderer: (result) => {
        return (
          <div>
            <Button
              variant="primary"
              size="sm"
              className={`${styles.showBtn}`}
              onClick={() => {
                setShowGovern(true);
                setCustomerGoverns(
                  result.data.scannedCustomerCodes.governorates
                );
                setOwnerGoverns(result.data.scannedOwnerCodes.governorates);
              }}
            >
              <HiOutlineLocationMarker />
            </Button>
          </div>
        );
      },
    },
  ]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .post(
        "https://evouchers.shareecoin.com/CPC/getReports",
        {
          reportType: "points report",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          params.api.applyTransaction({ add: res.data.pointsList });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  };

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 300,
  };

  const navigate = useNavigate();

  const handleExport = () => {
    gridApi.exportDataAsCsv();
  };

  const { t } = useTranslation();

  const handleDownloadReport = () => {
    window.location.href =
      "https://evouchers.shareecoin.com/CPC/reports/earnedPoints";
  };

  return (
    <div className={`${styles.ProductReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/products");
                }}
              />
              {t("batchReport")}
            </h3>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3 text-center">
            <Button
              variant="primary"
              className={`${styles.downloadBtn}`}
              onClick={() => {
                handleDownloadReport();
              }}
            >
              {t("downloadReport")}
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={showGovern} onHide={handleClose} centered>
        <Modal.Body className={`${styles.governModal}`}>
          <div className={`${styles.governContent} p-3 mb-3`}>
            <h6 className="mb-3">Scanned Customer Codes</h6>
            {customerGoverns.length > 0 ? (
              customerGoverns.map((customer, index) => {
                return (
                  <div className="p-1 me-2" key={index}>
                    <Badge>{customer["governorates_name_en"]}</Badge>
                    <span className={`${styles.countGoverns} mx-1`}>
                      {customer["count"]}
                    </span>
                  </div>
                );
              })
            ) : (
              <div className={`${styles.noData}`}>No Scanned Codes</div>
            )}
          </div>
          <div className={`${styles.governContent} p-3`}>
            <h6 className="mb-3">Scanned Owner Codes</h6>
            {ownerGoverns.length > 0 ? (
              ownerGoverns.map((owner, index) => {
                return (
                  <div className="p-1 me-2" key={index}>
                    <Badge>{owner["governorates_name_en"]}</Badge>
                    <span className={`${styles.countGoverns} mx-1`}>
                      {owner["count"]}
                    </span>
                  </div>
                );
              })
            ) : (
              <div className={`${styles.noData}`}>No Scanned Codes</div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
