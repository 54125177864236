import React from "react";
import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./CouponModal.module.scss";

export default function CouponModal(props) {
  const navigate = useNavigate();

  return (
    <div className={`${styles.CouponModalContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.CouponModalBody}`}>
          <Container>
            <Row>
              <Col xs={12} className="mb-2">
                تصرف هذة القسائم داخل محطات سي بي سي التابعة للبرنامج، تعرف
                عليها من
                <NavLink to="/customer/dashboard/home/redeem/vouchers">{` هنا`}</NavLink>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <div className="d-grid">
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      navigate("/customer/dashboard/home/redeem");
                      Cookies.set("type", "redeemFuelVouchers");
                    }}
                  >
                    وقود
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <div className="d-grid">
                  <Button variant="danger" size="lg" disabled={true}>
                    منتجات
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
