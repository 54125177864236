import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./CustomerMessage.module.scss";

export default function CustomerMessage(props) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.customerMessageContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.giftPointBody}`}>
          <h4>{t("congrats")}</h4>
          <p className="my-3">{props.message}</p>
          <div className="d-grid">
            <Button
              size="sm"
              variant="priamry"
              className={`${styles.okBtn}`}
              onClick={() => {
                props.onHide();
              }}
            >
              {t("ok")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
