import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import randomstring from "randomstring";
import axios from "axios";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.rtl.css";
import { useNavigate } from "react-router-dom";
import failedMessage from "../../messages/Failed";
import successMessage from "../../messages/Successful";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { userRegister } from "../../redux/features/userSlice";
import styles from "./RegForm.module.scss";
import MessageBox from "../../messages/MessageBox";
import Cookies from "js-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function RegForm() {
  const [govid, setGovid] = useState("");
  const [govers, setGovers] = useState([]);
  const [cities, setCities] = useState([]);
  const [activeCityField, setAcitveCityField] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGovers(res.data["Governates List"]);
      });
  }, []);
  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govid,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [govid]);

  useEffect(() => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  }, []);

  const reisterValidation = yup.object().shape({
    firstName: yup.string().required("الاسم الاول مطلوب"),
    lastName: yup.string().required("الاسم الثاني مطلوب"),
    businessName: yup.string().required("اسم المتجر مطلوب"),
    phoneNumber: yup.string().required("رقم الجوال مطلوب"),
    email: yup.string().email().required("البريد الاليكتروني مطلوب"),
    nationalID: yup.string().required("الرقم القومي مطلوب"),
    districts: yup.array().of(
      yup.object().shape({
        cityID: yup.string().required("المنطقة مطلوبة"),
        govID: yup.string().required("المحافظة مطلوبة"),
      })
    ),
  });

  const generateRandomNationId = (length) => {
    const result = randomstring.generate({
      length: length,
      charset: "numeric",
    });
    return result;
  };
  const [lang, setLang] = useState(Cookies.get("i18next"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showMessage, setShowMessage] = useState(false);

  window.onload = () => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  };

  const { t } = useTranslation();

  return (
    <div className={`${styles.RegFormContent} p-2 p-sm-4`}>
      {/* <div className={styles.langBtn}>
        {lang === "en" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("ar");
              Cookies.set("i18next", "ar");
              document.body.dir = "rtl";
              setLang("ar");
            }}
          >
            Arabic
          </Button>
        )}
        {lang === "ar" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("en");
              Cookies.set("i18next", "en");
              document.body.dir = "ltr";
              setLang("en");
            }}
          >
            انجليزية
          </Button>
        )}
      </div> */}
      <Container fluid>
        <Row className="flex-column-reverse flex-lg-row ">
          <Col xs={12} lg={6}>
            <h5 className={`${styles.cpcRegTitle} mb-4`}>
              برنامج نقاط وياك للمحلات
            </h5>
            <h5 className={`${styles.cpcRegSubTitle} mb-2`}>
              تسجيل مالك المحل
            </h5>
            <div className="p-1 p-sm-2">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  email: "",
                  nationalID: `${generateRandomNationId(14)}`,
                  businessName: "",
                  districts: [
                    {
                      govID: "",
                      cityID: "",
                    },
                  ],
                }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading("Loading...", {
                    transition: Zoom,
                    position: "bottom-left",
                  });
                  setSubmitDisabled(true);
                  setTimeout(() => {
                    setSubmitDisabled(false);
                  }, 3000);
                  dispatch(
                    userRegister({
                      firstName: values.firstName,
                      lastName: values.lastName,
                      phoneNumber: values.phoneNumber,
                      email: values.email,
                      nationalID: values.nationalID,
                      govID: values.districts[0].govID,
                      cityID: values.districts[0].cityID,
                      businessName: values.businessName,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                      setShowMessage(true);
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={reisterValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("ownerName")}
                        </Form.Label>
                        <Col xs={6}>
                          <Form.Control
                            type="text"
                            placeholder={t("firstName")}
                            className={`${styles.regFormInput} mb-3 mb-md-0`}
                            name="firstName"
                            id="firstName"
                            onChange={handleChange}
                            value={values.firstName}
                          />
                          {errors.firstName && touched.firstName && (
                            <Form.Text className="text-error">
                              {errors.firstName}
                            </Form.Text>
                          )}
                        </Col>
                        <Col xs={6}>
                          <Form.Control
                            type="text"
                            placeholder={t("lastName")}
                            className={`${styles.regFormInput}`}
                            name="lastName"
                            id="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                          />
                          {errors.lastName && touched.lastName && (
                            <Form.Text className="text-error">
                              {errors.lastName}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("email")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="email"
                            placeholder=""
                            className={`${styles.regFormInput}`}
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                        </Col>
                        {errors.email && touched.email && (
                          <Form.Text className="text-error">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("mobileNumber")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="text"
                            maxLength={11}
                            placeholder="******01"
                            className={`${styles.regFormInput} ${styles.phoneStyle}`}
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.phoneNumber}
                          />
                        </Col>
                        {errors.phoneNumber && touched.phoneNumber && (
                          <Form.Text className="text-error">
                            {errors.phoneNumber}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("merchantName")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="text"
                            placeholder="إسم المحل"
                            className={`${styles.regFormInput}`}
                            name="businessName"
                            id="businessName"
                            onChange={handleChange}
                            value={values.businessName}
                          />
                        </Col>
                        {errors.businessName && touched.businessName && (
                          <Form.Text className="text-error">
                            {errors.businessName}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Col xs={12} sm={6}>
                          <Form.Label
                            className={`${styles.regFormLabel}`}
                            column
                            sm={12}
                          >
                            {`${t("gover")} (${t("mainBranch")})`}
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Select
                              className={`${styles.regFormInput}`}
                              name="govID"
                              id="govID"
                              onChange={(e) => {
                                setGovid(e.currentTarget.value);
                                setFieldValue(
                                  `districts[0].govID`,
                                  e.currentTarget.value
                                );
                                setAcitveCityField(true);
                              }}
                              value={values.districts[0].govID}
                            >
                              <option value="">{t("selectGover")}</option>

                              {govers.map((gov, index) => {
                                return (
                                  <option
                                    value={gov["GovernateID"]}
                                    key={index + 1}
                                  >
                                    {gov[t("govers")]}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label
                            className={`${styles.regFormLabel}`}
                            column
                            sm={12}
                          >
                            {`${t("district")} (${t("mainBranch")})`}
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Select
                              className={`${styles.regFormInput}`}
                              disabled={!activeCityField}
                              name="cityID"
                              id="cityID"
                              onChange={(e) => {
                                setFieldValue(
                                  `districts[0].cityID`,
                                  e.currentTarget.value
                                );
                              }}
                              value={values.districts[0].cityID}
                            >
                              <option value="">{t("selectDistrict")}</option>
                              {cities.map((city, index) => {
                                return (
                                  <option
                                    value={city["cityID"]}
                                    key={index + 1}
                                  >
                                    {}
                                    {city[t("districs")]}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                        </Col>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Check
                          required
                          name="terms"
                          label="اوافق على الشروط و الاحكام"
                          onChange={(e) => {
                            if (e.currentTarget.checked === true) {
                              setSubmitDisabled(false);
                            } else {
                              setSubmitDisabled(true);
                            }
                          }}
                          isInvalid={!!errors.terms}
                          feedback={errors.terms}
                          feedbackType="invalid"
                          id="validationFormik0"
                          className="d-inline-block"
                        />

                        <Link
                          className="d-inline"
                          target={"_blank"}
                          to="/cpc/owner/terms"
                        >
                          عرض الشروط و الاحكام
                        </Link>
                      </Form.Group>
                      <div className="d-grid mt-3">
                        <Button
                          type="submit"
                          variant="primary"
                          size="md"
                          className={`${styles.registerBtn}`}
                          disabled={submitDisabled}
                        >
                          {t("createMerchant")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <img
                src={require("../../imgs/CPC WAYAK Logo-1.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPicc}`}
              />
              <img
                src={require("../../imgs/cpc-reg-form.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPic}`}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <MessageBox
        show={showMessage}
        onHide={() => {
          setShowMessage(false);
        }}
      />
    </div>
  );
}
