import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./components/admin/dashboard/Dashboard";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Home from "./components/admin/home/Home";
import Team from "./components/admin/team/Team";
import AddActivator from "./components/admin/team/add/AddActivator";
import AdminLogin from "./components/admin/login/AdminLogin";
import VerifyEmail from "./components/admin/verify/VerifyEmail";
import ForgetPass from "./components/admin/forget/ForgetPass";
import ReportActivator from "./components/admin/team/report/ReportActivator";
import Assigned from "./components/admin/team/report/assigned/Assigned";
import Activated from "./components/admin/team/report/activated/Activated";
import Shop from "./components/admin/shop/Shop";
import { encrypt } from "n-krypta";
import Product from "./components/admin/product/Product";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddOwner from "./components/admin/shop/add/AddOwner";
import EditActivator from "./components/admin/team/edit/EditActivator";
import { useDispatch, useSelector } from "react-redux";
import Shops from "./components/activator/shops/Shops";
import AdminPrivateRoute from "./protectedRoutes/AdminPrivateRoute";
import ActivatorPrivateRoute from "./protectedRoutes/ActivatorPrivateRoute";
import TraderPrivateRoute from "./protectedRoutes/TraderPrivateRoute";
import OwnerPrivateRoute from "./protectedRoutes/OwnerPrivateRoute";
import CustomerPrivateRoute from "./protectedRoutes/CustomerPrivateRoute";
import AddShops from "./components/activator/shops/add/AddShops";
import ViewShops from "./components/activator/shops/view/ViewShops";
import EditShopOfOwner from "./components/activator/shops/edit/EditShopOfOwner";
import Qrcodes from "./components/activator/qrcodes/Qrcodes";
import AddCampaign from "./components/admin/product/campaign/create/AddCampaign";
import OwnerDetails from "./components/owner/details/OwnerDetails";
import OwnerLandingPage from "./components/owner/landingPage/OwnerLandingPage";
import NationalID from "./components/owner/nationalID/NationalID";
import TraderHome from "./components/trader/home/TraderHome";
import RedeemPoints from "./components/owner/redeem/RedeemPoints";
import GiftCards from "./components/trader/giftcards/GiftCards";
import TraderDetails from "./components/trader/details/TraderDetails";
import OwnerLogin from "./components/owner/login/OwnerLogin";
import SendCode from "./components/admin/sendcode/SendCode";
import { useEffect, useState } from "react";
import SendOtp from "./components/owner/sendotp/SendOtp";
import ResetPass from "./components/owner/resetpassword/ResetPass";
import ChangePassword from "./components/trader/changepassword/ChangePassword";
import OwnerBalance from "./components/owner/balance/OwnerBalance";
import OwnerDetail from "./components/owner/detail/OwnerDetail";
import TeamReport from "./components/admin/team/activatorReport/TeamReport";
import MerchantReport from "./components/admin/shop/merchantReport/MerchantReport";
import OwnerReport from "./components/owner/ownerReport/OwnerReport";
import RegForm from "./components/regForm/RegForm";
import ProductReport from "./components/admin/product/uploadBatch/ProductReport/ProductReport";
import "./App.css";
import "animate.css";
import RedeemReport from "./components/admin/product/earnScheme/redeemReport/RedeemReport";
import Cookies from "js-cookie";
import i18next from "i18next";
import Sidebar from "./components/customer/sidebar/Sidebar";
import CustomerHome from "./components/customer/home/CustomerHome";
import Landing from "./components/landing/Landing";
import CustomerGifts from "./components/customer/gjftcards/CustomerGifts";
import CustomerLogin from "./components/customer/login/CustomerLogin";
import CustomerRegister from "./components/customer/register/CustomerRegister";
import CustomerRedeem from "./components/customer/redeem/CustomerRedeem";
import CustomerChangepassowrd from "./components/customer/changepassword/CustomerChangepassowrd";
import HelpWhatsapp from "./components/help/HelpWhatsapp";
import Station from "./components/admin/station/Station";
import AddStation from "./components/admin/station/add/AddStation";
import StationLogin from "./components/stationOwner/login/StationLogin";
import StationOwnerPrivateRoute from "./protectedRoutes/StationOwnerPrivateRoute";
import StationHome from "./components/stationOwner/home/StationHome";
import AddOperator from "./components/stationOwner/home/addOperator/AddOperator";
import StationOperators from "./components/admin/station/operators/StationOperators";
import StationOrdersReport from "./components/admin/station/reports/stationOrders/StationOrdersReport";
import ReportsHome from "./components/areaManager/reports/ReportsHome";
import StationPaymentReport from "./components/admin/station/reports/stationPayment/StationPaymentReport";
import StationOwnerForgetpass from "./components/stationOwner/forgetPassword/StationOwnerForgetpass";
import StationReport from "./components/stationOwner/report/StationReport";
import CustomerForgetPassword from "./components/customer/forgetPass/CustomerForgetPassword";
import CustomerForgetPassSendOtp from "./components/customer/forgetPass/sendOtp/CustomerForgetPassSendOtp";
import StationsDetails from "./components/customer/redeemm/Stations/StationsDetails";
import StationOwnerSoon from "./components/landing/comingSoon/stationOwner/StationOwnerSoon";
import CustomerSoon from "./components/landing/comingSoon/customer/CustomerSoon";
import OwnerTerms from "./components/regForm/termss/OwnerTerms";
import { removeWhatsIcon } from "./redux/features/userSlice";
import MerchantListingReport from "./components/admin/shop/merchantListingReport/MerchantListingReport";
import OwnerGifts from "./components/owner/giftcards/OwnerGifts";
import StationReports from "./components/stationOwner/reports/StationReports";
import AddNewStation from "./components/admin/station/addnew/AddNewStation";
import AreaManagerPrivateRoute from "./protectedRoutes/AreaManagerPrivateRoute";
import OfficeManagerPrivateRoute from "./protectedRoutes/OfficeManagerPrivateRoute";
import AreaManagerStations from "./components/areaManager/stations/AreaManagerStations";
import AreaManagerStationsDetails from "./components/areaManager/stations/details/AreaManagerStationsDetails";
import OfficeManagerStations from "./components/officeManager/stations/OfficeManagerStations";
import OfficeManagerStationsDetails from "./components/officeManager/stations/details/OfficeManagerStationsDetails";
import StationDetails from "./components/admin/station/details/StationDetails";
import EditStation from "./components/admin/station/edit/EditStation";
import OfficeManagerReportsHome from "./components/officeManager/reports/OfficeManagerReportsHome";

function App() {
  const token = useSelector((state) => state.cpc.user.login.token);
  const role = useSelector((state) => state.cpc.user.login.roleName);
  const pathStatus = useSelector((state) => state.cpc.user.login.pathStatus);

  const [sendCodeEmail, setSendCodeEmail] = useState("");
  const [sendPhoneNumber, setSendPhoneNumber] = useState("");
  const [sendCustomerPhoneNumber, setSendCustomerPhoneNumber] = useState("");

  const [status, setStatus] = useState(null);

  const handlesendcode = (email) => {
    setSendCodeEmail(email);
  };
  const handlephonenumber = (mobileNumber) => {
    setSendPhoneNumber(mobileNumber);
  };

  const handleCustomerMobileNumber = (mobileNumber) => {
    setSendCustomerPhoneNumber(mobileNumber);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (i18next.language == "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, []);

  return (
    <div className="App">
      <Router>
        {token === "" && role === "" && (
          <Routes>
            <Route path="/" element={<Navigate to="/cpc/dashboard" />}></Route>
            <Route path="/*" element={<Navigate to="/cpc/dashboard" />}></Route>
            <Route path="/cpc/dashboard" element={<Landing />}></Route>
            <Route path="/cpc/login" element={<AdminLogin />}></Route>
            <Route
              path="/cpc/ownerTrader/login"
              element={<OwnerLogin />}
            ></Route>
            <Route path="/cpc/owner/terms" element={<OwnerTerms />}></Route>
            <Route
              path="/cpc/ownerTrader/forget/password"
              element={<SendOtp handlephonenumber={handlephonenumber} />}
            ></Route>
            <Route
              path="/cpc/ownerTrader/reset/password"
              element={<ResetPass phone={sendPhoneNumber} />}
            ></Route>
            <Route
              path="/cpc/customer/forget/password"
              element={
                <CustomerForgetPassSendOtp
                  handlephonenumber={handleCustomerMobileNumber}
                />
              }
            ></Route>
            <Route
              path="/cpc/customer/reset/password"
              element={
                <CustomerForgetPassword phone={sendCustomerPhoneNumber} />
              }
            ></Route>
            <Route
              path="/cpc/customer/login"
              element={<CustomerLogin />}
            ></Route>
            <Route
              path="/cpc/customer/ComingSoon"
              element={<CustomerSoon />}
            ></Route>
            {/* <Route
              path="/cpc/customer/login/:operator_id/:operator_phoneNumber/:station_id/:station_name/:gov_id/:city_id"
              element={<CustomerLogin />}
            ></Route> */}
            <Route
              path="/cpc/customer/register"
              element={<CustomerRegister />}
            ></Route>
            <Route
              path="/activator/:activatorID/merchant/:merchantID"
              element={<OwnerLandingPage />}
            >
              <Route path="activate" element={<OwnerDetails />}></Route>
              <Route path="activate/upload" element={<NationalID />}></Route>
            </Route>
            <Route
              path="/cpc/reset/password"
              element={<VerifyEmail email={sendCodeEmail} />}
            ></Route>
            <Route path="/cpc/change/password" element={<ForgetPass />}></Route>
            <Route
              path="/cpc/forget/password"
              element={<SendCode handlesendcode={handlesendcode} />}
            ></Route>
            <Route path="/cpc/register" element={<RegForm />}></Route>
            {/* Customer Dashbaor Trial */}
            <Route
              path="/cpc/stationOwner/login"
              element={<StationLogin />}
            ></Route>
            <Route
              path="/cpc/stationOwner/ComingSoon"
              element={<StationOwnerSoon />}
            ></Route>
            <Route
              path="/cpc/stationOwner/forgetpassword"
              element={<StationOwnerForgetpass />}
            ></Route>
          </Routes>
        )}
        {token !== "" && role === "Activator" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/activator/dashboard/shops" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/activator/dashboard/shops" />}
            ></Route>
            <Route
              path="activator/dashboard"
              element={
                <ActivatorPrivateRoute token={token} condition={role}>
                  <Home />
                </ActivatorPrivateRoute>
              }
            >
              <Route path="qrcodes" element={<Qrcodes />}></Route>
              <Route path="shops" element={<Shops />}></Route>
              <Route path=":ownerID/addshop" element={<AddShops />}></Route>
              <Route path=":ownerID/viewshops" element={<ViewShops />}></Route>
              <Route
                path=":ownerID/editShops/:shopID"
                element={<EditShopOfOwner />}
              ></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "Admin" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/admin/dashboard/team" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/admin/dashboard/team" />}
            ></Route>
            <Route
              path="admin/dashboard"
              element={
                <AdminPrivateRoute token={token} condition={role}>
                  <Home />
                </AdminPrivateRoute>
              }
            >
              <Route path="team" element={<Team />}></Route>
              <Route path="team/add" element={<AddActivator />}></Route>
              <Route path="team/reports" element={<TeamReport />}></Route>
              <Route
                path="team/edit/:activatorID"
                element={<EditActivator />}
              ></Route>
              <Route
                path="team/report/:activatorID"
                element={<ReportActivator />}
              >
                <Route index element={<Navigate to="assigned" />} />

                <Route path="assigned" element={<Assigned />}></Route>
                <Route path="activated" element={<Activated />}></Route>
              </Route>
              <Route path="merchants/all" element={<Shop />}></Route>
              <Route
                path="merchants/all/addOwner"
                element={<AddOwner />}
              ></Route>
              <Route
                path="merchants/all/reports"
                element={<MerchantReport />}
              ></Route>
              <Route
                path="merchants/listing/report"
                element={<MerchantListingReport />}
              ></Route>
              <Route
                path="merchants/all/details/:shopID"
                element={<Dashboard />}
              ></Route>
              {/* <Route
                path="owners&shops/details/:shopID/edit"
                element={<EditShop />}
              ></Route> */}
              <Route path="products" element={<Product />}></Route>
              <Route path="products/report" element={<ProductReport />}></Route>
              <Route
                path="products/redeem/report"
                element={<RedeemReport />}
              ></Route>
              <Route path="campaigns/create" element={<AddCampaign />}></Route>
              <Route path="stations" element={<Station />}></Route>
              {/* <Route
                path="stations/operating_details/:stationID"
                element={<StationOperators />}
              ></Route> */}
              <Route
                path="stations/stationDetails/:stationID"
                element={<StationDetails />}
              ></Route>
              <Route
                path="stations/edit/:stationID"
                element={<EditStation />}
              ></Route>
              <Route path="stations/add" element={<AddNewStation />}></Route>
              <Route path="stations/report" element={<ReportsHome />}></Route>
              <Route
                path="stations/report/ordersReport"
                element={<StationOrdersReport />}
              ></Route>
              <Route
                path="stations/report/paymentReport"
                element={<StationPaymentReport />}
              ></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "CPCArea_Manager" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/manager/area/dashboard/stations" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/manager/area/dashboard/stations" />}
            ></Route>
            <Route
              path="/manager/area/dashboard"
              element={<Navigate to="/manager/area/dashboard/stations" />}
            ></Route>
            <Route
              path="/manager/area/dashboard"
              element={
                <AreaManagerPrivateRoute token={token} condition={role}>
                  <Home />
                </AreaManagerPrivateRoute>
              }
            >
              <Route path="stations/reports" element={<ReportsHome />}></Route>
              <Route path="stations" element={<AreaManagerStations />}></Route>
              <Route
                path="stations/details/:stationID"
                element={<AreaManagerStationsDetails />}
              ></Route>
              <Route
                path="change/password"
                element={<ChangePassword />}
              ></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "CPCOffice_Manager" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/manager/office/dashboard/stations" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/manager/office/dashboard/stations" />}
            ></Route>
            <Route
              path="/manager/office/dashboard"
              element={<Navigate to="/manager/office/dashboard/stations" />}
            ></Route>
            <Route
              path="/manager/office/dashboard"
              element={
                <OfficeManagerPrivateRoute token={token} condition={role}>
                  <Home />
                </OfficeManagerPrivateRoute>
              }
            >
              <Route
                path="stations/reports"
                element={<OfficeManagerReportsHome />}
              ></Route>
              <Route
                path="stations"
                element={<OfficeManagerStations />}
              ></Route>
              <Route
                path="stations/details/:stationID"
                element={<OfficeManagerStationsDetails />}
              ></Route>
              <Route
                path="change/password"
                element={<ChangePassword />}
              ></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "Owner" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/owner/dashboard/balance" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/owner/dashboard/balance" />}
            ></Route>
            <Route
              path="/owner/dashboard"
              element={
                <OwnerPrivateRoute token={token} condition={role}>
                  <Home />
                </OwnerPrivateRoute>
              }
            >
              <Route path="details" element={<OwnerDetail />}></Route>
              <Route path="balance" element={<OwnerBalance />}></Route>
              <Route path="balance/report" element={<OwnerReport />}></Route>
              <Route
                path="balance/redeemPoints"
                element={<RedeemPoints />}
              ></Route>
              <Route path="giftCards" element={<OwnerGifts />}></Route>
              <Route
                path="change/password"
                element={<ChangePassword />}
              ></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "Trader" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/trader/dashboard/home" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/trader/dashboard/home" />}
            ></Route>
            <Route
              path="/trader/dashboard"
              element={
                <TraderPrivateRoute token={token} condition={role}>
                  <Home />
                </TraderPrivateRoute>
              }
            >
              <Route path="home" element={<TraderHome />}></Route>
              {/* <Route path="details" element={<TraderDetails />}></Route> */}
              <Route
                path="change/password"
                element={<ChangePassword />}
              ></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "Consumer" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/customer/dashboard/home" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/customer/dashboard/home" />}
            ></Route>
            <Route
              path="/customer/dashboard"
              element={
                <CustomerPrivateRoute token={token} condition={role}>
                  <Sidebar />
                </CustomerPrivateRoute>
              }
            >
              <Route path="home" element={<CustomerHome />}></Route>
              <Route path="home/redeem" element={<CustomerRedeem />}></Route>
              <Route
                path="home/redeem/vouchers"
                element={<StationsDetails />}
              ></Route>
              <Route
                path="change/password"
                element={<CustomerChangepassowrd />}
              ></Route>
              <Route path="gifts" element={<CustomerGifts />}></Route>
            </Route>
          </Routes>
        )}
        {token !== "" && role === "stationOwner" && (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/stationOwner/dashboard/home" />}
            ></Route>
            <Route
              path="/*"
              element={<Navigate to="/stationOwner/dashboard/home" />}
            ></Route>
            <Route
              path="/stationOwner/dashboard"
              element={
                <StationOwnerPrivateRoute token={token} condition={role}>
                  <Home />
                </StationOwnerPrivateRoute>
              }
            >
              <Route path="home" element={<StationHome />}></Route>
              <Route path="home/add/operator" element={<AddOperator />}></Route>
              <Route path="home/report" element={<StationReport />}></Route>
              {/* <Route path="home/reports" element={<StationReports />}></Route> */}
              <Route
                path="change/password"
                element={<CustomerChangepassowrd />}
              ></Route>
            </Route>
          </Routes>
        )}
      </Router>
      {i18next.language == "ar" ? (
        <ToastContainer
          rtl
          className="toast-style"
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      ) : (
        <ToastContainer
          className="toast-style"
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      )}
      {/* {role === "Owner" ||
        role === "Trader" ||
        role === "Consumer" ||
        (role === "" && (
          <div className="callsContent">
            <HelpWhatsapp />
          </div>
        ))} */}
    </div>
  );
}

export default App;
