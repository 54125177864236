import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./features/adminSlice";
import userReducer from "./features/userSlice";
import ownerReducer from "./features/ownerSlice";
import traderReducer from "./features/TraderSlice";
import activatorReducer from "./features/activatorSlice";
import customerReducer from "./features/customerSlice";
import stationReducer from "./features/stationSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const cpc = combineReducers({
  user: userReducer,
  activator: activatorReducer,
  admin: adminReducer,
  owner: ownerReducer,
  trader: traderReducer,
  customer: customerReducer,
  station: stationReducer,
});

const persistedReducer = persistReducer(persistConfig, cpc);

export const store = configureStore({
  reducer: {
    cpc: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
