import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { createPDFQrCode } from "../../../../redux/features/stationSlice";
import { Formik } from "formik";
import i18next from "i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Zoom } from "react-toastify";
import axios from "axios";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import fileDownload from "js-file-download";
import Pagination from "../../../pagination/Pagination";
import { handleLogout } from "../../../../redux/features/userSlice";
import styles from "./StationDetails.module.scss";

export default function StationDetails() {
  const [details, setDetails] = useState(null);
  const [operators, setOperators] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = useSelector((state) => state.cpc.user.login.token);
  const { stationID } = useParams();

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/stations", {
        params: {
          governorateID: "",
          cityID: "",
          AreaID: null,
          officeID: null,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const targetStation = res.data.data.filter(
            (sat) => sat["station_id"] == stationID
          );
          setDetails(targetStation[0]);
          setOperators(targetStation[0]["stationOperators"]);
        }
        if (res.data.statusCode === 404) {
          navigate("/admin/dashboard/stations");
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  return (
    <div className={`${styles.stationDetailsContent} p-1`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations");
                }}
              />
              {details !== null && details["station_name"]}
            </h3>
          </Col>
        </Row>
        {details !== null && (
          <Row className="mt-4">
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("areaName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["stationOffice"]["officeArea"]["area_name_ar"]}`
                    : `${details["stationOffice"]["officeArea"]["area_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("officeName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["stationOffice"]["office_name_ar"]}`
                    : `${details["stationOffice"]["office_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("goverName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["governorate"]["governorate_name_ar"]}`
                    : `${details["governorate"]["governorate_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("cityName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["city"]["city_name_ar"]}`
                    : `${details["city"]["city_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>
                  {t("stationEmail")}
                </h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${
                    details["stationOffice"]["reportUser"] == null
                      ? "لا يوجد"
                      : details["stationOffice"]["reportUser"]["email"]
                  }`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>
                  {t("stationPhoneNumber")}
                </h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${
                    details["stationOffice"]["reportUser"] == null
                      ? "لا يوجد"
                      : details["stationOffice"]["reportUser"]["phone"]
                  }`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("stationCode")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${
                    details["stationOffice"]["reportUser"] == null
                      ? "لا يوجد"
                      : details["stationOffice"]["reportUser"]["NID"]
                  }`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>
                  {t("stationAddress")}
                </h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${details["address_line"]}`}
                </p>
              </div>
            </Col>
            {/* Operators  */}
            {operators.length !== 0 &&
              operators.map((operator, index) => {
                return (
                  <Col xs={12} sm={6} className="mb-3 p-2" key={index}>
                    <div className={`${styles.detailsElement} px-3 py-3`}>
                      <div className="mb-3">
                        <h6 className={`${styles.detailsTitle}`}>
                          {t("operatorName")}
                        </h6>
                        <p className={`${styles.detailsSubTitle} mb-0`}>
                          {operator["name"]}
                        </p>
                      </div>
                      <div>
                        <h6 className={`${styles.detailsTitle}`}>
                          {t("operatorNumber")}
                        </h6>
                        <p className={`${styles.detailsSubTitle} mb-0`}>
                          {operator["phone_number"]}
                        </p>
                      </div>
                      <div className="d-grid mt-3">
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            dispatch(
                              createPDFQrCode({
                                operatorID: operator["operator_id"],
                                token,
                              })
                            ).then((res) => {
                              fileDownload(
                                res.payload.data,
                                `operator-${operator["name"]}_qrcode.pdf`
                              );
                            });
                          }}
                        >
                          {t("downloadQr")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
    </div>
  );
}
