import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Form,
} from "react-bootstrap";
import Cookies from "js-cookie";
import Select from "react-select";
import styles from "./Pagination.module.scss";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

export default function Pagination({
  totalPosts,
  postsPerPage,
  onChangePage,
  currentPage,
}) {
  const [final, setFinal] = useState();
  const [nextDisable, setNextDisable] = useState(false);
  const [preDisable, setPreDisable] = useState(false);
  const [value, setValue] = useState(0);
  let pages = [];

  let pageCount = Math.ceil(totalPosts / postsPerPage);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }



  const { t } = useTranslation();

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <div
              className={`${styles.paginationContent} d-flex justify-content-center align-items-end container w-100`}
            >
              <ReactPaginate
                forcePage={
                  Number(Cookies.get("page")) ? Number(Cookies.get("page")) : 0
                }
                previousLabel={t("pre")}
                nextLabel={t("next")}
                pageClassName="page-item mb-2"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={onChangePage}
                containerClassName="pagination d-flex justify-content-center flex-wrap"
                activeClassName="active"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
