import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import { Formik } from "formik";
import i18next from "i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Zoom } from "react-toastify";
import { handleLogout } from "../../../redux/features/userSlice";
import Pagination from "../../pagination/Pagination";
import styles from "./OfficeManagerStations.module.scss";

export default function OfficeManagerStations() {
  const [stations, setStations] = useState([]);
  const [allStations, setAllStations] = useState([]);
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [total, setTotal] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [stationStatus, setStationStatus] = useState(2);

  const [message, setMessage] = useState("");
  const [messageAr, setMessageAr] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);
  const officeID = useSelector((state) => state.cpc.user.login.officeID);
  const areaID = useSelector((state) => state.cpc.user.login.areaID);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  const currentPosts = stations.slice(itemOffset, endOffset);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/stations", {
        params: {
          governorateID: "",
          cityID: "",
          AreaID: areaID,
          officeID: officeID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          setStations(res.data.data);
          setTotal(res.data.data.length);
        }
        if (res.data.statusCode === 404) {
          setStations([]);
          setMessage(res.data.error);
          setMessageAr(res.data.errorAr);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [officeID, areaID]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * postsPerPage) % stations.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };
  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % stations.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });

  return (
    <div className={`${styles.OfficeManagerStationsContent} p-1`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              {t("listStations")}
            </h3>
          </Col>
          <Col xs={12} md={6}>
            <div className={`${styles.btnRight}`}>
              <Button
                size="md"
                variant="secondary"
                className={`${styles.reportBtn} me-2 mb-2`}
                onClick={() => {
                  navigate("/manager/office/dashboard/stations/reports");
                }}
              >
                {t("stationsReport")}
              </Button>
            </div>
          </Col>
          {stations.length !== 0 && (
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
                <div className={`${styles.filterForm} mb-3 mb-lg-0`}>
                  <Formik
                    initialValues={{ stationStatus: "" }}
                    onSubmit={(values) => {
                      setStationStatus(values.stationStatus);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      setFieldValue,
                      handleSubmit,
                    }) => {
                      return (
                        <Form onSubmit={handleSubmit}>
                          <InputGroup className={`${styles.filterInput}`}>
                            <InputGroup.Text
                              className={`${styles.filterLabel}`}
                            >
                              <HiFilter />
                            </InputGroup.Text>
                            <Form.Select
                              size="sm"
                              name="stationStatus"
                              id="stationStatus"
                              onChange={(e) => {
                                setItemOffset(0);
                                setFieldValue(
                                  "stationStatus",
                                  e.currentTarget.value
                                );
                                handleSubmit();
                              }}
                              className={`${styles.filterSelect}`}
                              value={values.stationStatus}
                            >
                              <option value={2}>{t("allStations")}</option>
                              <option value={1}>{t("active")}</option>
                              <option value={0}>{t("notActive")}</option>
                            </Form.Select>
                          </InputGroup>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className={`${styles.searchForm}`}>
                  <Formik
                    initialValues={{ searchValue: "" }}
                    onSubmit={(values) => {}}
                  >
                    {({ values, touched, handleChange, handleSubmit }) => {
                      return (
                        <Form onSubmit={handleSubmit}>
                          <InputGroup className={`${styles.searchInput}`}>
                            <InputGroup.Text
                              className={`${styles.searchLabel}`}
                            >
                              <BiSearch />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder={t("search")}
                              className={`${styles.searchField}`}
                              id="searchValue"
                              name="searchValue"
                              onChange={(e) => {
                                if (page !== 1) {
                                  setPage(1);
                                }
                                setSearchValue(e.currentTarget.value);
                              }}
                            />
                          </InputGroup>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Col>
          )}
        </Row>
        {stations.length == 0 ? (
          <Row className="mt-4">
            <Col xs={12} className="mb-3">
              <div className="text-center">
                {i18next.language === "ar" ? messageAr : message}
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="mt-4">
            <Col xs={12}>
              {currentPosts.map((station, index) => {
                return (
                  <div
                    className={`${styles.bgGrey} px-4 py-3 mb-3`}
                    key={index}
                  >
                    <div
                      className={`${styles.activatorInfoContent} d-flex align-items-center`}
                    >
                      <img
                        src={require("../../../imgs/fuellogo.jpeg")}
                        className="me-2"
                      />
                      <div className={`${styles.activatorInfo} mt-2`}>
                        <h5>{station["station_name"]}</h5>
                        <p dir="rtl">{station["address_line"]}</p>
                        {/* <span className="mt-3">01121830506</span> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.activatorBtnContent}  h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
                    >
                      <Button
                        variant="primary"
                        className={`${styles.detailsBtn} me-2`}
                        onClick={() => {
                          navigate(
                            `/manager/office/dashboard/stations/details/${station["station_id"]}`
                          );
                        }}
                      >
                        {t("stationDetails")}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col xs={12}>
              <Pagination
                totalPosts={total}
                postsPerPage={postsPerPage}
                onChangePage={handlePageClick}
                currentPage={itemOffset}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
