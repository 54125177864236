import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { changePassword } from "../../../redux/features/TraderSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";
import styles from "./ChangePassword.module.scss";
import OwnerAcceptOtp from "./acceptOtp/OwnerAcceptOtp";
import { useTranslation } from "react-i18next";
import { ownerSendOTP } from "../../../redux/features/userSlice";

export default function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);
  const roleName = useSelector((state) => state.cpc.user.login.roleName);

  const changePasswordValidation = yup.object().shape({
    oldPassword: yup.string().required("كلمة السر القديمة مطلوبة"),
    newPassword: yup.string().required("كلمة السر الجديدة مطلوبة"),
    confirmPassword: yup
      .string()
      .required("تاكيد كلمة السر مطلوب")
      .oneOf([yup.ref("newPassword"), null], "كلمة السر غير صحيحة"),
  });

  const { t } = useTranslation();

  const [verfCode, setVerfCode] = useState("");
  const [changePasswordInfo, setChangePasswordInfo] = useState({
    phoneNumber: "",
    currentPassword: "",
    newPassword: "",
  });
  const [showOtp, setShowOtp] = useState(false);

  const phoneNumber = useSelector(
    (state) => state.cpc.user.login.OwnerTraderPhoneNumber
  );

  return (
    <div className={`${styles.changePasswordContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  if (roleName === "Trader") {
                    navigate("/trader/dashboard/home");
                  }
                  if (roleName === "Owner") {
                    navigate("/owner/dashboard/balance");
                  }
                }}
              />
              {t("changePassword")}
            </h3>
          </Col>
        </Row>
        <Col xs={12} md={{ span: 8, offset: 2 }}>
          <Row className="mt-3">
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={(values, actions) => {
                const loadReq = toast.loading("Loading...", {
                  transition: Zoom,
                  position: "bottom-left",
                });
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 3000);
                dispatch(
                  ownerSendOTP({
                    mobileNumber: phoneNumber,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    setShowOtp(true);
                    setChangePasswordInfo({
                      phoneNumber: phoneNumber,
                      currentPassword: values.oldPassword,
                      newPassword: values.newPassword,
                    });
                    setVerfCode(res.payload.data.verificationCode);
                    successMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data.errorAr,
                      loadReq
                    );
                  }
                });

              }}
              validationSchema={changePasswordValidation}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form
                    id="changePassword"
                    onSubmit={handleSubmit}
                    className={`${styles.changePasswordFormContent} p-4 p-md-4`}
                  >
                    <Row className="mb-3">
                      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("oldPass")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("oldPass")}
                          className={`${styles.addInput}`}
                          name="oldPassword"
                          id="oldPassword"
                          onChange={handleChange}
                          value={values.oldPassword}
                        />
                        {errors.oldPassword && touched.oldPassword && (
                          <Form.Text className="text-error">
                            {errors.oldPassword}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("newPass")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("newPass")}
                          className={`${styles.addInput}`}
                          name="newPassword"
                          id="newPassword"
                          onChange={handleChange}
                          value={values.newPassword}
                        />
                        {errors.newPassword && touched.newPassword && (
                          <Form.Text className="text-error">
                            {errors.newPassword}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Col} xs={12} sm={12} className="mb-3">
                        <Form.Label className={`${styles.addInputLabel}`}>
                          {t("confirmPass")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("confirmPass")}
                          className={`${styles.addInput}`}
                          name="confirmPassword"
                          id="confirmPassword"
                          onChange={handleChange}
                          value={values.confirmPassword}
                        />
                        {errors.confirmPassword && touched.confirmPassword && (
                          <Form.Text className="text-error">
                            {errors.confirmPassword}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Col xs={12} className="mt-3 text-center">
                        <Button
                          type="submit"
                          size="md"
                          variant="primary"
                          form="changePassword"
                          className={`${styles.saveBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("changePassword")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Row>
        </Col>
      </Container>
      <OwnerAcceptOtp
        show={showOtp}
        onHide={() => {
          setShowOtp(false);
        }}
        verfcode={verfCode}
        changepasswordinfo={changePasswordInfo}
      />
    </div>
  );
}
