import React from "react";
import { Navigate } from "react-router-dom";

export default function AreaManagerPrivateRoute({
  token,
  condition,
  children,
}) {
  if (condition === "" || condition !== "CPCArea_Manager" || token === "") {
    return <Navigate to="/cpc/login" />;
  } else {
    return children;
  }
}
