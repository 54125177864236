import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { connect, Formik } from "formik";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import { handleLogout } from "../../../redux/features/userSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaRegFileExcel } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "./StationReport.module.scss";
import { date } from "yup";

export default function StationReport() {
  const navigate = useNavigate();
  const girdRef = useRef();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);
  const stationID = useSelector((state) => state.cpc.user.login.station.id);

  const [bookDate, setBookDate] = useState("");
  const [bookDatee, setBookDatee] = useState("");

  const [bookHour, setBookHour] = useState("");
  const [bookHourr, setBookHourr] = useState("");
  const [diffDate, setDiffDate] = useState(true);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "رقم طلب الدفع",
      field: "order_id",
      minWidth: 350,
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data["order_id"]}`}
          </div>
        );
      },
    },
    {
      headerName: "اسم عامل المحطة",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.station.operator["name"]}`}
          </div>
        );
      },
    },
    {
      headerName: "رقم هاتف عمل المحطة",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.station.operator["phone_number"]}`}
          </div>
        );
      },
    },
    {
      headerName: "نوع الوقود",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`بنزين ${result.data["fuel_type"]}`}
          </div>
        );
      },
    },
    {
      headerName: "رقم هاتف العميل",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.customer["phone"]}`}
          </div>
        );
      },
    },
    {
      headerName: "اسم العميل",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.customer["firstName"]} ${result.data.customer["lastName"]}`}
          </div>
        );
      },
    },
    {
      headerName: "رقم السيارة",
      field: "",
      minWidth: 300,
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {`${result.data.car["plate_number_letters"]} - ${result.data.car["plate_number_numbers"]}`}
          </div>
        );
      },
    },
    {
      headerName: "المبلغ المنصرف",
      field: "amount",
      wrapText: false,
      autoHeight: true,
      // cellRenderer: (result) => {
      //   return (
      //     <div className={`${styles.orderIdContent}`}>
      //       <ul>
      //         {result.data["fuelPayments"].map((pay, index) => {
      //           return <li key={index}>{pay["points_used"]}</li>;
      //         })}
      //       </ul>
      //     </div>
      //   );
      // },
    },
    {
      headerName: "حالة الصرف",
      field: "status",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {(result.data.status = true ? "مقبول" : "مرفوض")}
          </div>
        );
      },
    },
    {
      headerName: "التاريخ و الوقت",
      field: "",
      wrapText: false,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.orderIdContent}`}>
            {moment(result.data["createdAt"])
              .utc()
              .format("MM-DD-YYYY, h:mm:ss a")}
          </div>
        );
      },
    },
  ]);

  const defaultColDef = {
    flex: 1,
    sortable: false,
    filter: false,
    floatingFilter: false,
    minWidth: 200,
    resizable: true,
  };

  const [rowData, setRowData] = useState([]);
  const [filter, setFilter] = useState({
    status: "",
    customerPhone: "",
    transactionId: "",
    amountFrom: "",
    amountTo: "",
    plateLetters: "",
    plateNumbers: "",
    voucher_type: "",
    fromDate: "",
    toDate: "",
    operatorPhone: "",
    operatorName: "",
    format: "",
    transactionHourFrom: "",
    transactionHourTo: "",
    fuelType: "",
  });

  console.log(filter);

  useEffect(() => {
    axios
      .get(
        "https://evouchers.shareecoin.com/CPC/fuelStation/orders/getReport",
        {
          responseType: filter.format === "" ? "" : "blob",
          params: {
            sort: "desc",
            sortBy: "createdAt",
            fromDate: filter.fromDate,
            toDate: filter.toDate,
            transactionId: filter.transactionId,
            customerPhone: filter.customerPhone,
            operatorName: filter.operatorName,
            operatorId: "",
            operatorPhone: filter.operatorPhone,
            stationId: stationID,
            govId: "",
            cityId: "",
            limit: 1000000,
            page: "",
            offset: "",
            voucher_type: filter["voucher_type"],
            fuelType: filter.fuelType,
            plateLetters: filter.plateLetters,
            plateNumbers: filter.plateNumbers,
            amountFrom: filter.amountFrom,
            amountTo: filter.amountTo,
            status: filter.status,
            Download: filter.format,
            hourFrom: filter.transactionHourFrom,
            hourTo: filter.transactionHourTo,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          if (filter.format == "") {
            setRowData(res.data.orders);
          }
        }
        if (filter.format === "Excel" && res.data.type !== "application/json") {
          const data = window.URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = data;
          link.download = "Station-Report.xlsx";
          link.click();
        }
        if (filter.format === "PDF" && res.data.type !== "application/json") {
          const data = window.URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = data;
          link.download = "Station-Report.pdf";
          link.click();
        }
        if (res.data.type === "application/json") {
          dispatch(handleLogout());
          navigate("/cpc/stationOwner/login");
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/stationOwner/login");
        }
        if (res.data.statusCode === 404) {
          if (filter.format == "") {
            setRowData([]);
          }
        }
      });
  }, [filter]);

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  //   axios
  //     .get(
  //       "https://evouchers.shareecoin.com/CPC/fuelStation/operators/transactions",
  //       {
  //         params: {
  //           startDate: "",
  //           endDate: "",
  //           page: "",
  //           limit: "",
  //           sortBy: "name",
  //           order: "desc",
  //           minAmount: "",
  //           maxAmount: "",
  //           name: "",
  //         },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       const targetStation = res.data.data.filter(
  //         (target) => target["stationOwner_id"] == userID
  //       );
  //       targetStation.map((station) => {
  //         params.api.applyTransaction({ add: station["stationOperators"] });
  //       });
  //     });
  // };

  // const handleExport = () => {
  //   gridApi.exportDataAsCsv({
  //     fileName: "Stations-Report",
  //     processCellCallback: function (params) {
  //       if (params.column.getColId() === "fuelPayment") {
  //         params.value.map((val) => {
  //           return val["order_id"].toString();
  //         });
  //       }
  //       // if (params.column.getColId() === "city") {
  //       //   return params.value[0]["city"]["city_name_en"];
  //       // }
  //       return params.value;
  //     },
  //   });
  // };

  useEffect(() => {
    // const diffTime = Math.abs(date2 - date1);
    // const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    console.log(bookDate, bookDatee);

    let date1 = new Date(bookDate).getTime();
    let date2 = new Date(bookDatee).getTime();

    if (date1 != date2 || bookDate == "" || bookDatee == "") {
      setBookHour("");
      setBookHourr("");
      setDiffDate(true);
    } else {
      setBookHour("");
      setBookHourr("");
      setDiffDate(false);
    }
  }, [bookDate, bookDatee]);

  return (
    <div className={`${styles.stationReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/stationOwner/dashboard/home");
                }}
              />
              {t("stationReportt")}
            </h3>
          </Col>
          <Col xs={12}>
            <Formik
              initialValues={{
                transactionStatus: "",
                clientPhoneNumber: "",
                transactionNumber: "",
                paymentAmountFrom: "",
                paymentAmountTo: "",
                plateNumbers: "",
                plateLetters: "",
                clientType: "",
                transactionDateFrom: "",
                transactionDateTo: "",
                operatorPhoneNumber: "",
                operatorName: "",
                transactionHourFrom: bookHour,
                transactionHourTo: bookHourr,
                fuelType: "",
              }}
              onSubmit={(values) => {
                console.log(values);
                if (values.transactionDateFrom !== values.transactionDateTo) {
                  values.transactionHourFrom = "";
                  values.transactionHourTo = "";
                }
                setFilter({
                  status: values.transactionStatus,
                  customerPhone: values.clientPhoneNumber,
                  transactionId: values.transactionNumber,
                  amountFrom: values.paymentAmountFrom,
                  amountTo: values.paymentAmountTo,
                  plateLetters: values.plateLetters,
                  plateNumbers: values.plateNumbers,
                  voucher_type: values.clientType,
                  fromDate: values.transactionDateFrom,
                  toDate: values.transactionDateTo,
                  operatorPhone: values.operatorPhoneNumber,
                  operatorName: values.operatorName,
                  format: "",
                  transactionHourFrom: values.transactionHourFrom,
                  transactionHourTo: values.transactionHourTo,
                  fuelType: values.fuelType,
                });
              }}
              // enableReinitialize
            >
              {({
                values,
                errors,
                handleChange,
                setFieldValue,
                touched,
                handleSubmit,
                handleReset,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          حالة العملية
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="transactionStatus"
                          id="transactionStatus"
                          onChange={(e) => {
                            setFieldValue(
                              "transactionStatus",
                              e.currentTarget.value
                            );
                          }}
                        >
                          <option value="">اختر حالة العملية</option>
                          <option value={1}>مقبولة</option>
                          <option value={0}>مرفوضة</option>
                        </Form.Select>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          رقم هاتف العميل
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          placeholder="أدخل رقم الهاتف"
                          name="clientPhoneNumber"
                          id="clientPhoneNumber"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setFieldValue(
                              "clientPhoneNumber",
                              e.currentTarget.value
                            );
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          رقم طلب الدفع
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          placeholder="ادخل رقم طلب الدفع"
                          name="transactionNumber"
                          id="transactionNumber"
                          onChange={(e) => {
                            setFieldValue(
                              "transactionNumber",
                              e.currentTarget.value
                            );
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              المبلغ المدفوع ( من )
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputField}`}
                              name="paymentAmountFrom"
                              id="paymentAmountFrom"
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "paymentAmountFrom",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              المبلغ المدفوع ( إلى )
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputField}`}
                              name="paymentAmountTo"
                              id="paymentAmountTo"
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "paymentAmountTo",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              ارقام لوحة السيارة
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputCarField}`}
                              name="plateNumbers"
                              id="plateNumbers"
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "plateNumbers",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              أحرف لوحة السيارة
                            </Form.Label>
                            <Form.Control
                              type="text"
                              size="sm"
                              className={`${styles.filterInputCarField}`}
                              name="plateLetters"
                              id="plateLetters"
                              onChange={(e) => {
                                let string = e.target.value;
                                let array = string.split("");
                                let newStr = array.join(" ");
                                setFieldValue("plateLetters", newStr);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          نوع العميل
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="clientType"
                          id="clientType"
                          onChange={(e) => {
                            setFieldValue("clientType", e.currentTarget.value);
                          }}
                        >
                          <option value="">اختر نوع العميل</option>
                          <option value="Sharee_Coin">عميل شاري كوين</option>
                          <option value="CPC">عميل التعاون</option>
                        </Form.Select>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              تاريخ العملية ( من )
                            </Form.Label>
                            <DatePicker
                              className={`${styles.filterDateField}`}
                              name="transactionDateFrom"
                              id="transactionDateFrom"
                              selected={bookDate}
                              onSelect={(date) => {
                                setFieldValue(
                                  "transactionDateFrom",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDate(date);
                              }}
                              onChange={(date) => {
                                setFieldValue(
                                  "transactionDateFrom",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDate(date);
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              تاريخ العملية ( إلى )
                            </Form.Label>
                            <DatePicker
                              className={`${styles.filterDateField}`}
                              name="transactionDateTo"
                              id="transactionDateTo"
                              selected={bookDatee}
                              onSelect={(date) => {
                                setFieldValue(
                                  "transactionDateTo",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDatee(date);
                              }}
                              onChange={(date) => {
                                setFieldValue(
                                  "transactionDateTo",
                                  moment(date).format("YYYY-MM-DD")
                                );
                                setBookDatee(date);
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          رقم هاتف عامل المحطة
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          placeholder="أدخل رقم الهاتف"
                          name="operatorPhoneNumber"
                          id="operatorPhoneNumber"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setFieldValue(
                              "operatorPhoneNumber",
                              e.currentTarget.value
                            );
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          اسم عامل المحطة
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          placeholder="أدخل اسم العامل"
                          name="operatorName"
                          id="operatorName"
                          onChange={(e) => {
                            setFieldValue(
                              "operatorName",
                              e.currentTarget.value
                            );
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              وقت العملية ( من )
                            </Form.Label>
                            <Form.Control
                              type="time"
                              className={`${styles.filterDateField}`}
                              name="transactionHourFrom"
                              id="transactionHourFrom"
                              onChange={(e) => {
                                if (diffDate === true) {
                                  setBookHour("");
                                  setFieldValue("transactionHourFrom", "");
                                } else {
                                  setBookHour(e.target.value);
                                  setFieldValue(
                                    "transactionHourFrom",
                                    e.target.value
                                  );
                                }
                              }}
                              disabled={diffDate}
                              value={bookHour}
                            />
                          </div>
                          <div className="mx-1">
                            <Form.Label
                              className={`${styles.filterInputLabel}`}
                            >
                              وقت العملية ( إلى )
                            </Form.Label>
                            <Form.Control
                              type="time"
                              className={`${styles.filterDateField}`}
                              name="transactionHourTo"
                              id="transactionHourTo"
                              onChange={(e) => {
                                if (diffDate === true) {
                                  setBookHourr("");
                                  setFieldValue("transactionHourTo", "");
                                } else {
                                  setBookHourr(e.target.value);
                                  setFieldValue(
                                    "transactionHourTo",
                                    e.target.value
                                  );
                                }
                              }}
                              disabled={diffDate}
                              value={bookHourr}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={4} className="mb-3">
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          نوع الوقود
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="fuelType"
                          id="fuelType"
                          onChange={(e) => {
                            setFieldValue("fuelType", e.currentTarget.value);
                          }}
                        >
                          <option value="">اختر نوع الوقود</option>
                          <option value="80">بنزين 80</option>
                          <option value="92">بنزين 92</option>
                          <option value="95">بنزين 95</option>
                          <option value="solar">سولار</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <div className="d-flex justify-content-start align-items-center mb-3">
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                        className={`${styles.searchBtn} m-1`}
                      >
                        {t("search")}
                      </Button>
                      <Button
                        type="reset"
                        variant="primary"
                        size="sm"
                        className={`${styles.searchBtn} m-1`}
                        onClick={() => {
                          setFilter({
                            status: "",
                            customerPhone: "",
                            transactionId: "",
                            amountFrom: "",
                            amountTo: "",
                            plateLetters: "",
                            plateNumbers: "",
                            voucher_type: "",
                            fromDate: "",
                            toDate: "",
                            operatorPhone: "",
                            operatorName: "",
                            format: "",
                            transactionHourFrom: "",
                            transactionHourTo: "",
                            fuelType: "",
                          });
                          handleReset();
                          setBookDate("");
                          setBookDatee("");
                          setBookHour("");
                          setBookHourr("");
                          setDiffDate(true);
                        }}
                      >
                        {t("reset")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine ag-theme-quartz"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                ref={girdRef}
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3">
            <div className="d-flex justify-content-start">
              <Button
                variant="danger"
                size="md"
                className={`${styles.downloadPdfBtn} m-1`}
                onClick={() => {
                  setFilter({ ...filter, format: "PDF" });
                }}
              >
                <FaRegFilePdf className="mx-1" />
                PDF
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
