import React, { useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FaUserAlt, FaStoreAlt } from "react-icons/fa";
import { BsFillFuelPumpFill } from "react-icons/bs";
import styles from "./Landing.module.scss";
import { useNavigate } from "react-router-dom";
import { removeWhatsIcon } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";

export default function Landing() {
  const landing = document.getElementById("landingContent");
  if (landing) {
    import("bootstrap/dist/css/bootstrap.css");
    import("bootstrap/dist/css/bootstrap.rtl.css");
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();



  return (
    <div
      className={`${styles.landingConent} p-2 p-sm-4`}
      id="landingContent"
      dir="rtl"
    >
      <Container fluid>
        <Row className="flex-column-reverse flex-lg-row ">
          <Col
            xs={12}
            lg={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <h5 className={`${styles.cpcRegTitle} mb-4`}>برنامج نقاط وياك</h5>
            <div className={`${styles.btnsGroup}`}>
              <div className="text-center">
                <Button
                  variant="danger"
                  size="lg"
                  className={`${styles.choiceBtn} mx-3`}
                  onClick={() => {
                    // navigate("/cpc/customer/login");
                    navigate("/cpc/customer/ComingSoon");
                  }}
                >
                  <FaUserAlt />
                </Button>
                <h4 className={`${styles.btnTagName} mt-2`}>مستهلك</h4>
              </div>
              <div className="text-center">
                <Button
                  variant="danger"
                  size="lg"
                  className={`${styles.choiceBtn} mx-3`}
                  onClick={() => {
                    navigate("/cpc/ownerTrader/login");
                  }}
                >
                  <FaStoreAlt />
                </Button>
                <h4 className={`${styles.btnTagName} mt-2`}>صاحب محل</h4>
              </div>
              <div className="text-center">
                <Button
                  variant="danger"
                  size="lg"
                  className={`${styles.choiceBtn} mx-3`}
                  onClick={() => {
                    // navigate("/cpc/stationOwner/login");
                    navigate("/cpc/stationOwner/ComingSoon");
                  }}
                >
                  <BsFillFuelPumpFill />
                </Button>
                <h4 className={`${styles.btnTagName} mt-2`}>صاحب محطة</h4>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="mb-5 mb-md-0">
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <img
                src={require("../../imgs/CPC WAYAK Logo-1.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPicc}`}
              />
              <img
                src={require("../../imgs/cpc-reg-form.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPic}`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
