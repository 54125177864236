import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import styles from "./CustomerPagination.module.scss";

export default function Pagination({
  totalPosts,
  postsPerPage,
  onChangePage,
  currentPage,
}) {
  let pages = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }

  return (
    <div className="text-center">
      {pages.map((page, index) => {
        return (
          <Button
            variant="danger"
            size="sm"
            key={index}
            className={`${styles.pageBtn} m-2 ${
              currentPage === index + 1 && styles.activePageBtn
            }`}
            onClick={() => {
              onChangePage(page);
            }}
          >
            {page}
          </Button>
        );
      })}
    </div>
  );
}
