import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./CustomerCashout.module.scss";
import CustomerWallet from "../cashout/wallet/CustomerWallet";
import CustomerRefcode from "../cashout/refcode/CustomerRefcode";
import CustomerBankAccount from "../cashout/bankaccount/CustomerBankAccount";
import CustomerBankCard from "../cashout/bankcard/CustomerBankCard";
import { useTranslation } from "react-i18next";
import CustomerCashTerms from "./wallet/terms/CustomerCashTerms";
import CustomerBankCardd from "./bankcardd/CustomerBankCardd";

export default function CustomerCashout() {
  const [showOption, setShowOption] = useState(1);

  const [termsMessage, setTermsMessage] = useState(true);

  const { t } = useTranslation();
  return (
    <div className={`${styles.cashoutContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h4 className={`${styles.redeemCashoutTitle}`}>{t("cashnote")}</h4>
          </Col>
          <Col xs={12}>
            <Container>
              <Row>
                <Col xs={6} sm={6} md={4} lg={3} className="mb-2 text-center">
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.cashoutChoiceBtn} me-2 ${
                      showOption === 1 && styles.cashoutChoiceBtnActive
                    }`}
                    onClick={() => {
                      setShowOption(1);
                    }}
                  >
                    {t("ewallet")}
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} className="mb-2 text-center">
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.cashoutChoiceBtn} me-2 ${
                      showOption === 2 && styles.cashoutChoiceBtnActive
                    }`}
                    onClick={() => {
                      setShowOption(2);
                    }}
                  >
                    {t("bankAccounts")}
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} className="mb-2 text-center">
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.cashoutChoiceBtn} me-2 ${
                      showOption === 3 && styles.cashoutChoiceBtnActive
                    }`}
                    onClick={() => {
                      setShowOption(3);
                    }}
                  >
                    {t("bankCards")}
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} className="mb-2 text-center">
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.cashoutChoiceBtn} me-2 ${
                      showOption === 5 && styles.cashoutChoiceBtnActive
                    }`}
                    onClick={() => {
                      setShowOption(5);
                    }}
                  >
                    {t("refCode")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12}>
            {showOption === 1 ? (
              <CustomerWallet />
            ) : showOption === 2 ? (
              <CustomerBankAccount />
            ) : showOption === 3 ? (
              <CustomerBankCard />
            ) : (
              showOption === 5 && <CustomerRefcode />
            )}
          </Col>
        </Row>
      </Container>
      <CustomerCashTerms
        show={termsMessage}
        onHide={() => {
          setTermsMessage(false);
        }}
      />
    </div>
  );
}
