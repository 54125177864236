import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { BsShopWindow } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import i18next from "i18next";
import styles from "./OwnerLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function OwnerLandingPage() {
  const handleToggle = () => {
    var el = document.getElementById("wrapper");
    el.classList.toggle("toggled");
  };

  const handleCloseMenu = () => {
    var e2 = document.getElementById("wrapper");
    e2.classList.remove("toggled");
  };

  const { merchantID } = useParams();
  const { activatorID } = useParams();

  const [lang, setLang] = useState("en");

  const roleName = useSelector((state) => state.cpc.user.login.roleName);

  useEffect(() => {
    if (roleName === "") {
      import("./style.css");
    }
  }, [roleName]);

  useEffect(() => {
    if (lang === "ar") {
      import("bootstrap/dist/css/bootstrap.css");
      import("bootstrap/dist/css/bootstrap.rtl.css");
    }
  }, [lang]);

  const { t } = useTranslation();

  return (
    <div className={`${styles.ownerLandingPageContent}`}>
      <div className="d-flex" id="wrapper">
        <div id="sidebar-wrapper">
          <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom">
            Owner Dashboard
          </div>
          <div className="list-group list-group-flush my-3">
            <NavLink
              to={`/activator/${activatorID}/merchant/${merchantID}/activate`}
              className={`${styles.listItem} list-group-item mb-3`}
              onClick={() => {
                handleCloseMenu();
              }}
            >
              <BsShopWindow className={`${styles.listIcon} mx-3`} />
              {t("ownerDetails")}
            </NavLink>
            {lang === "ar" ? (
              <Button
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  i18next.changeLanguage("en");
                  document.body.dir = "ltr";
                  setLang("en");
                }}
              >
                <MdLanguage className={`${styles.listIcon} mx-3`} />
                English
              </Button>
            ) : (
              lang === "en" && (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("ar");
                    document.body.dir = "rtl";
                    setLang("ar");
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} mx-3`} />
                  العربية
                </Button>
              )
            )}
          </div>
        </div>

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-3 px-4">
            <div className="d-flex align-items-center">
              <FiMenu
                id="menu-toggle"
                className={`${styles.menuIcon} me-3`}
                onClick={() => {
                  handleToggle();
                }}
              />
            </div>
          </nav>
          <div className={`${styles.adminContent} p-3`}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
