import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { QrScanner } from "@yudiel/react-qr-scanner";
import styles from "./Qrscan.module.scss";

export default function Qrscan(props) {
  const [result, setResult] = useState("");
  // const handleScan = (result, error) => {
  //   if (!!result) {
  //     let string = result?.text;
  //     let obj = JSON.parse(string);
  //     props.setfinalresult(obj);
  //     props.onHide();
  //   }
  // };

  useEffect(() => {
    if (result !== "") {
      props.handlefinalresult(result);
    }
  }, [result]);


  const handleScan = (result, error) => {
    if (!!result) {
      setResult(result?.text);
      props.onHide();
    }
  };

  return (
    <div className={`${styles.QrscanContent}`}>
      <Modal show={props.show} onHide={props.onHide} size="md" centered>
        <Modal.Body className={`${styles.qrCodeModal}`}>
          <QrReader
            scanDelay={500}
            className={`${styles.qrVideo}`}
            onResult={handleScan}
            constraints={{ facingMode: "environment" }}
          />
          {/* <p className={`${styles.qrResult}`}>{result}</p> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
