import React from "react";
import styles from "./StationOwnerSoon.module.scss";

export default function StationOwnerSoon() {
  return (
    <div className={`${styles.StationOwnerSoonContent}`}>
      <img src={require("../../../../imgs/owner img.jpeg")} className={`${styles.ownerImg}`}/>
      <img
        src={require("../../../../imgs/soon logo.jpeg")}
        className={`${styles.soonLogo}`}
      />
    </div>
  );
}
