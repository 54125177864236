import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./Terms.module.scss";

export default function Terms(props) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.termsContent}`}>
      <Modal {...props} size="md" centered backdrop="static" keyboard={false}>
        <Modal.Body className={`${styles.giftPointBody}`}>
          <h4>{t("giftTerms")}</h4>
          <div className={`${styles.cashNotes} p-3`}>
            <ul>
              <li className="mb-2">{t("giftTerms1")}</li>
              <li className="mb-2">{t("giftTerms2")}</li>
              <li>{t("giftTerms3")}</li>
            </ul>
          </div>
          <div className="text-center mt-3">
            <Button
              size="md"
              variant="primary"
              className={`${styles.okBtn}`}
              onClick={() => {
                props.onHide();
              }}
            >
              {t("accept")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
