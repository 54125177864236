import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { handleLogout } from "../../../../redux/features/userSlice";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "./AreaManagerStationsDetails.module.scss";

export default function AreaManagerStationsDetails() {
  const { stationID } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [details, setDetails] = useState(null);

  console.log(stationID);

  const token = useSelector((state) => state.cpc.user.login.token);
  const areaID = useSelector((state) => state.cpc.user.login.areaID);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/stations", {
        params: {
          governorateID: "",
          cityID: "",
          AreaID: areaID,
          officeID: "",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.statusCode === 200) {
          const targetStation = res.data.data.filter(
            (sat) => sat["station_id"] == stationID
          );
          console.log(targetStation);
          setDetails(targetStation[0]);
        }
        if (res.data.statusCode === 404) {
          navigate("/manager/area/dashboard/stations");
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [details]);

  return (
    <div className={`${styles.areaManagerStationsDetailsContent} p-1`}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h3 className={`${styles.teamTitle}`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/manager/area/dashboard/stations");
                }}
              />
              {details !== null && details["station_name"]}
            </h3>
          </Col>
        </Row>
        {details !== null && (
          <Row className="mt-4">
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("areaName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["stationOffice"]["officeArea"]["area_name_ar"]}`
                    : `${details["stationOffice"]["officeArea"]["area_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("officeName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["stationOffice"]["office_name_ar"]}`
                    : `${details["stationOffice"]["office_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("goverName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["governorate"]["governorate_name_ar"]}`
                    : `${details["governorate"]["governorate_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("cityName")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {i18next.language === "ar"
                    ? `${details["city"]["city_name_ar"]}`
                    : `${details["city"]["city_name_en"]}`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>
                  {t("stationEmail")}
                </h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${
                    details["stationOffice"]["reportUser"] == null
                      ? "لا يوجد"
                      : details["stationOffice"]["reportUser"]["email"]
                  }`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>
                  {t("stationPhoneNumber")}
                </h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${
                    details["stationOffice"]["reportUser"] == null
                      ? "لا يوجد"
                      : details["stationOffice"]["reportUser"]["phone"]
                  }`}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>{t("stationCode")}</h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${
                    details["stationOffice"]["reportUser"] == null
                      ? "لا يوجد"
                      : details["stationOffice"]["reportUser"]["NID"]
                  }`}{" "}
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 p-2">
              <div className={`${styles.detailsElement} px-3 py-3`}>
                <h6 className={`${styles.detailsTitle}`}>
                  {t("stationAddress")}
                </h6>
                <p className={`${styles.detailsSubTitle} mb-0`}>
                  {`${details["address_line"]}`}
                </p>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
