import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./GiftPoint.module.scss";
import { useTranslation } from "react-i18next";

export default function GiftPoint(props) {
  const [gifts, setGifts] = useState("");

  const { t } = useTranslation();

  return (
    <div className={`${styles.giftPointContent}`}>
      <Modal {...props} size="sm" centered>
        <Modal.Body className={`${styles.giftPointBody}`}>
          <h4>{t("congrats")}</h4>
          <p className="my-3">{`${t("youHaveEarned")} ${props.points} ${t(
            "points"
          )}`}</p>
          <div className="d-grid">
            <Button
              size="sm"
              variant="primary"
              className={`${styles.okBtn}`}
              onClick={() => {
                props.onHide();
              }}
            >
              {t("ok")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
