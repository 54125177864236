import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import i18next from "i18next";
import Pagination from "../../../pagination/Pagination";
import Cookies from "js-cookie";
import styles from "./CashRewards.module.scss";

export default function CashRewards(props) {
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [showOption, setShowOption] = useState(1);
  const [vouchers, setVouchers] = useState([]);
  const [total, setTotal] = useState("");

  let cashRewards = props.cashrewards;

  useEffect(() => {
    if (showOption === 1) {
      let requestedVouchers = cashRewards.filter(
        (vouch) =>
          vouch["settled"] === 0 ||
          (vouch["settled"] === 1 && vouch["vendorTrxStatus"] === "pending")
      );
      setVouchers(requestedVouchers);
      setTotal(requestedVouchers.length);
    } else if (showOption === 2) {
      let successfulVouhcers = cashRewards.filter(
        (vouch) => vouch["vendorTrxStatus"] === "successful"
      );
      setVouchers(successfulVouhcers);
      setTotal(successfulVouhcers.length);
    } else {
      let rejectedVouchers = cashRewards.filter(
        (vouch) =>
          vouch["vendorTrxStatus"] === "rejected" ||
          vouch["vendorTrxStatus"] === "returned"
      );
      setVouchers(rejectedVouchers);
      setTotal(rejectedVouchers.length);
    }
  }, [showOption]);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  const currentPosts = vouchers.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * postsPerPage) % vouchers.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };

  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % vouchers.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });


  return (
    <div className={`${styles.giftCardsParent} p-4`}>
      <h3 className={`${styles.teamTitle} mb-3`}>السحب النقدي</h3>
      <div>
        <div
          className={`${styles.fuelTypeContent} mb-4 d-flex justify-content-around`}
        >
          <Button
            variant="primary"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 1 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(1);
            }}
          >
            مطلوب
          </Button>
          <Button
            variant="primary"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 2 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(2);
            }}
          >
            ناجح
          </Button>
          <Button
            variant="primary"
            size="md"
            className={`${styles.fuelTypeBtn} mb-2 me-2 ${
              showOption === 3 && styles.fuelTypeActiveBtn
            }`}
            onClick={() => {
              setPage(1);
              setShowOption(3);
            }}
          >
            مرفوض
          </Button>
        </div>
      </div>
      <div className="mb-4 d-flex flex-wrap justify-content-center align-items-center">
        {currentPosts.map((reward, index) => {
          return (
            <div className={`${styles.giftCard} m-2 p-3`} key={index}>
              <div className={`${styles.giftCardInfo} w-100`}>
                <div className={`${styles.giftCardImg} me-2`}>
                  <img src={require("../../../../imgs/Cashout.png")} />
                </div>
                <div className={`${styles.giftCardDetails}`}>
                  <p>سحب نقدي</p>
                  <p>محافظ الكترونية</p>
                </div>
                <div className={`${styles.giftCardDetails}`}>
                  <p>{reward.amount} جنيه</p>
                  <p>فودافون كاش</p>
                </div>
              </div>
              {reward["settled"] === 0 && (
                <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                  <div className={`${styles.notSettledStatus} px-4 py-1`}>
                    سيتم ارسال المبلغ الشهر القادم
                  </div>
                </div>
              )}
              {reward["settled"] === 1 &&
                reward["vendorTrxStatus"] === "pending" && (
                  <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                    <div className={`${styles.notSettledStatus} px-4 py-1`}>
                      سيتم ارسال المبلغ الشهر القادم
                    </div>
                  </div>
                )}
              {reward["vendorTrxStatus"] === "successful" && (
                <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                  <div
                    className={`${styles.successfulStatusContent} px-4 py-1`}
                  >
                    عملية مقبولة، تم ارسال المبلغ بنجاح
                  </div>
                </div>
              )}
              {reward["vendorTrxStatus"] === "rejected" && (
                <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                  <div className={`${styles.rejectedStatusContent} px-4 py-1`}>
                    العملية مرفوضة، تم أرجاع مبلغ النقاط
                  </div>
                </div>
              )}
              {reward["vendorTrxStatus"] === "returned" && (
                <div className={`${styles.giftCardBtns} mt-3 w-100`}>
                  <div className={`${styles.rejectedStatusContent} px-4 py-1`}>
                    العملية مرفوضة، تم أرجاع مبلغ النقاط
                  </div>
                </div>
              )}
              {reward["vendorTrxStatus"] === "returned" ||
                (reward["vendorTrxStatus"] === "rejected" && (
                  <div className={`${styles.errorMessageRejected} mt-2`}>
                    {i18next.language === "en"
                      ? reward["vendorStatusDescription"]
                      : reward["vendorStatusDescriptionAr"]}
                  </div>
                ))}
            </div>
          );
        })}
      </div>
      <Pagination
        totalPosts={total}
        postsPerPage={postsPerPage}
        onChangePage={handlePageClick}
        currentPage={itemOffset}
      />
    </div>
  );
}
