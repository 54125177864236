import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const sendGift = createAsyncThunk(
  "trader/sendGift",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/redeem/OrderVoucher",
        {
          ProductId: data.ProductId,
          enteredPassword: data.enteredPassword,
          value: data.value,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const cashoutGift = createAsyncThunk(
  "trader/cashout",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/CPC/redeem/cashout",
        {
          cashoutAmount: data.cashAmount,
          enteredPassword: data.password,
          walletName: data.walletName,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "trader/change/password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/CPC/changePassword",
        {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {};

export const TraderSlice = createSlice({
  name: "trader",
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = TraderSlice.actions;

export default TraderSlice.reducer;
