import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import i18next from "i18next";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import styles from "./Station.module.scss";
import axios from "axios";
import { HiFilter } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import Pagination from "../../pagination/Pagination";
import { handleLogout } from "../../../redux/features/userSlice";
import { activateDeactivateStation } from "../../../redux/features/adminSlice";

export default function Station() {
  const [stations, setStations] = useState([]);
  const [allStations, setAllStations] = useState([]);
  const [page, setPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [total, setTotal] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [stationStatus, setStationStatus] = useState(2);

  const [message, setMessage] = useState("");
  const [messageAr, setMessageAr] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/Stations", {
        params: {
          // sort: "desc",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setStations(res.data.data);
        }
        if (res.data.statusCode === 404) {
          setMessage(res.data.message);
          setMessageAr(res.data.messageAr);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/fuelStation/Operators", {
        params: {
          // sort: "desc",
          // sortBy: "date",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          let arrangedStations = res.data.data.sort((a, b) =>
            b["station_id"] > a["station_id"] ? 1 : -1
          );
          if (stationStatus == 1) {
            let activeStations = arrangedStations.filter(
              (station) =>
                station["stationStatus"] === 1 &&
                station["station_name"]
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
            );
            setAllStations(activeStations);
            setTotal(activeStations.length);
          } else if (stationStatus == 0) {
            let notActiveStations = arrangedStations.filter(
              (station) =>
                station["stationStatus"] === 0 &&
                station["station_name"]
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
            );
            setAllStations(notActiveStations);
            setTotal(notActiveStations.length);
          } else {
            let reArrangedStations = arrangedStations.filter((station) =>
              station["station_name"]
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            );
            setAllStations(reArrangedStations);
            setTotal(reArrangedStations.length);
          }
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [stationStatus, searchValue]);

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + postsPerPage;
  const currentPosts = allStations.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * postsPerPage) % allStations.length;
    setItemOffset(newOffset);
    Cookies.set("page", event.selected);
  };

  useEffect(() => {
    if (Cookies.get("page")) {
      const newOffsett =
        (Number(Cookies.get("page")) * postsPerPage) % allStations.length;
      setItemOffset(newOffsett);
    } else {
      setItemOffset(0);
    }
  });

  return (
    <div className={`${styles.stationContent} p-1`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              {t("theStations")}
            </h3>
          </Col>
          <Col xs={12} md={6}>
            <div className={`${styles.btnRight}`}>
              <Button
                size="md"
                variant="secondary"
                className={`${styles.reportBtn} me-2 mb-2`}
                onClick={() => {
                  navigate("/admin/dashboard/stations/report");
                }}
              >
                {t("stationReport")}
              </Button>
              <Button
                size="md"
                variant="primary"
                className="mb-2"
                onClick={() => {
                  navigate("/admin/dashboard/stations/add");
                }}
              >
                {t("addStation")}
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
              <div className={`${styles.filterForm} mb-3 mb-lg-0`}>
                <Formik
                  initialValues={{ stationStatus: "" }}
                  onSubmit={(values) => {
                    setStationStatus(values.stationStatus);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.filterInput}`}>
                          <InputGroup.Text className={`${styles.filterLabel}`}>
                            <HiFilter />
                          </InputGroup.Text>
                          <Form.Select
                            size="sm"
                            name="stationStatus"
                            id="stationStatus"
                            onChange={(e) => {
                              setItemOffset(0);
                              setFieldValue(
                                "stationStatus",
                                e.currentTarget.value
                              );
                              handleSubmit();
                            }}
                            className={`${styles.filterSelect}`}
                            value={values.stationStatus}
                          >
                            <option value={2}>{t("allStations")}</option>
                            <option value={1}>{t("active")}</option>
                            <option value={0}>{t("notActive")}</option>
                          </Form.Select>
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <div className={`${styles.searchForm}`}>
                <Formik
                  initialValues={{ searchValue: "" }}
                  onSubmit={(values) => {}}
                >
                  {({ values, touched, handleChange, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputGroup className={`${styles.searchInput}`}>
                          <InputGroup.Text className={`${styles.searchLabel}`}>
                            <BiSearch />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("search")}
                            className={`${styles.searchField}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => {
                              if (page !== 1) {
                                setPage(1);
                              }
                              setSearchValue(e.currentTarget.value);
                            }}
                          />
                        </InputGroup>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          {message !== "" && messageAr !== "" && (
            <Col xs={12} className="mb-3">
              <div>{i18next.language === "ar" ? messageAr : message}</div>
            </Col>
          )}
          {currentPosts.map((station, index) => {
            return (
              <Col xs={12} key={index}>
                {station["stationStatus"] === 1 ? (
                  <div className={`${styles.bgGrey} px-4 py-3 mb-3`}>
                    <div
                      className={`${styles.activatorInfoContent} d-flex align-items-center`}
                    >
                      <img
                        src={require("../../../imgs/fuellogo.jpeg")}
                        className="me-2"
                      />
                      <div className={`${styles.activatorInfo} mt-2`}>
                        <h5>{station["station_name"]}</h5>
                        <p dir="rtl">{station["address_line"]}</p>
                        {/* <span className="mt-3">01121830506</span> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.activatorBtnContent}  h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
                    >
                      <Button
                        variant="primary"
                        className={`${styles.detailsBtn} mx-1`}
                        onClick={() => {
                          navigate(
                            `/admin/dashboard/stations/stationDetails/${station["station_id"]}`
                          );
                        }}
                      >
                        {t("stationDetails")}
                      </Button>
                      <Button
                        variant="success"
                        className={`${styles.editBtn} mx-1`}
                        onClick={() => {
                          navigate(
                            `/admin/dashboard/stations/edit/${station["station_id"]}`
                          );
                        }}
                      >
                        {t("editStation")}
                      </Button>
                      <Button
                        variant="danger"
                        className={`${styles.removeBtn} mx-1`}
                        onClick={() => {
                          dispatch(
                            activateDeactivateStation({
                              token,
                              stationID: station["station_id"],
                              status: 0,
                            })
                          ).then((res) => {
                            const loadReq = toast.loading(t("loading"), {
                              transition: Zoom,
                              position:
                                i18next.language == "ar"
                                  ? "bottom-right"
                                  : "bottom-left",
                            });
                            setTimeout(() => {
                              window.location.reload();
                            }, 3000);
                            if (res.payload.data.statusCode === 200) {
                              successMessage(
                                res.payload.data.message,
                                res.payload.data.messageAr,
                                loadReq
                              );
                            } else if (res.payload.data.statusCode === 401) {
                              dispatch(handleLogout());
                              navigate("/cpc/login");
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            } else {
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            }
                          });
                        }}
                      >
                        {t("deactivate")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={`${styles.bgGreyNotActive} px-4 py-3 mb-3`}>
                    <div
                      className={`${styles.activatorInfoContent} d-flex align-items-center`}
                    >
                      <img
                        src={require("../../../imgs/fuellogo.jpeg")}
                        className="me-2"
                      />
                      <div className={`${styles.activatorInfo} mt-2`}>
                        <h5>{station["station_name"]}</h5>
                        <p dir="rtl">{station["address_line"]}</p>
                        {/* <span className="mt-3">01121830506</span> */}
                      </div>
                    </div>
                    <div
                      className={`${styles.activatorBtnContent}  h-100 d-flex justify-content-left justify-content-md-center align-items-center flex-wrap mt-2`}
                    >
                      {" "}
                      <Button
                        variant="primary"
                        className={`${styles.detailsBtn} mx-1`}
                        onClick={() => {
                          navigate(
                            `/admin/dashboard/stations/stationDetails/${station["station_id"]}`
                          );
                        }}
                      >
                        {t("stationDetails")}
                      </Button>
                      <Button
                        variant="success"
                        className={`${styles.editBtn} mx-1`}
                        onClick={() => {
                          navigate(
                            `/admin/dashboard/stations/edit/${station["station_id"]}`
                          );
                        }}
                      >
                        {t("editStation")}
                      </Button>
                      <Button
                        variant="secondary"
                        className={`${styles.removeBtn} mx-1`}
                        onClick={() => {
                          dispatch(
                            activateDeactivateStation({
                              token,
                              stationID: station["station_id"],
                              status: 1,
                            })
                          ).then((res) => {
                            const loadReq = toast.loading(t("loading"), {
                              transition: Zoom,
                              position:
                                i18next.language == "ar"
                                  ? "bottom-right"
                                  : "bottom-left",
                            });
                            setTimeout(() => {
                              window.location.reload();
                            }, 3000);
                            if (res.payload.data.statusCode === 200) {
                              successMessage(
                                res.payload.data.message,
                                res.payload.data.messageAr,
                                loadReq
                              );
                            } else if (res.payload.data.statusCode === 401) {
                              dispatch(handleLogout());
                              navigate("/cpc/login");
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            } else {
                              failedMessage(
                                res.payload.data.error,
                                res.payload.data.errorAr,
                                loadReq
                              );
                            }
                          });
                        }}
                      >
                        {t("activate")}
                      </Button>
                    </div>
                  </div>
                )}
              </Col>
            );
          })}
          <Col xs={12}>
            <Pagination
              totalPosts={total}
              postsPerPage={postsPerPage}
              onChangePage={handlePageClick}
              currentPage={itemOffset}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
