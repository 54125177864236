import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Badge, Button } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { BiStoreAlt } from "react-icons/bi";
import { FaRegNewspaper } from "react-icons/fa";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { FiMenu } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { RiTeamLine } from "react-icons/ri";
import { BsShopWindow } from "react-icons/bs";
import { FiShoppingBag } from "react-icons/fi";
import { MdLanguage } from "react-icons/md";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { BsFuelPumpFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../../redux/features/userSlice";
import styles from "./Home.module.scss";
import i18next from "i18next";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";

export default function Home() {
  const handleToggle = () => {
    var el = document.getElementById("wrapper");
    el.classList.toggle("toggled");
  };

  const handleCloseMenu = () => {
    var e2 = document.getElementById("wrapper");
    e2.classList.remove("toggled");
  };

  const role = useSelector((state) => state.cpc.user.login.roleName);
  const businessName = useSelector(
    (state) => state.cpc.user.login.businessName
  );
  const cityName = useSelector((state) => state.cpc.user.login.cityName);
  const goverName = useSelector((state) => state.cpc.user.login.goverName);
  const token = useSelector((state) => state.cpc.user.login.token);
  const userID = useSelector((state) => state.cpc.user.login.userID);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [lang, setLang] = useState(Cookies.get("i18next"));

  const roleName = useSelector((state) => state.cpc.user.login.roleName);

  useEffect(() => {
    if (lang === "ar") {
      import("bootstrap/dist/css/bootstrap.css");
      import("bootstrap/dist/css/bootstrap.rtl.css");
    }
  }, [lang]);

  useEffect(() => {
    if (roleName !== "Consumer") {
      import("./Menu.css");
    }
  }, [roleName]);

  const { t, i18n } = useTranslation();

  const [activatorInfo, setAcitvatorInfo] = useState({
    firstName: "",
    lastName: "",
    districts: [],
  });

  const goverNameAr = useSelector((state) => state.cpc.user.login.goverNameAr);
  const goverNameEn = useSelector((state) => state.cpc.user.login.goverNameEn);
  const cityNameAr = useSelector((state) => state.cpc.user.login.cityNameAr);
  const cityNameEn = useSelector((state) => state.cpc.user.login.cityNameEn);
  const activatorFirstName = useSelector(
    (state) => state.cpc.user.login.activatorFirstName
  );

  const areaName = useSelector((state) => state.cpc.user.login.areaName);
  const officeName = useSelector((state) => state.cpc.user.login.officeName);

  return (
    <div className={`${styles.adminHome}`}>
      <div className="d-flex" id="wrapper">
        {role === "Admin" ? (
          <div id="sidebar-wrapper" className="sidebar-wrapper-all">
            <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom">
              {t("adminDash")}
            </div>
            <div className="list-group list-group-flush my-3">
              <NavLink
                to="/admin/dashboard/team"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  Cookies.remove("page");
                  handleCloseMenu();
                }}
              >
                <RiTeamLine className={`${styles.listIcon} me-3`} />
                {t("team")}
              </NavLink>
              <NavLink
                to="/admin/dashboard/merchants/all"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  Cookies.remove("page");

                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                {t("Merchants")}
              </NavLink>
              <NavLink
                to="/admin/dashboard/products"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  Cookies.remove("page");

                  handleCloseMenu();
                }}
              >
                <FiShoppingBag className={`${styles.listIcon} me-3`} />
                {t("products")}
              </NavLink>
              <NavLink
                to="/admin/dashboard/stations"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  Cookies.remove("page");
                  handleCloseMenu();
                }}
              >
                <BsFillFuelPumpFill className={`${styles.listIcon} me-3`} />
                {t("stations")}
              </NavLink>
              {lang === "ar" ? (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    document.body.dir = "ltr";
                    setLang("en");
                    handleCloseMenu();
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} me-3`} />
                  English
                </Button>
              ) : (
                lang === "en" && (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("ar");
                      document.body.dir = "rtl";
                      setLang("ar");
                      handleCloseMenu();
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    العربية
                  </Button>
                )
              )}
              <Button
                variant="danger"
                className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                onClick={() => {
                  dispatch(handleLogout());
                  navigate("/cpc/login");
                }}
              >
                <BiLogOut className="me-2 fs-5" />
                Logout
              </Button>
            </div>
          </div>
        ) : role === "Activator" ? (
          <div id="sidebar-wrapper" className="sidebar-wrapper-all">
            <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom d-flex justify-content-center align-items-center flex-column">
              <h6>Activator Dashboard</h6>
              <div className={`${styles.traderProfile}`}>
                <FaUserTie />
              </div>
              <p className={`${styles.businessName} mt-2 mb-1`}>
                {`${activatorFirstName}`}
              </p>
              {/* {activatorInfo.districts.map((dis, index) => {
                return (
                  <span className={`${styles.location}`} key={index}>
                    {`${dis["governorate"]["governorate_name_en"]}, ${dis["city"]["city_name_en"]}`}
                  </span>
                );
              })} */}
            </div>
            <div className="list-group list-group-flush my-3">
              <NavLink
                to="/activator/dashboard/shops"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                {t("createBranches")}
              </NavLink>
              <NavLink
                to="/activator/dashboard/qrcodes"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                {t("activatedMerchants")}
              </NavLink>
              {lang === "ar" ? (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    document.body.dir = "ltr";
                    setLang("en");
                    handleCloseMenu();
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} me-3`} />
                  English
                </Button>
              ) : (
                lang === "en" && (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("ar");
                      document.body.dir = "rtl";
                      setLang("ar");
                      handleCloseMenu();
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    العربية
                  </Button>
                )
              )}
              <Button
                variant="danger"
                className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                onClick={() => {
                  dispatch(handleLogout());
                  navigate("/cpc/login");
                }}
              >
                <BiLogOut className="me-2 fs-5" />
                Logout
              </Button>
            </div>
          </div>
        ) : role === "CPCArea_Manager" ? (
          <div id="sidebar-wrapper" className="sidebar-wrapper-all">
            <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom d-flex justify-content-center align-items-center flex-column">
              <h6>شاشة فريق الحسابات</h6>
              <div className={`${styles.traderProfile}`}>
                <BiStoreAlt />
              </div>
              <p className={`${styles.businessName} mt-2 mb-1`}>
                {i18next.language == "ar"
                  ? `اسم المنطقة : ${areaName["area_name_ar"]}`
                  : `Area : ${areaName["area_name_en"]}`}
              </p>
            </div>
            <div className="list-group list-group-flush my-3">
              <NavLink
                to="/manager/area/dashboard/stations"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                محطات الوقود
              </NavLink>
              {lang === "ar" ? (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    document.body.dir = "ltr";
                    setLang("en");
                    handleCloseMenu();
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} me-3`} />
                  English
                </Button>
              ) : (
                lang === "en" && (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("ar");
                      document.body.dir = "rtl";
                      setLang("ar");
                      handleCloseMenu();
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    العربية
                  </Button>
                )
              )}
              <Button
                variant="danger"
                className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                onClick={() => {
                  dispatch(handleLogout());
                  navigate("/cpc/login");
                }}
              >
                <BiLogOut className="me-2 fs-5" />
                {t("logout")}
              </Button>
            </div>
          </div>
        ) : role === "CPCOffice_Manager" ? (
          <div id="sidebar-wrapper" className="sidebar-wrapper-all">
            <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom d-flex justify-content-center align-items-center flex-column">
              <h6>شاشة فريق الحسابات</h6>
              <div className={`${styles.traderProfile}`}>
                <BiStoreAlt />
              </div>
              <p className={`${styles.businessName} mt-2 mb-1`}>
                {i18next.language == "ar"
                  ? `اسم المكتب : ${officeName["office_name_ar"]}`
                  : `Office : ${officeName["office_name_en"]}`}
              </p>
            </div>
            <div className="list-group list-group-flush my-3">
              <NavLink
                to="/manager/office/dashboard/stations"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                محطات الوقود
              </NavLink>
              {lang === "ar" ? (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    document.body.dir = "ltr";
                    setLang("en");
                    handleCloseMenu();
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} me-3`} />
                  English
                </Button>
              ) : (
                lang === "en" && (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("ar");
                      document.body.dir = "rtl";
                      setLang("ar");
                      handleCloseMenu();
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    العربية
                  </Button>
                )
              )}
              <Button
                variant="danger"
                className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                onClick={() => {
                  dispatch(handleLogout());
                  navigate("/cpc/login");
                }}
              >
                <BiLogOut className="me-2 fs-5" />
                {t("logout")}
              </Button>
            </div>
          </div>
        ) : role === "Owner" ? (
          <div id="sidebar-wrapper" className="sidebar-wrapper-all">
            <div className="sidebar-heading text-center py-4 primary-text fw-bold border-bottom d-flex justify-content-center align-items-center flex-column">
              <h6>{t("ownerDash")}</h6>
              <div className={`${styles.traderProfile}`}>
                <BiStoreAlt />
              </div>
              <p className={`${styles.businessName} mt-2 mb-1`}>
                {businessName}
              </p>
              <span className={`${styles.location}`}>
                {i18next.language == "ar" ? (
                  <h5>{`${goverNameAr}, ${cityNameAr}`}</h5>
                ) : (
                  <h5>{`${goverNameEn}, ${cityNameEn}`}</h5>
                )}
              </span>
              <Button
                size="sm"
                variant="primary"
                className={`${styles.changePasswordBtn} mt-2`}
                onClick={() => {
                  navigate("/owner/dashboard/change/password");
                  handleCloseMenu();
                }}
              >
                {t("changePassword")}
              </Button>
            </div>
            <div className="list-group list-group-flush my-3">
              <NavLink
                to="/owner/dashboard/balance"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                {t("pointsBalance")}
              </NavLink>
              <NavLink
                to="/owner/dashboard/details"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <BsShopWindow className={`${styles.listIcon} me-3`} />
                {t("ownerDetails")}
              </NavLink>
              <NavLink
                to="/owner/dashboard/giftCards"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <MdOutlineCardGiftcard className={`${styles.listIcon} me-3`} />
                {t("giftCards")}
              </NavLink>
              {lang === "ar" ? (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    document.body.dir = "ltr";
                    setLang("en");
                    handleCloseMenu();
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} me-3`} />
                  English
                </Button>
              ) : (
                lang === "en" && (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("ar");
                      document.body.dir = "rtl";
                      setLang("ar");
                      handleCloseMenu();
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    العربية
                  </Button>
                )
              )}
              <Button
                variant="danger"
                className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                onClick={() => {
                  dispatch(handleLogout());
                  navigate("/cpc/ownerTrader/login");
                }}
              >
                <BiLogOut className="me-2 fs-5" />
                {t("logout")}
              </Button>
            </div>
          </div>
        ) : role === "Trader" ? (
          <div id="sidebar-wrapper" className="sidebar-wrapper-all">
            <div className="sidebar-heading py-4 primary-text fw-bold border-bottom d-flex justify-content-center align-items-center flex-column">
              <h6>Trader Dashboard</h6>
              <div className={`${styles.traderProfile}`}>
                <BiStoreAlt />
              </div>
              <p className={`${styles.businessName} mt-2 mb-1`}>
                {businessName}
              </p>
              <span className={`${styles.location}`}>
                {i18next.language == "ar" ? (
                  <h5>{`${goverNameAr}, ${cityNameAr}`}</h5>
                ) : (
                  <h5>{`${goverNameEn}, ${cityNameEn}`}</h5>
                )}
              </span>
              <Button
                size="sm"
                variant="primary"
                className={`${styles.changePasswordBtn} mt-2`}
                onClick={() => {
                  handleCloseMenu();
                  navigate("/trader/dashboard/change/password");
                }}
              >
                {t("changePassword")}
              </Button>
            </div>
            <div className="list-group list-group-flush my-3">
              <NavLink
                to="/trader/dashboard/home"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <AiOutlineHome className={`${styles.listIcon} me-3`} />
                {t("home")}
              </NavLink>
              {/* <NavLink
                  to="/trader/dashboard/details"
                  className={`${styles.listItem} list-group-item mb-3`}
                >
                  <TbListDetails className={`${styles.listIcon} me-3`} />
                  Details
                </NavLink> */}
              {/* <NavLink
                  to="/trader/dashboard/giftCards"
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    handleCloseMenu();
                  }}
                >
                  <MdOutlineCardGiftcard
                    className={`${styles.listIcon} me-3`}
                  />
                  {t("giftCards")}
                </NavLink> */}
              {/* <NavLink
                  to="/trader/dashboard/report"
                  className={`${styles.listItem} list-group-item mb-3`}
                >
                  <FaRegNewspaper className={`${styles.listIcon} me-3`} />
                  Report
                </NavLink> */}
              {lang === "ar" ? (
                <Button
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    i18next.changeLanguage("en");
                    document.body.dir = "ltr";
                    setLang("en");
                  }}
                >
                  <MdLanguage className={`${styles.listIcon} me-3`} />
                  English
                </Button>
              ) : (
                lang === "en" && (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("ar");
                      document.body.dir = "rtl";
                      setLang("ar");
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    العربية
                  </Button>
                )
              )}
              <Button
                variant="danger"
                className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                onClick={() => {
                  dispatch(handleLogout());
                  navigate("/cpc/ownerTrader/login");
                }}
              >
                <BiLogOut className="me-2 fs-5" />
                {t("logout")}
              </Button>
            </div>
          </div>
        ) : (
          role === "stationOwner" && (
            <div id="sidebar-wrapper" className="sidebar-wrapper-all">
              <div className="sidebar-heading py-4 primary-text fw-bold border-bottom d-flex justify-content-center align-items-center flex-column">
                <h6 className={`${styles.stationOwnerTitle}`}>
                  {t("StationOwnerDash")}
                </h6>
                <div className={`${styles.traderProfile}`}>
                  <BsFuelPumpFill />
                </div>
                {/* <p className={`${styles.businessName} mt-2 mb-1`}>
                  {businessName}
                </p> */}
                {/* <span className={`${styles.location}`}>
                  {i18next.language == "ar" ? (
                    <h5>{`${goverNameAr}, ${cityNameAr}`}</h5>
                  ) : (
                    <h5>{`${goverNameEn}, ${cityNameEn}`}</h5>
                  )}
                </span> */}
                {/* <Button
                  size="sm"
                  variant="primary"
                  className={`${styles.changePasswordBtn} mt-2`}
                  onClick={() => {
                    handleCloseMenu();
                    navigate("/trader/dashboard/change/password");
                  }}
                >
                  {t("changePassword")}
                </Button> */}
              </div>
              <div className="list-group list-group-flush my-3">
                <NavLink
                  to="/stationOwner/dashboard/home"
                  className={`${styles.listItem} list-group-item mb-3`}
                  onClick={() => {
                    handleCloseMenu();
                  }}
                >
                  <AiOutlineHome className={`${styles.listIcon} me-3`} />
                  {t("home")}
                </NavLink>
                {/* <NavLink
                to="/trader/dashboard/details"
                className={`${styles.listItem} list-group-item mb-3`}
              >
                <TbListDetails className={`${styles.listIcon} me-3`} />
                Details
              </NavLink> */}
                {/* <NavLink
                to="/trader/dashboard/giftCards"
                className={`${styles.listItem} list-group-item mb-3`}
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <MdOutlineCardGiftcard
                  className={`${styles.listIcon} me-3`}
                />
                {t("giftCards")}
              </NavLink> */}
                {/* <NavLink
                to="/trader/dashboard/report"
                className={`${styles.listItem} list-group-item mb-3`}
              >
                <FaRegNewspaper className={`${styles.listIcon} me-3`} />
                Report
              </NavLink> */}
                {lang === "ar" ? (
                  <Button
                    className={`${styles.listItem} list-group-item mb-3`}
                    onClick={() => {
                      i18next.changeLanguage("en");
                      document.body.dir = "ltr";
                      setLang("en");
                    }}
                  >
                    <MdLanguage className={`${styles.listIcon} me-3`} />
                    English
                  </Button>
                ) : (
                  lang === "en" && (
                    <Button
                      className={`${styles.listItem} list-group-item mb-3`}
                      onClick={() => {
                        i18next.changeLanguage("ar");
                        document.body.dir = "rtl";
                        setLang("ar");
                      }}
                    >
                      <MdLanguage className={`${styles.listIcon} me-3`} />
                      العربية
                    </Button>
                  )
                )}
                <Button
                  variant="danger"
                  className={`${styles.logoutBtn} mt-5 py-3 px-4`}
                  onClick={() => {
                    dispatch(handleLogout());
                    navigate("/cpc/stationOwner/login");
                  }}
                >
                  <BiLogOut className="me-2 fs-5" />
                  {t("logout")}
                </Button>
              </div>
            </div>
          )
        )}

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-3 px-4">
            <div className="d-flex align-items-center">
              <FiMenu
                id="menu-toggle"
                className={`${styles.menuIcon} me-3`}
                onClick={() => {
                  handleToggle();
                }}
              />
            </div>
          </nav>
          <div className={`${styles.adminContent} p-3`}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
