import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsQrCodeScan } from "react-icons/bs";
import { BsGift } from "react-icons/bs";
import styles from "./TraderHome.module.scss";
import Qrscan from "./qrscan/Qrscan";
import GiftPoint from "./point/GiftPoint";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../../redux/features/userSlice";

export default function TraderHome() {
  const [showQrModal, setShowQrModal] = useState(false);
  const [balance, setBalance] = useState("");
  const [egp, setEgp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handlevouch = (points) => {
  //   setPoints(points);
  //   setShowGift(true);
  // };

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/customer/pointsBalance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setBalance(res.data.pointsBalance);
          setEgp(res.data.pointsBalanceEGP);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
        }
      });
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <div className={`${styles.traderHomeContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>{t("home")}</h3>
          </Col>
          <Col xs={12} md={{ span: 8, offset: 2 }} className="mt-4">
            <div className={`${styles.balanceTableContent} p-3`}>
              <h6>{t("balance")}</h6>
              <Table responsive borderless className="mb-0">
                <thead>
                  <tr>
                    <th className="text-center">{t("points")}</th>
                    <th>{balance}</th>
                  </tr>
                </thead>
              </Table>
            </div>
          </Col>
          <Col xs={12}>
            <div
              className={`${styles.scanQrContent} mt-5 p-2 p-md-5 w-100 d-flex justify-content-center align-items-center`}
            >
              <Button
                variant="light"
                className={`${styles.scanBtn}`}
                onClick={() => {
                  setShowQrModal(true);
                }}
              >
                <BsQrCodeScan />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Qrscan
        show={showQrModal}
        onHide={() => {
          setShowQrModal(false);
        }}
      />
    </div>
  );
}
