import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { handleLogout } from "../../../redux/features/userSlice";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userAcceptotp } from "../../../redux/features/userSlice";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import styles from "./VerifyEmail.module.scss";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function VerifyEmail(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.email === "") {
      navigate("/cpc/forget/password");
    }
  }, []);

  const verifyEmailValidation = yup.object().shape({
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    newPassword: yup.string().required("New password is required"),
    otp: yup.number().required("OTP is required"),
  });

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className={`${styles.verifyLogin} py-5 px-2 p-md-5`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <h4 className={`${styles.verifyTitle}`}>{t("verifyEmail")}</h4>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`${styles.verifyFormContent} p-4 p-md-5`}>
              <Formik
                initialValues={{ email: props.email, otp: "", newPassword: "" }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading(t("loading"), {
                    transition: Zoom,
                    position:
                      i18next.language == "ar" ? "bottom-right" : "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    userAcceptotp({
                      email: values.email,
                      otp: values.otp,
                      newPassword: values.newPassword,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      navigate(`/cpc/login`);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else if (res.payload.data.statusCode === 401) {
                      dispatch(handleLogout());
                      navigate("/cpc/login");
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={verifyEmailValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.verifyLabel}`}>
                          {t("email")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t("enterEmail")}
                          className={`${styles.verifyInput}`}
                          name="email"
                          id="email"
                          onChange={handleChange}
                          value={values.email}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.verifyLabel}`}>
                          {t("newPass")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("enterNewPass")}
                          className={`${styles.verifyInput}`}
                          name="newPassword"
                          id="newPassword"
                          onChange={handleChange}
                          value={values.newPassword}
                        />
                        {errors.newPassword && touched.newPassword && (
                          <Form.Text className="text-error">
                            {errors.newPassword}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${styles.verifyLabel}`}>
                          {t("verifyOtp")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={6}
                          placeholder={t("enterOtp")}
                          className={`${styles.verifyOTP}`}
                          name="otp"
                          id="otp"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleChange}
                          value={values.otp}
                        />
                        {errors.otp && touched.otp && (
                          <Form.Text className="text-error d-block">
                            {errors.otp}
                          </Form.Text>
                        )}
                        <Form.Text className={`${styles.emailNote}`}>
                          Check your Email, you'll get OTP code 6 digits write
                          it to verify Email.
                        </Form.Text>
                      </Form.Group>
                      <div className="d-grid mt-4">
                        <Button
                          type="submit"
                          variant="primary"
                          size="lg"
                          className={`${styles.verifyBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("confirmOtp")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
