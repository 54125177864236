import React, { useState, useEffect, useTransition } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaExchangeAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styles from "./CustomerRedeem.module.scss";
import Cookies from "js-cookie";
import CustomerGiftCards from "./giftcards/CustomerGiftCards";
import CustomerCashout from "./cashout/CustomerCashout";
import CustomerFuel from "./fuel/CustomerFuel";
import { handleLogout } from "../../../redux/features/userSlice";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function CustomerRedeem() {
  const [showOption, setShowOption] = useState(1);
  const [step, setStep] = useState("");
  const [customerBalance, setCustomerBalance] = useState({
    points: "",
    egp: "",
  });

  const navigate = useNavigate();

  const stepback = (step) => {
    setStep(step);
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.cpc.user.login.token);

  useEffect(() => {
    let type = Cookies.get("type");
    if (type === "redeemFuelVouchers") {
      setShowOption(3);
    }
  }, []);



  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/customer/pointsBalance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCustomerBalance({
            points: res.data.pointsBalance,
            egp: res.data.pointsBalanceEGP,
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/customer/login");
        }
      });
  }, []);

  return (
    <div className={`${styles.redeemPointsContent}`}>
      <Container>
        <Row>
          <Col xs={12} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  if (step == 2) {
                    stepback(1);
                  } else {
                    navigate("/customer/dashboard/home");
                  }
                }}
              />
              {t("redeemPoints")}
            </h3>
          </Col>
          <Col xs={12} sm={12} lg={8} className="align-self-center">
            <Container>
              <Row>
                <Col xs={12} sm={4}>
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.redeemPointsBtn} mb-2 me-2 ${
                      showOption === 1 && styles.redeemPointActiveBtn
                    }`}
                    onClick={() => {
                      setShowOption(1);
                    }}
                  >
                    {t("giftCards")}
                  </Button>
                </Col>
                <Col xs={12} sm={4}>
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.redeemPointsBtn} mb-2 me-2 ${
                      showOption === 2 && styles.redeemPointActiveBtn
                    }`}
                    onClick={() => {
                      setShowOption(2);
                    }}
                  >
                    {t("cashout")}
                  </Button>
                </Col>
                <Col xs={12} sm={4}>
                  <Button
                    variant="primary"
                    size="sm"
                    className={`${styles.redeemPointsBtn} mb-2 me-2 ${
                      showOption === 3 && styles.redeemPointActiveBtn
                    }`}
                    onClick={() => {
                      setShowOption(3);
                    }}
                  >
                    {t("fuel")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} sm={12} lg={4}>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className={`${styles.resultContent} mt-3 d-flex align-items-center`}
              >
                <div>
                  <h5 className="text-center">{t("points")}</h5>
                  <p className="text-center">{customerBalance.points}</p>
                </div>
                <div className="mx-3 text-center">
                  <FaExchangeAlt />
                </div>
                <div>
                  <h5 className="text-center">{t("egp")}</h5>
                  <p className="text-center">{customerBalance.egp}</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            {showOption === 1 ? (
              <CustomerGiftCards stepback={stepback} stepnow={step} />
            ) : showOption === 2 ? (
              <CustomerCashout />
            ) : showOption === 3 ? (
              <CustomerFuel />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
