import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

function makeRandomEmail(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const addShop = createAsyncThunk(
  "activator/add/shop",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        "https://evouchers.shareecoin.com/CPC/create/trader",
        {
          merchantID: data.ownerID,
          districts: data.shopInfo,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const editShop = createAsyncThunk(
  "activator/edit/shop",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `https://evouchers.shareecoin.com/CPC/edit/trader/${data.shopID}`,
        {
          traderData: [
            {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phone: data.phone,
              NID: data.nationalID,
              goveID: data.govID,
              cityId: data.cityID,
              branchName: data.branchName,
              address: data.address,
              addressAr: data.addressAr,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const generateQR = createAsyncThunk(
  "merchant/generate/qrcode",
  async (data, { rejectWithValue }) => {
    try {
      const result = axios.post(
        `https://evouchers.shareecoin.com/CPC/QR/generate/${data.merchantID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const checkMerchantBranches = createAsyncThunk(
  "check/merchant",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `https://evouchers.shareecoin.com/CPC/list-Owner-Traders/${data.merchantID}`
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "delete/branch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `https://evouchers.shareecoin.com/CPC/delete/trader/${data.branchID}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {};

export const activatorSlice = createSlice({
  name: "activator",
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = activatorSlice.actions;

export default activatorSlice.reducer;
