import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import styles from "./CustomerLogin.module.scss";
import { useNavigate } from "react-router-dom";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { customerLoginInfo } from "../../../redux/features/userSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function CustomerLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(Cookies.get("i18next"));

  const customerLoginValidation = yup.object().shape({
    phoneNumber: yup.string().required("رقم الموبايل مطلوب"),
    password: yup.string().required("كلمة السر مطلوبة"),
  });
  useEffect(() => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  }, []);

  window.onload = () => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  };

  let {
    operator_id,
    operator_phoneNumber,
    station_id,
    station_name,
    gov_id,
    city_id,
  } = useParams();


  return (
    <div className={`${styles.CustomerLoginContent} p-2 p-sm-4`}>
      <div className={styles.langBtn}>
        {lang === "en" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("ar");
              Cookies.set("i18next", "ar");
              document.body.dir = "rtl";
              setLang("ar");
            }}
          >
            Arabic
          </Button>
        )}
        {lang === "ar" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("en");
              Cookies.set("i18next", "en");
              document.body.dir = "ltr";
              setLang("en");
            }}
          >
            انجليزية
          </Button>
        )}
      </div>
      <Container fluid>
        <Row className="flex-column-reverse flex-lg-row ">
          <Col
            xs={12}
            lg={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <h5 className={`${styles.cpcRegTitle} mb-4`}>
              برنامج نقاط وياك للمستخدمين
            </h5>
            <div className="p-1 p-sm-2">
              <Formik
                initialValues={{
                  phoneNumber: "",
                  password: "",
                }}
                onSubmit={(values, actions) => {
                  const loadReq = toast.loading(t("loading"), {
                    transition: Zoom,
                    position:
                      i18n.language === "ar" ? "bottom-right" : "bottom-left",
                  });
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    customerLoginInfo({
                      phone: values.phoneNumber,
                      password: values.password,
                      operator_id,
                      operator_phoneNumber,
                      station_id,
                      station_name,
                      gov_id,
                      city_id,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      if (res.payload.data.roleName === "Consumer") {
                        navigate(`/customer/dashboard/home`);
                      }
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.error,
                        res.payload.data.errorAr,
                        loadReq
                      );
                    }
                  });
                }}
                validationSchema={customerLoginValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("mobileNumber")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="text"
                            maxLength={11}
                            placeholder="******01"
                            className={`${styles.regFormInput} ${styles.phoneStyle}`}
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.phoneNumber}
                          />
                        </Col>
                        {errors.phoneNumber && touched.phoneNumber && (
                          <Form.Text className="text-error">
                            {errors.phoneNumber}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("password")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="password"
                            maxLength={4}
                            className={`${styles.regFormInput} ${styles.phoneStyle}`}
                            id="password"
                            name="password"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.password}
                          />
                        </Col>
                        {errors.password && touched.password && (
                          <Form.Text className="text-error">
                            {errors.password}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <NavLink
                        to="/cpc/customer/forget/password"
                        className={`${styles.forgetPassLink}`}
                      >
                        {t("forgetPass")}
                      </NavLink>
                      <div className="d-grid mt-3">
                        <Button
                          type="submit"
                          variant="danger"
                          size="md"
                          className={`${styles.registerBtn}`}
                          disabled={isSubmitting}
                        >
                          {t("login")}
                        </Button>
                        <Button
                          variant="danger"
                          size="md"
                          className={`${styles.registerBtn} mt-3`}
                          onClick={() => {
                            navigate("/cpc/customer/register");
                          }}
                        >
                          {t("createAccount")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
          <Col xs={12} lg={6} className="mb-5 mb-md-0">
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <img
                src={require("../../../imgs/CPC WAYAK Logo-1.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPicc}`}
              />
              <img
                src={require("../../../imgs/cpc-reg-form.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPic}`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
