import React, { useState } from "react";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { FaFileAlt } from "react-icons/fa";
import { adminUploadProducts } from "../../../../redux/features/adminSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import axios from "axios";
import styles from "./UploadBatch.module.scss";
import { useTranslation } from "react-i18next";

export default function UploadBatch() {
  const [fileName, setFileName] = useState("");
  const [progressBar, setProgressBar] = useState(0);
  const [message, setMessage] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);
  const dispatch = useDispatch();

  const handleProductfile = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post("https://evouchers.shareecoin.com/CPC/uploadProducts", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (event) => {
          setProgressBar(Math.round(100 * event.loaded) / event.total);
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setMessage({
            status: "success",
            message: res.data.message,
            messageAr: res.data.messageAr,
          });
        } else {
          setMessage({
            status: "notSuccess",
            message: res.data.error,
            messageAr: res.data.errorAr,
          });
        }
      });
  };

  const { t } = useTranslation();

  return (
    <div className={`${styles.uploadBatchContent} p-3`}>
      <Container>
        <Row>
          <Col xs={12}>
            <div className={`${styles.bgGrey} py-4 px-5 mb-3`}>
              <Container fluid>
                <Row>
                  <Col xs={12} md={7}>
                    <Formik
                      initialValues={{ productsFile: "" }}
                      onSubmit={(values) => {
                        // dispatch(
                        //   adminUploadProducts({
                        //     productFile: values.productsFile,
                        //     token,
                        //   })
                        // ).then((res) => {
                        //   setMessage(res.payload.data.error);
                        // });
                      }}
                    >
                      {({
                        values,
                        erros,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => {
                        return (
                          <Form id="uploadBatchFrom">
                            <Form.Group className="mb-2">
                              <Form.Label
                                className={`${styles.uploadBatchLabel}`}
                                htmlFor="productsFile"
                              >
                                {t("uploadBatch")}
                              </Form.Label>
                              <Form.Control
                                type="file"
                                name="productsFile"
                                id="productsFile"
                                className="d-none"
                                onChange={(e) => {
                                  // setFieldValue("productsFile", e.target.files[0]);
                                  // handleSubmit();
                                  setMessage("");
                                  setProgressBar(0);
                                  handleProductfile(e);
                                }}
                              />
                            </Form.Group>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                  <Col xs={12} md={5}>
                    <div className={`${styles.bgFileResult} p-3`}>
                      <FaFileAlt className="me-2" />
                      <span className={`${styles.fileName}`}>
                        {fileName !== "" ? fileName : t("noFileChosenYet")}
                      </span>
                      {fileName !== "" && (
                        <div className={`${styles.progress} mt-2`}>
                          <div
                            className={`${styles.progressBar}`}
                            style={{ width: `${progressBar}%` }}
                          ></div>
                        </div>
                      )}
                    </div>
                    {message.status === "success" ? (
                      <span className={`${styles.message} text-successs`}>
                        {i18next.language == "ar"
                          ? message.messageAr
                          : message.message}
                      </span>
                    ) : (
                      <span className={`${styles.message} text-error`}>
                        {i18next.language == "ar"
                          ? message.messageAr
                          : message.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
