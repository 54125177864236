import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import {
  createCustomer,
  customerSendOTP,
} from "../../../redux/features/customerSlice";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import MessageBox from "../../../messages/MessageBox";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Cookies from "js-cookie";
import styles from "./CustomerRegister.module.scss";
import CustomerAcceptOTP from "../acceptotp/CustomerAcceptOTP";

export default function CustomerRegister() {
  const [govid, setGovid] = useState("");
  const [govers, setGovers] = useState([]);
  const [cities, setCities] = useState([]);
  const [activeCityField, setAcitveCityField] = useState(false);
  const [verfCode, setVerfCode] = useState("");

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGovers(res.data["Governates List"]);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govid,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
        }
      });
  }, [govid]);

  useEffect(() => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messageBox, setMessageBox] = useState(false);
  const [customerOtp, setCustomerOtp] = useState(false);

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(Cookies.get("i18next"));

  const customerRegisterValidation = yup.object().shape({
    firstName: yup.string().required("الاسم الاول مطلوب"),
    lastName: yup.string().required("الاسم الثاني مطلوب"),
    phoneNumber: yup.string().required("رقم الجوال مطلوب"),
    email: yup.string().email().required("البريد الاليكتروني مطلوب"),
    gender: yup.string().required("النوع مطلوب"),
    districts: yup.array().of(
      yup.object().shape({
        cityID: yup.string().required("المنطقة مطلوبة"),
        govID: yup.string().required("المحافظة مطلوبة"),
      })
    ),
  });

  window.onload = () => {
    i18next.changeLanguage("ar");
    Cookies.set("i18next", "ar");
    document.body.dir = "rtl";
    setLang("ar");
  };

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const [customerInfo, setCustomerInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    gender: "",
    govID: "",
    cityID: "",
  });

  return (
    <div className={`${styles.CustomerRegisterContent} p-2 p-sm-4`}>
      <div className={styles.langBtn}>
        {lang === "en" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("ar");
              Cookies.set("i18next", "ar");
              document.body.dir = "rtl";
              setLang("ar");
            }}
          >
            Arabic
          </Button>
        )}
        {lang === "ar" && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              i18next.changeLanguage("en");
              Cookies.set("i18next", "en");
              document.body.dir = "ltr";
              setLang("en");
            }}
          >
            انجليزية
          </Button>
        )}
      </div>
      <Container fluid>
        <Row className="flex-column-reverse flex-lg-row ">
          <Col
            xs={12}
            lg={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <h5 className={`${styles.cpcRegTitle} mb-4`}>
              برنامج نقاط وياك للمستخدمين
            </h5>
            <div className="p-1 p-sm-2">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  password: "",
                  email: "",
                  districts: [
                    {
                      govID: "",
                      cityID: "",
                    },
                  ],
                  gender: "",
                }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                  }, 3000);
                  dispatch(
                    customerSendOTP({
                      mobileNumber: values.phoneNumber,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      setCustomerOtp(true);
                      setCustomerInfo({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        phoneNumber: values.phoneNumber,
                        email: values.email,
                        password: values.password,
                        gender: values.gender,
                        govID: values.districts[0].govID,
                        cityID: values.districts[0].cityID,
                      });
                      setVerfCode(res.payload.data.verificationCode);
                    }
                  });
                }}
                validationSchema={customerRegisterValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => {
                  return (
                    <Form
                      onSubmit={handleSubmit}
                      onKeyDown={(e) => checkKeyDown(e)}
                    >
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("userName")}
                        </Form.Label>
                        <Col xs={6}>
                          <Form.Control
                            type="text"
                            placeholder={t("firstName")}
                            className={`${styles.regFormInput} mb-3 mb-md-0`}
                            name="firstName"
                            id="firstName"
                            onChange={handleChange}
                            value={values.firstName}
                          />
                          {errors.firstName && touched.firstName && (
                            <Form.Text className="text-error">
                              {errors.firstName}
                            </Form.Text>
                          )}
                        </Col>
                        <Col xs={6}>
                          <Form.Control
                            type="text"
                            placeholder={t("lastName")}
                            className={`${styles.regFormInput}`}
                            name="lastName"
                            id="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                          />
                          {errors.lastName && touched.lastName && (
                            <Form.Text className="text-error">
                              {errors.lastName}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("password")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="password"
                            maxLength={4}
                            placeholder=""
                            className={`${styles.regFormInput} text-center`}
                            name="password"
                            id="password"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.password}
                          />
                        </Col>
                        {errors.email && touched.email && (
                          <Form.Text className="text-error">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("email")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="email"
                            placeholder=""
                            className={`${styles.regFormInput}`}
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                        </Col>
                        {errors.email && touched.email && (
                          <Form.Text className="text-error">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("mobileNumber")}
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            type="text"
                            maxLength={11}
                            placeholder="******01"
                            className={`${styles.regFormInput} ${styles.phoneStyle}`}
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.phoneNumber}
                          />
                        </Col>
                        {errors.phoneNumber && touched.phoneNumber && (
                          <Form.Text className="text-error">
                            {errors.phoneNumber}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Form.Label
                          className={`${styles.regFormLabel}`}
                          column
                          sm={12}
                        >
                          {t("gender")}
                        </Form.Label>
                        <Col sm={12}>
                          <div className="d-flex justify-content-center align-items-center">
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              className={`${styles.genderInput} d-none`}
                              onChange={(e) => {
                                setFieldValue("gender", "male");
                              }}
                            />
                            <label
                              className={`${styles.genderLabel} me-2`}
                              htmlFor="male"
                            >
                              {t("male")}
                            </label>
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              className={`${styles.genderInput} d-none`}
                              onChange={(e) => {
                                setFieldValue("gender", "female");
                              }}
                            />
                            <label
                              className={`${styles.genderLabel} me-2`}
                              htmlFor="female"
                            >
                              {t("female")}
                            </label>
                          </div>
                        </Col>
                        {errors.gender && touched.gender && (
                          <Form.Text className="text-error">
                            {errors.gender}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group as={Row} className="mb-2">
                        <Col xs={12} sm={6}>
                          <Form.Label
                            className={`${styles.regFormLabel}`}
                            column
                            sm={12}
                          >
                            {t("gover")}
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Select
                              className={`${styles.regFormInput}`}
                              name="govID"
                              id="govID"
                              onChange={(e) => {
                                setGovid(e.currentTarget.value);
                                setFieldValue(
                                  `districts[0].govID`,
                                  e.currentTarget.value
                                );
                                setAcitveCityField(true);
                              }}
                              value={values.districts[0].govID}
                            >
                              <option value="">{t("selectGover")}</option>

                              {govers.map((gov, index) => {
                                return (
                                  <option
                                    value={gov["GovernateID"]}
                                    key={index + 1}
                                  >
                                    {gov[t("govers")]}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label
                            className={`${styles.regFormLabel}`}
                            column
                            sm={12}
                          >
                            {t("district")}
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Select
                              className={`${styles.regFormInput}`}
                              disabled={!activeCityField}
                              name="cityID"
                              id="cityID"
                              onChange={(e) => {
                                setFieldValue(
                                  `districts[0].cityID`,
                                  e.currentTarget.value
                                );
                              }}
                              value={values.districts[0].cityID}
                            >
                              <option value="">{t("selectDistrict")}</option>
                              {cities.map((city, index) => {
                                return (
                                  <option
                                    value={city["cityID"]}
                                    key={index + 1}
                                  >
                                    {city[t("districs")]}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                        </Col>
                      </Form.Group>
                      <div className="d-grid mt-3">
                        <Button
                          type="submit"
                          variant="danger"
                          size="md"
                          className={`${styles.registerBtn} mb-3`}
                          disabled={isSubmitting}
                        >
                          {t("createAccount")}
                        </Button>
                        <Button
                          variant="danger"
                          size="md"
                          className={`${styles.registerBtn}`}
                          onClick={() => {
                            navigate("/cpc/customer/login");
                          }}
                        >
                          {t("login")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
          <Col xs={12} lg={6} className="mb-5 mb-md-0">
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              <img
                src={require("../../../imgs/CPC WAYAK Logo-1.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPicc}`}
              />
              <img
                src={require("../../../imgs/cpc-reg-form.png")}
                alt="cpc-reg-pic"
                className={`${styles.regFormPic}`}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <MessageBox
        show={messageBox}
        onHide={() => {
          setMessageBox(false);
        }}
      />
      <CustomerAcceptOTP
        show={customerOtp}
        onHide={() => {
          setCustomerOtp(false);
        }}
        verfcode={verfCode}
        customerinfo={customerInfo}
      />
    </div>
  );
}
