import React from "react";
import { Navigate } from "react-router-dom";

export default function StationOwnerPrivateRoute({ token, condition, children }) {
  if (condition === "" || condition !== "stationOwner" || token === "") {
    return <Navigate to="/cpc/dashboard" />;
  } else {
    return children;
  }
}
