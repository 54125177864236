import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { handleLogout } from "../../../../redux/features/userSlice";
import DatePicker from "react-datepicker";
import { IoMdArrowRoundBack } from "react-icons/io";
import moment from "moment/moment";
import styles from "./TeamReport.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Formik, useFormik } from "formik";
import { FaRegFileExcel } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import {
  activatorTeamReportFilter,
  downloadActivatorTeamReportFilter,
} from "../../../../redux/features/adminSlice";
import "./iconstyle.css";

export default function TeamReport() {
  const girdRef = useRef();
  const [gridApi, setGridApi] = useState("");
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState(null);

  const { t } = useTranslation();

  const [cities, setCities] = useState([]);
  const [governs, setGoverns] = useState([]);
  const [governID, setGovernID] = useState(null);
  const [activeCity, setAcitveCity] = useState(false);

  const [fromCreationDate, setFromCreationDate] = useState("");
  const [toCreationDate, setToCreationDate] = useState("");
  const [fromActivationDate, setFromActivationDate] = useState("");
  const [toActivationDate, setToActivationDate] = useState("");

  const token = useSelector((state) => state.cpc.user.login.token);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: t("activatorNamee"),
      field: "activatorFirstName",
      colId: "activatorName",
      cellRenderer: (result) => {
        return (
          <div>
            {`${result.data.activatorFirstName} ${result.data.activatorLastName}`}
          </div>
        );
      },
    },
    {
      headerName: t("assignedMerchants"),
      colId: "assignedMerchants",
      field: "assignedMerchants",
      flex: 2,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.assignedMerchants}`}>
            <ul>
              {result.data.assignedMerchants
                .filter((assign) => {
                  return assign.activationStatus === 0;
                })
                .map((merchant, index) => {
                  return (
                    <li key={index} className="p-0 m-0">
                      {merchant["businessName"]}
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: t("creationDate"),
      field: "creationDate",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.assignedMerchants}`}>
            <ul>
              {result.data.assignedMerchants
                .filter((assign) => {
                  return assign.activationStatus === 0;
                })
                .map((merchant, index) => {
                  if (merchant["createdDate"] == null) {
                    return <span className="d-block">No Branches</span>;
                  } else {
                    return (
                      <li key={index} className="p-0 m-0">
                        {merchant["createdDate"]}
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: t("ownerName"),
      field: "ownerName",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.assignedMerchants}`}>
            <ul>
              {result.data.assignedMerchants.map((assign, index) => {
                return (
                  <li key={index} className="p-0 m-0">
                    {`${assign["ownerFirstName"]} ${assign["ownerLastName"]}`}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: t("gover"),
      field: "governorate",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.assignedMerchants}`}>
            <ul>
              {result.data.assignedMerchants.map((assign, index) => {
                return (
                  <li key={index} className="p-0 m-0">
                    {i18next.language == "ar"
                      ? assign.branches[0]["governorate_name_ar"]
                      : assign.branches[0]["governorate_name_en"]}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: t("city"),
      field: "city",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.assignedMerchants}`}>
            <ul>
              {result.data.assignedMerchants.map((assign, index) => {
                return (
                  <li key={index} className="p-0 m-0">
                    {i18next.language == "ar"
                      ? assign.branches[0]["city_name_ar"]
                      : assign.branches[0]["city_name_en"]}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
    {
      headerName: t("activationStatus"),
      field: "ActivationStatus",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (result) => {
        return (
          <div className={`${styles.assignedMerchants}`}>
            <ul>
              {result.data.assignedMerchants.map((assign, index) => {
                return (
                  <li key={index} className="p-0 m-0">
                    {assign["activationStatus"] === 0 ? "Inactive" : "Active"}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      },
    },
  ]);

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  //   axios
  //     .get("https://evouchers.shareecoin.com/CPC/listActivators", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       params.api.applyTransaction({ add: res.data.activators });
  //     });
  // };

  const [goverName, setGoverName] = useState("");

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/CPC/listActivators", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setResults(res.data.activators);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGoverns(res.data["Governates List"]);
          const filterGovern = res.data["Governates List"].filter((govv) =>
            i18next.language == "ar"
              ? govv["governorate_name_ar"] == goverName
              : govv["governorate_name_en"] == goverName
          );
          filterGovern.map((filGov) => {
            setGovernID(filGov["GovernateID"]);
          });
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [goverName]);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: governID,
        Function: "ListCites",
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCities(res.data["List of Cities"]);
        }
        if (res.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/login");
        }
      });
  }, [governID]);

  const defaultColDef = {
    flex: 1,
    minWidth: 350,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleExport = () => {
    gridApi.exportDataAsCsv({
      fileName: "Activation-Team-Report",
      allColumns: true,
      processCellCallback: function (params) {
        if (params.column.getColId() == "assignedMerchants") {
          let newArray = [];
          params.value
            .filter((assign) => {
              return assign.activationStatus === 0;
            })
            .map((act) => {
              newArray.push(act["businessName"]);
            });
          return newArray;
        }
        if (params.column.getColId() == "activatedMerchants") {
          let newArrayy = [];
          params.value
            .filter((assign) => {
              return assign.activationStatus === 1;
            })
            .map((act) => {
              newArrayy.push(act["businessName"]);
            });
          return newArrayy;
        }
        if (params.column.getColId() === "activatorName") {
          return `${params.node.data["activatorFirstName"]} ${params.node.data["activatorLastName"]}`;
        }
        return params.value;
      },
    });
  };

  function downloadBloob(blob, FileName = "omar-ghoniem.xlsx") {
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = data;
    link.download = FileName;
  }

  return (
    <div className={`${styles.TeamReportContent}`}>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <h3 className={`${styles.teamTitle} mb-3 mb-md-0`}>
              <IoMdArrowRoundBack
                className={`${styles.backBtn} fs-4 me-2`}
                onClick={() => {
                  navigate("/admin/dashboard/team");
                }}
              />
              {t("activatorTeamReport")}
            </h3>
          </Col>
          <Col xs={12}>
            <Formik
              initialValues={{}}
              onSubmit={(values, actions) => {
                dispatch(
                  activatorTeamReportFilter({
                    token,
                    filterParams: values,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    if (res.payload.data.activators.length > 0) {
                      setFilter(res.meta.arg.filterParams);
                    }
                    setResults(res.payload.data.activators);
                  }
                  if (res.data.statusCode === 401) {
                    dispatch(handleLogout());
                    navigate("/cpc/login");
                  }
                  // actions.resetForm();
                });
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                handleChange,
                handleReset,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("activatorName")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          name="activatorName"
                          id="activatorName"
                          onChange={(e) => {
                            setFieldValue(
                              "activatorName",
                              e.currentTarget.value
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("ownerName")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          size="sm"
                          className={`${styles.filterInputField}`}
                          name="ownerName"
                          id="ownerName"
                          onChange={(e) => {
                            setFieldValue("ownerName", e.currentTarget.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("activationStatus")}
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="activationStatus"
                          id="activationStatus"
                          onChange={(e) => {
                            setFieldValue(
                              "activationStatus",
                              e.currentTarget.value
                            );
                          }}
                        >
                          <option value="">{t("select")}</option>
                          <option value="1">{t("active")}</option>
                          <option value="0">{t("notActive")}</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("gover")}
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="governorate_name_ar"
                          id="governorate_name_ar"
                          onChange={(e) => {
                            setGoverName(e.currentTarget.value);
                            setFieldValue(
                              "governorate_name_ar",
                              e.currentTarget.value
                            );
                            setAcitveCity(true);
                          }}
                        >
                          <option value="">{t("selectGover")}</option>
                          {governs.map((gov, index) => {
                            return (
                              <option
                                key={gov.GovernateID}
                                value={gov["governorate_name_ar"]}
                              >
                                {i18next.language == "ar"
                                  ? gov["governorate_name_ar"]
                                  : gov["governorate_name_en"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("city")}
                        </Form.Label>
                        <Form.Select
                          className={`${styles.filterInputSelect}`}
                          name="city_name_ar"
                          id="city_name_ar"
                          disabled={!activeCity}
                          onChange={(e) => {
                            setFieldValue(
                              "city_name_ar",
                              e.currentTarget.value
                            );
                          }}
                        >
                          <option value="">{t("selectCity")}</option>
                          {cities.map((city, index) => {
                            return (
                              <option key={index} value={city["city_name_Ar"]}>
                                {i18next.language == "ar"
                                  ? city["city_name_Ar"]
                                  : city["city_name_en"]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("creationDateFrom")}
                        </Form.Label>
                        <DatePicker
                          className={`${styles.filterDateField}`}
                          name="createdDateFrom"
                          id="createdDateFrom"
                          selected={fromCreationDate}
                          onSelect={(date) => {
                            setFieldValue(
                              "createdDateFrom",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setFromCreationDate(date);
                          }}
                          onChange={(date) => {
                            setFieldValue(
                              "createdDateFrom",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setFromCreationDate(date);
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("creationDateTo")}
                        </Form.Label>
                        <DatePicker
                          className={`${styles.filterDateField}`}
                          name="createdDateTo"
                          id="createdDateTo"
                          selected={toCreationDate}
                          onSelect={(date) => {
                            setFieldValue(
                              "createdDateTo",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setToCreationDate(date);
                          }}
                          onChange={(date) => {
                            setFieldValue(
                              "createdDateTo",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setToCreationDate(date);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("activationDateFrom")}
                        </Form.Label>
                        <DatePicker
                          className={`${styles.filterDateField}`}
                          name="activatedDateFrom"
                          id="activatedDateFrom"
                          selected={fromActivationDate}
                          onSelect={(date) => {
                            setFieldValue(
                              "activatedDateFrom",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setFromActivationDate(date);
                          }}
                          onChange={(date) => {
                            setFieldValue(
                              "activatedDateFrom",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setFromActivationDate(date);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className={`${styles.filterInputLabel}`}>
                          {t("activationDateTo")}
                        </Form.Label>
                        <DatePicker
                          className={`${styles.filterDateField}`}
                          name="activatedDateTo"
                          id="activatedDateTo"
                          selected={toActivationDate}
                          onSelect={(date) => {
                            setFieldValue(
                              "activatedDateTo",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setToActivationDate(date);
                          }}
                          onChange={(date) => {
                            setFieldValue(
                              "activatedDateTo",
                              moment(date).format("YYYY-MM-DD")
                            );
                            setToActivationDate(date);
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <div className="d-flex justify-content-end align-items-center mb-3">
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                        className={`${styles.searchBtn} m-1`}
                      >
                        {t("search")}
                      </Button>
                      <Button
                        type="reset"
                        variant="danger"
                        size="sm"
                        className={`${styles.resetBtn} m-1`}
                        onClick={() => {
                          handleReset();
                          setAcitveCity(false);
                          setFromCreationDate("");
                          setToCreationDate("");
                          setFromActivationDate("");
                          setToActivationDate("");
                          dispatch(
                            activatorTeamReportFilter({
                              token,
                              filterParams: {},
                            })
                          ).then((res) => {
                            if (res.payload.data.statusCode === 200) {
                              if (res.payload.data.activators.length > 0) {
                                setFilter(res.meta.arg.filterParams);
                              }
                              setResults(res.payload.data.activators);
                            }
                          });
                        }}
                      >
                        {t("reset")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Col>
          <Col xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: `70vh` }}
            >
              <AgGridReact
                enableRtl={true}
                ref={girdRef}
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={results}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Col>
          <Col xs={12} className="my-3">
            <div className="d-flex justify-content-start">
              <Button
                variant="success"
                size="md"
                className={`${styles.downloadExcelBtn} m-1`}
                onClick={() => {
                  dispatch(
                    downloadActivatorTeamReportFilter({
                      token,
                      downloadParams: {
                        format: "excel",
                        ...filter,
                      },
                    })
                  ).then((res) => {
                    const data = window.URL.createObjectURL(res.payload.data);
                    const link = document.createElement("a");
                    link.href = data;
                    link.download = "Activation-Team-Report.xlsx";
                    link.click();
                  });
                }}
              >
                <FaRegFileExcel className="mx-1" />
                Excel
              </Button>
              <Button
                variant="danger"
                size="md"
                className={`${styles.downloadPdfBtn} m-1`}
                onClick={() => {
                  dispatch(
                    downloadActivatorTeamReportFilter({
                      token,
                      downloadParams: {
                        format: "pdf",
                        ...filter,
                      },
                    })
                  ).then((res) => {
                    const data = window.URL.createObjectURL(res.payload.data);
                    const link = document.createElement("a");
                    link.href = data;
                    link.download = "Activation-Team-Report.pdf";
                    link.click();
                  });
                }}
              >
                <FaRegFilePdf className="mx-1" />
                PDF
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
