import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import styles from "./Qrscan.module.scss";
import { useDispatch, useSelector } from "react-redux";
import GiftPoint from "../point/GiftPoint";
import failedMessage from "../../../../messages/Failed";
import successMessage from "../../../../messages/Successful";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { handleLogout } from "../../../../redux/features/userSlice";
import { earnPointsAfterScanQr } from "../../../../redux/features/ownerSlice";
import { useNavigate } from "react-router-dom";

export default function Qrscan(props) {
  const [result, setResult] = useState("Loading ...");
  const [showGift, setShowGift] = useState(false);
  const [points, setPoints] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.cpc.user.login.token);

  const handleScan = (result, error) => {
    if (!!result) {
      setResult(result?.text);
      props.onHide();
      dispatch(
        earnPointsAfterScanQr({
          qrCode: result?.text,
          token,
        })
      ).then((res) => {
        const loadReq = toast.loading("Loading...", {
          transition: Zoom,
          position: "bottom-left",
        });
        if (res.payload.data.statusCode === 200) {
          setPoints(res.payload.data.addedPoints);
          setShowGift(true);
          successMessage(
            res.payload.data.message,
            res.payload.data.messageAr,
            loadReq
          );
        } else if (res.payload.data.statusCode === 401) {
          dispatch(handleLogout());
          navigate("/cpc/ownerTrader/login");
          failedMessage(
            res.payload.data.error,
            res.payload.data.errorAr,
            loadReq
          );
        } else {
          failedMessage(
            res.payload.data.error,
            res.payload.data.errorAr,
            loadReq
          );
        }
      });
    }
  };

  return (
    <div className={`${styles.qrScanContent}`}>
      <Modal {...props} size="md" centered>
        <Modal.Body className={`${styles.qrCodeModal}`}>
          <QrReader
            scanDelay={500}
            className={`${styles.qrVideo}`}
            onResult={handleScan}
            constraints={{ facingMode: "environment" }}
          />
          {/* <p className={`${styles.qrResult}`}>{result}</p> */}
        </Modal.Body>
      </Modal>
      <GiftPoint
        show={showGift}
        onHide={() => {
          setShowGift(false);
        }}
        points={points}
      />
    </div>
  );
}
